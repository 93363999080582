import React, { FC, useState } from 'react'
import { Modal } from 'navex-react'
import { ActionIcon } from 'navex-react'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { Toasts, ToastId } from 'navex-react'
import { useDeleteCustomFormula } from './CustomFormulasApi'
import { IButtonProps } from 'navex-react'

interface IProps {
  customId: number | undefined,
  formula: string,
  name: string,
  id: number,
  setIsCustomFormulaEdit: (value: boolean) => void,
  reFetchData: () => void,
  setCustomFormulaId: (customId: number | undefined) => void,
}

export const CustomFormulaListItem: FC<IProps> = (props) => {
  const [showModalDeleteCustomFormula, setShowModalDeleteCustomFormula] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);
  const [okButtonProps, setOkButtonProps] = useState<IButtonProps>({ disabled: false })

  let toastId: ToastId = 0

  const { id, customId } = props

  const [deleteCustomFormula] = useDeleteCustomFormula({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Custom Formula deleted sucessfully")
      }
      props.reFetchData()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const handleEditCustomFormulaBtnLinkClick = () => {
    props.setIsCustomFormulaEdit(true)
    props.setCustomFormulaId(id)
  }

  const handleDeleteCustomFormulaAlertBtnLinkClick = () => {
    setShowModalDeleteCustomFormula(true)
  }

  const handleDeleteModalXClose = () => {
    setShowModalDeleteCustomFormula(false)
  }

  const handleFormulaDelete = async () => {
    if (id !== undefined) {
     setIsDeleting(true)
     okButtonProps.disabled = true
     setOkButtonProps(okButtonProps)
     await deleteCustomFormula({ id })
     setIsDeleting(false)
    }
  }

  return (
    <tr key={customId}>
      <td>
        {props.formula}
      </td>
      <td>
        {props.name}
      </td>
      <td>
        <ActionIcon id={props.id + "_btnEditFormula"} icon={faPencilAlt} toolTip="Edit" onClick={handleEditCustomFormulaBtnLinkClick} />

        <ActionIcon id={props.id + "_btnDeleteFormula"} icon={faTrashAlt} toolTip="Delete" onClick={handleDeleteCustomFormulaAlertBtnLinkClick} />
        <Modal id={props.id + "_modalDeleteFormula"} modalType={("BASIC")}
          headerText={props.name}
          show={showModalDeleteCustomFormula}
          okButtonText = {!isDeleting? "Delete" : "Deleting..."}
          okButtonProps={okButtonProps}
          message={
            `Delete ${props.name} from custom formula table.`
          }
          onClickedOk={handleFormulaDelete}
          onClickedCancel={handleDeleteModalXClose}
        />
      </td>
    </tr>
  )
}