//import { BirstConfig } from '../../Assets/js/birst_embed'

import { BirstConfig } from '../../Assets/js/birst_embed_7_3_3'

export interface ICreateDashboardProps {
  dashboardName: string,
  collectionName: string,
  /**
   * The filters string is expected to already be in the correct format.
   */
  filters?: string,
  dashboardUrl: URL
}

export const createDashboardUrl = (props: ICreateDashboardProps) => {
  const url = props.dashboardUrl

  url.searchParams.append('birst.module', 'newDashboards')
  url.searchParams.append('birst.exportZoom', '2')
  url.searchParams.append('birst.embedded', 'true')
  url.searchParams.append('birst.hideDashboardNavigation', 'true')
  url.searchParams.append('birst.hideDashboardPrompts', 'true')
  url.searchParams.append('birst.dashboard', props.collectionName)
  url.searchParams.append('birst.page', props.dashboardName)

  let urlString = String(url)

  if (props.filters) {
    urlString += `&birst.filters=${props.filters}`
  }

  return urlString
}

export const createDashboard = (props: ICreateDashboardProps) => {
  let url = createDashboardUrl(props)

  const optionsDashboard = {
    iframeSrc: url
  }

  BirstConfig.create("BirstDash", optionsDashboard)
}
