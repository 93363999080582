import React, { FC, useEffect, useRef, useState } from 'react'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'navex-react'
import { axiosInstance } from '../../Api/api'
import { buildServerApiUrl, getCustomerKey } from '../../Api/urls'
import { Spinner } from '../Shared/Spinner'
import { IQuarters } from './FiscalCalendarApi'
import { FiscalQuarters } from './FiscalQuarters'
import styles from "./FiscalCalendar.module.scss"
import { FORMAT } from '../../ts/enums/calendarFormat'
import { months } from '../../Utils/fiscalCalendarUtils'


interface IProps {
    startDate: string | undefined,
    endDate: string | undefined,
    fiscalMonth: string,
    fiscalFormat: string | undefined,
    newFiscalYear: number,
    setIsPreview: (isPreview: boolean) => void
}

export const PreviewFiscalCalendar: FC<IProps> = (props) => {

    const { startDate, endDate, fiscalMonth, fiscalFormat, setIsPreview, newFiscalYear } = props
    
    const [isLoading, setIsLoading] = useState(true)
    const [errors, setErros] = useState([] as any[])
    const [quarters, setQuarters] = useState<IQuarters[]>([])

    const fiscalYear =  newFiscalYear
    const startDateToLocale = new Date(startDate!).toLocaleDateString('en-US').replace(/\u200e/g, '')
    const endDateToLocale = new Date(endDate!).toLocaleDateString('en-US').replace(/\u200e/g, '')
    const fiscalMonthInNumber = fiscalFormat === FORMAT.ANNUALCALENDAR ? 0 : months.indexOf(fiscalMonth) + 1 

    const payload = useRef({})

    payload.current = {
        beginDate: startDateToLocale,
        endDate: endDateToLocale,
        firstMonth: fiscalMonthInNumber,
        fiscalFormat: fiscalFormat,
        customerKey: getCustomerKey(),
        fiscalYear: fiscalYear
    }

    useEffect(() => {
        const loadQuarters = () => {
            axiosInstance.post(`${buildServerApiUrl("FiscalCalendar.Preview").toString()}`, payload.current).then(res => {
                if (res.status === 200) {
                    setIsLoading(false)
                    if (res.data.data) {
                        setQuarters(res.data.data.quarters as IQuarters[])
                    }
                }
            }).catch(error => {
                setIsLoading(false)
                setErros([error])
            })
        }
        loadQuarters()
    }, [])

    if (errors.length) return <BackToEditButton {...{ setIsPreview, errors, fiscalFormat }} />
    if (isLoading) return <Spinner />


    return (
        <>
            <FiscalQuarters {...{ fiscalYear, quarters, fiscalFormat, startDateToLocale, endDateToLocale }} />
            <BackToEditButton {...{ setIsPreview, errors, fiscalFormat }} />
        </>
    )
}

const BackToEditButton: FC<{ setIsPreview: (preview: boolean) => void, errors: any[], fiscalFormat: string | undefined }> = (props) => {
    const { setIsPreview, errors, fiscalFormat } = props

    const handlePreviewCancelBtnClick = () => {
        setIsPreview(false)
    }

    const buttonName = errors.length ? 'Back' : 'Close Preview'

    return (
        <>
            {errors.length ? <Error /> : null}
            <Button id="btnPreviewFiscalYear" onClick={handlePreviewCancelBtnClick} purpose="default"
                style={fiscalFormat === FORMAT.ANNUALCALENDAR ? { marginTop: "35px" } : {}}
                className={styles.closePreview}>{buttonName}</Button>
        </>
    )
}

const Error = () => {
    return (
        <>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#e3094c', fontSize: "20px" }} />
            <h2 id='unexpectedError' className="text-danger" style={{ marginLeft: '15px', display: 'inline' }}>
                An error occurred processing your request. Please try again.</h2>
        </>
    )
}