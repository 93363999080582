import React, { useState } from 'react'
import { DropDown, DropDownItem } from 'navex-react'
import { IMapping } from './OutcomesApi'
import { useUpsertSubstantiatedTypeMapping } from './OutcomesApi'
import { Toasts, ToastId } from 'navex-react'

interface IProps {
  mapping: IMapping,
  options: string[],
}

export const OutcomeRow = (props: IProps) => {
  const [mapping, setMapping] = useState(props.mapping.mapping)
  const [invalid, setInvalid] = useState(false)
  const setInvalidOn = () => setInvalid(true)
  const setInvalidOff = () => setInvalid(false)

  let toastId: ToastId = 0

  const [upsert, { posting: upserting }] = useUpsertSubstantiatedTypeMapping({
    onSuccess: (_, context) => {
      setInvalidOff()
      setMapping(context && context.substantiatedType)
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Success")
      }
    },
    onError: () => {
      setInvalidOn()
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const update = (value: string) => {
    upsert({
      outcomeId: props.mapping.outcomeId,
      substantiatedType: value
    })
  }

  const options = props.options.map((option, index) => {
    return (<DropDownItem
      data-testid={`${props.mapping.outcomeName}_${option}`}
      key={index} value={option}>{option}
    </DropDownItem>)
  })

  return (<tr>
    <td>
      {props.mapping.outcomeName}
    </td>
    <td>
      {props.mapping.tierName}
    </td>
    <td className="outcome-selector">
      <DropDown
        id={`${props.mapping.outcomeId}`}
        title={mapping || "Select"}
        value={mapping}
        isFormControl
        invalid={invalid}
        onChange={update}
        disabled={upserting}
      >
        {options}
      </DropDown>
    </td>
  </tr>)
}
