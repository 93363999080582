import { ImmerReducer, createActionCreators, createReducerFunction } from "immer-reducer"

export interface ILeftNavClickEvent {
  collectionName: string,
  spaceId: string,
  page: string,
  hasSpaceIdchanged: boolean,
}

export interface IBirstIntegrationsStateSlice {
  currentPage: string,
  leftNavClickEvent: ILeftNavClickEvent & {
    handled: boolean
  },
}

class BirstIntegrationsReducerDef extends ImmerReducer<IBirstIntegrationsStateSlice> {
  pubLeftNavClickEvent(leftNavClickEvent: ILeftNavClickEvent) {
    this.draftState.leftNavClickEvent = {
      ...leftNavClickEvent,
      handled: false
    }
  }

  handledLeftNavClickEvent() {
    this.draftState.leftNavClickEvent.handled = true
  }

}

export const BirstIntegrationsActionCreators = createActionCreators(BirstIntegrationsReducerDef)
export const birstIntegrations = createReducerFunction(BirstIntegrationsReducerDef,
  getEmptyBirstState())

function getEmptyBirstState(): IBirstIntegrationsStateSlice | undefined {
  return {
    currentPage: "",
    leftNavClickEvent: {
      collectionName: "",
      spaceId:"",
      page: "",
      hasSpaceIdchanged: false,
      handled: true
    },
  }
}