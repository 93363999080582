
import { INavex } from 'navex-react/dist/types/AppNavigationUI/INavex'
import { Report, service } from 'powerbi-client'

declare global {
    interface Window {
        apiUrlPath: string,
        gatewayUrl: string,
        platformTopNavUrl: string,
        birstUrl: string,
        Navex: INavex,
        sessionPollTimeout: number,
        managePollTimeout: number,
        customFieldPollTimeout: number,
        resetPollTimeout: number
        sessionStatusUiUrl: string,
        fiscalCalendarPollTimeout: number,
        report: Report,
        powerbi: service.Service
    }
}

export const buildHostUrl = () => {
    const subdomain = window.location.hostname.split('.')[0]

    const port = window.location.port === "3000" ? ":3000" : ""

    const origin = window.location.origin
    const pathName = window.location.pathname

    const relayState = origin + pathName

    const hostUrl = {
        protocol: window.location.protocol,
        hostName: window.location.hostname,
        subdomain: subdomain,
        port: port,
        customerKey: subdomain,
        relayState: relayState
    }
    return hostUrl
}

export const pathPrefix = process.env.PUBLIC_URL

let tenantId = ''
export const setTenantId = (tenantIdValue: string)=>{
    tenantId = tenantIdValue
}

export const getTenantId = () => {  
    return tenantId
}

let customerKey = ''
export const setCustomerKey = (customerKeyValue: string)=>{
    customerKey = customerKeyValue
}

export const getCustomerKey = () => {
    return customerKey    
}

export const getSubDomain = () => {
    return buildHostUrl().subdomain
}

export const buildUrlForApiAuthRedirectToKeyCloak = () => {
    let url = buildServerApiUrl("BrowserAuthorization.StartBrowserAuthorization?", "v2")
    url.searchParams.append('customerKey', getCustomerKey())
    url.searchParams.append('relayState', buildHostUrl().relayState)
    return url
}

export const buildUrlForApiAuthRedirectToKeyCloakV3 = () => {
    let url = buildServerApiUrl("BrowserAuthorization.StartBrowserAuthorization?", "v3")
    url.searchParams.append('alias', getSubDomain())
    url.searchParams.append('relayState', buildHostUrl().relayState)
    return url
}


export const buildUrlForClientGateway = () => {
    let hostGateway = window.gatewayUrl.replace('*', getCustomerKey())

    if (!hostGateway) {
        throw new Error("Unable to determine Gateway url")
    }
    const url = new URL(hostGateway)

    return url
}

export const buildUrlForClientPlatformTopNav = () => {
    let hostPlatformTopNav = window.platformTopNavUrl

    if (!hostPlatformTopNav) {
        throw new Error("Unable to determine Platform Top Nav url")
    }
    const url = new URL(hostPlatformTopNav)

    return url
}

export const buildServerApiUrl = (endpoint: string, version: string = "v1") => {
    let base = window.location.origin
    let hostApiPath = window.apiUrlPath + `/public/${version}/`
    
    let hostApi = new URL(hostApiPath, base).toString()
    

    if (!hostApi) {
        throw new Error("Unable to determine API url")
    }

    const url = new URL(endpoint, hostApi)

    return url
}

export const buildServerBirstUrl = (endpoint: string) => {
    let hostBirst = window.birstUrl

    if (!hostBirst) {
        throw new Error("Unable to determine Birst url")
    }
    const url = new URL(endpoint, hostBirst)

    return url
}

export const buildServerApiPrivateUrl = (endpoint: string, version: string = "v1") => {
    let base = window.location.origin
    let hostApiPrivatePath = window.apiUrlPath + `/private/${version}/`
    
    let hostApiPrivate = new URL(hostApiPrivatePath, base).toString()

    if (!hostApiPrivate) {
        throw new Error("Unable to determine API url")
    }

    const url = new URL(endpoint, hostApiPrivate)

    return url
}

export const buildUrlForToggleStatus = () => buildServerApiUrl("ReleaseToggle.ToggleStatus")

export const buildUrlForClearSession = () => buildServerApiUrl("BrowserAuthorization.ClearSession", "v2")

export const buildUrlForVerifyingHumanSession = () => `${buildServerApiUrl("Session.Get").toString()}/${getCustomerKey()}`

export const buildUrlForGetTenantInfo = () => `${buildServerApiUrl("TenantInfo.GetTenantInfo").toString()}/${getSubDomain()}`

export const buildUrlForLogoutV2 = () => {
    let url = buildServerApiUrl("BrowserAuthorization.Logout", "v2")

    url.searchParams.append('customerKey', getCustomerKey())
    url.searchParams.append('relayState', buildHostUrl().relayState)

    return url
}

export const buildUrlForLogoutV3 = (tenantId: string | undefined) => {
    let url = buildServerApiUrl("BrowserAuthorization.Logout", "v3")

    url.searchParams.append('tenantId', tenantId!)
    url.searchParams.append('relayState', buildHostUrl().relayState)

    return url
}

export const buildUrlForFinishLogout = () => buildServerApiUrl("BrowserAuthorization.FinishLogout", "v2")

export const buildUrlForFinishLogoutV3 = () => buildServerApiUrl("BrowserAuthorization.FinishLogout", "v3")

export const buildUrlForBirstLogout = () => buildServerBirstUrl("Logout.aspx")

export const buildUrlForBirstEmbedJs = () => buildServerBirstUrl("js/birst_embed.js")

export const buildUrlForClientSessionStatusUi = () => {
    let hostSessionStatusUi = window.sessionStatusUiUrl

    if (!hostSessionStatusUi) {
        throw new Error("Unable to determine Session Status UI url")
    }
    const url = new URL(hostSessionStatusUi)

    return url
}

export const buildUrlForKeycloakAccessToken = () => buildServerApiUrl("Session.GetAccessToken")