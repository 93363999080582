import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useEffect, useReducer, useRef, useCallback } from 'react'
import { axiosInstance } from './api'
import { buildUrlForApiAuthRedirectToKeyCloakV3 } from './urls'

type TSuccess = {
    data: any,
    response: any
}

type TError = {
    errors: any[],
    pending: boolean
}

type TStart = {
    pending: boolean
}

export type TInitialState = {
    data: any,
    status: undefined | string,
    pending: boolean,
    errors: any[],
    response: any
}

type TAction = { type: "START", result: TStart }
    | { type: "SUCCESS", result: TSuccess }
    | { type: "FAILURE", result: TError }

export const fetchReducer = (state: TInitialState, action: TAction): TInitialState => {
    switch (action.type) {
        case "START": return {
            ...state, pending: action.result.pending
        }
        case "SUCCESS": return {
            ...state, data: action.result.data, response: action.result.response, status: "Success", pending: false, errors: []
        }
        case "FAILURE": return {
            ...state, data: null, status: "Failed", pending: false, errors: [action.result.errors]
        }
        default:
            throw new Error("Unknown Action type")
    }
}

const noop = () => { }

interface IUseAxiosOptions<T> {
    before?: () => void,
    after?: () => void,
    onSuccess?: (data?: any, context?: T) => void,
    onError?: (errors?: any, context?: T) => void
}

type TUseGet = [
    {
        data?: any,
        status?: number | string
        loading: boolean,
        errors: any[],
        response: any
    },
    (silentFetch?: boolean) => Promise<AxiosResponse<any> | undefined>
]

export const useGet = <T>(url: string, config?: AxiosRequestConfig, options?: IUseAxiosOptions<T>):
    TUseGet => {
    const initialState: TInitialState = {
        data: null,
        status: undefined as undefined | string,
        pending: false,
        errors: [] as any[],
        response: {}
    }

    const isMounted = useRef(false)
    const [{ data, status, pending, errors, response }, dispatch] = useReducer(fetchReducer, initialState)
    const opts = { before: noop, after: noop, onSuccess: noop, onError: noop, ...options }

    const fetchData = useCallback(async (silentFetch: boolean = false): Promise<AxiosResponse<any> | undefined> => {
        opts.before()
        if (silentFetch) {
            dispatch({ type: "START", result: { pending: false } })
        } else {
            opts.before()
            dispatch({ type: "START", result: { pending: true } })
        }
        try {
            const response = await axiosInstance.get(url, { ...config })

            if (response.data.status) {// is navex standard response
                if (String(response.data.status).toLowerCase() === "success") {
                    dispatch({ type: "SUCCESS", result: { data: response.data.data, response: response } })
                    opts.onSuccess(response.data.data)
                }
                else {
                    dispatch({ type: "FAILURE", result: response.data.errors })
                    opts.onError(response.data.errors)
                }
            }
            else {// is unknown response type
                dispatch({ type: "SUCCESS", result: { data: response.data, response: response } })
                opts.onError(response.data)
            }
        }
        catch (error) {
            if ((error as any).response) {
                window.location.href = buildUrlForApiAuthRedirectToKeyCloakV3().toString()
                return
            }
            if (isMounted.current === true) {
                dispatch({ type: "FAILURE", result: error as any })
                opts.onError([error])
            }
        }
        if (!silentFetch) {
            opts.after()
        }
        return

    }, [config, opts, url])

    useEffect(() => {
        if (!isMounted.current) {
            fetchData()
        }

        return () => { isMounted.current = true }

    }, [fetchData])

    return [{ data, status, loading: pending, errors, response }, fetchData]
}

type TUsePost<T> = [
    (payload?: T) => Promise<AxiosResponse<any> | undefined>,
    {
        data?: any,
        status?: number | string
        posting: boolean,
        errors: any[],
        response: any
    }
]

export const usePost = <T>(url: string, config?: AxiosRequestConfig, options?: IUseAxiosOptions<T>):
    TUsePost<T> => {
    const initialState: TInitialState = {
        data: null,
        status: undefined as undefined | string,
        pending: false,
        errors: [] as any[],
        response: {}
    }

    const [{ data, status, pending, errors, response }, dispatch] = useReducer(fetchReducer, initialState)
    const opts = { before: noop, after: noop, onSuccess: noop, onError: noop, ...options }

    const postData = async (payload?: T): Promise<AxiosResponse<any> | undefined> => {
        opts.before()
        dispatch({ type: "START", result: { pending: true } })
        try {
            const response = await axiosInstance.post(url, payload, { ...config })
            if (response.data.status) {// is navex standard response
                if (response.data.status === "Success") {
                    dispatch({ type: "SUCCESS", result: { data: response.data.data, response: response } })
                    opts.onSuccess(response.data.data, payload)
                }
                else {
                    dispatch({ type: "FAILURE", result: response.data.errors })
                    opts.onError(response.data.errors)
                }
            }
            else {// is unknown response type
                dispatch({ type: "SUCCESS", result: { data: response.data, response: response } })
                opts.onError(response.data, payload)
            }
        }
        catch (error) {
            if ((error as any).response) {
                if ((error as any).response.status === 401) {
                    window.location.href = buildUrlForApiAuthRedirectToKeyCloakV3().toString()
                    return
                }
            }
            dispatch({ type: "FAILURE", result: error as any })
            opts.onError([error])
        }
        opts.after()
        return
    }

    return [postData, { data, status, posting: pending, errors, response }]
}