import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey, getTenantId } from "../../Api/urls"
import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from '../../Api/useAxios'

export interface IUpsertPowerBiSubscription {
    customerKey: string,
    ReportId: string,
    SubscribeName: string,
    RecipientMailId: string,
    ReportTypeFormat: string,
    SubscriptionStartDate: Date,
    Frequency: string,
    Status: boolean
    Timezone: string,
    ReportUrl: string,
    WeekOfFrequency: string | null,
    MonthOfFrequency: string | null,
    author:string
}

export type IUpsertSubscription = Omit<IUpsertPowerBiSubscription, "customerKey">

type TUsePowerBiSubscription = [
    (payload: IUpsertSubscription) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const upsertSubscribeUrl = `${buildServerApiUrl("PowerBi.PowerBiSubscription").toString()}`
export const usePowerBiSubscription = (
    options?: IUseAxiosOptions<IUpsertPowerBiSubscription>
): TUsePowerBiSubscription => {

    const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertPowerBiSubscription>(
        upsertSubscribeUrl, {}, options
    )

    const PowerBiSubscription = async (payload: IUpsertSubscription) => {
        const res = await upsert({ ...payload, customerKey: getCustomerKey() })
        return res
    }

    return [PowerBiSubscription, { posting, status, errors }]
}

export interface IGetSubscription {
    subscriptionId: number,
    subscriptionName: string,
    reportName: string,
    recipients: string,
    frequency: any,
    reportTypeFormat: string,
    subscriptionStartDate: Date,
    timezone: string,
    weekOfFrequency: string | null,
    monthOfFrequency: string | null,
    author:string
}

type TUseGetSubscriptionDetails = [
    {
        SubscriptionDetails: IGetSubscription[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]


export const useGetSubscriptionDetails = (): TUseGetSubscriptionDetails => {
    const getSubscriptionUrlPath = `${buildServerApiUrl("PowerBi.GetSubscriptionDetails").toString()}/${getTenantId()}`
    const [{ data, loading, errors, status }, reFetchData] = useAxiosGet(
        getSubscriptionUrlPath
    )
    let SubscriptionDetails
    if (data) {
        SubscriptionDetails = data
    }
    return [{ SubscriptionDetails, loading, status, errors }, reFetchData]
}

interface IDeleteSubscriptionPayload {
    subscriptionId: number
}

type IDeleteSubscription = Omit<IDeleteSubscriptionPayload, "customerKey">

type TUseDeleteSubscription = [
    (payload: IDeleteSubscription) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const deleteSubscriptionUrl = `${buildServerApiUrl("PowerBi.DeleteSubscription").toString()}`
export const useDeleteSubscription = (
    options?: IUseAxiosOptions<IDeleteSubscriptionPayload>
): TUseDeleteSubscription => {
    const [execute, { posting, status, errors }] = useAxiosPost<IDeleteSubscriptionPayload>(
        deleteSubscriptionUrl, {}, options
    )

    const clear = async (payload: IDeleteSubscription) => {
        const res = await execute({ ...payload})
        return res
    }

    return [clear, { posting, status, errors }]
}

interface IUpdateSubscriptionPayload {
    TenantId: string,
    SubscriptionId: number,
    SubscribeName: string,
    RecipientMailId: string,
    ReportId: string,
    ReportTypeFormat: string,
    SubscriptionStartDate: Date,
    Frequency: string,
    Timezone: string,
    ReportUrl: string,
    WeekOfFrequency: string | null,
    MonthOfFrequency: string | null,
    author:string
}

type IUpdateSubscription = Omit<IUpdateSubscriptionPayload, "TenantId">

type TUseUpdateSubscription = [
    (payload: IUpdateSubscription) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const updateSubscriptionUrl = `${buildServerApiUrl("PowerBi.UpdateSubscription").toString()}`
export const useUpdateSubscription = (
    options?: IUseAxiosOptions<IUpdateSubscriptionPayload>
): TUseUpdateSubscription => {
    const [execute, { posting, status, errors }] = useAxiosPost<IUpdateSubscriptionPayload>(
        updateSubscriptionUrl, {}, options
    )

    const update = async (payload: IUpdateSubscription) => {
        const res = await execute({ ...payload, TenantId: getTenantId() })
        return res
    }

    return [update, { posting, status, errors }]
}