import XRegExp from "xregexp"
import { pascalize, StringPredicate } from "./utils"

export const positivefieldNameRegex = XRegExp("^[\\p{L}_#][\\s\\p{L}\\p{Nd}$#_]*$")
export const badBodyCharRegex = XRegExp("[^\\p{L}\\p{Nd}$#_]*") // note the negation ^ inside the brackets

export function stripInvalidBodyCharacters(text: string) {
    const stripped = XRegExp.replace(text, badBodyCharRegex, "", "all")
    return stripped
}

export function handleIllegalFirstCharacter(text: string) {
    if(XRegExp.test(text, XRegExp("^[\\p{Nd}$].*$"))) {
        return `_${text}`
    }
    return text
}

export const tryToFindUniqueFieldNameSuggestion = (baseText: string, isUniquePredicate: (text:string)=> boolean): string|null => {

    if(isUniquePredicate(baseText)) {
        return baseText
    }

    for (let i = 0; i < 1000; i++) {
        const suffix = i.toString().padStart(3, '0')
        const attempt = baseText + suffix

        if(isUniquePredicate(attempt)) {
            return attempt
        }
    }

    return null
}

export const isValidIdentifier = (proposedInsightsFieldName: string) => {
    const result = positivefieldNameRegex.test(proposedInsightsFieldName)
    return result
}

export const calculateSuggestedFieldName = (customFieldName: string, isUniquePredicate: StringPredicate) => {
    let result = pascalize(customFieldName)
    result = handleIllegalFirstCharacter(result)
    result = stripInvalidBodyCharacters(result).slice(0, 32)

    const uniqueSuggestion = tryToFindUniqueFieldNameSuggestion(result, isUniquePredicate)

    if (uniqueSuggestion !== null) {
        return uniqueSuggestion
    }

    return uniqueSuggestion === null ? "EnterFieldNameHere" : uniqueSuggestion
}

