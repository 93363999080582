import React from 'react'
import { IDashboardManagementScreenDefinitions } from "./dashboardManagementScreenTypes"
import { ViewDashboardRoute } from "../ViewDashboard/ViewDashboardRoute"
import { ResetDashboardV2Route } from '../ResetDashboardsV2/ResetDashboardV2Route'

export const computeDashboardManagementDefinitions = (toggles: string[]): IDashboardManagementScreenDefinitions => ({
    ViewDashboard: {
        title: "Manage",
        gatingPermission: "ManageReports",
        component: (props: any) => <ViewDashboardRoute {...props} toggles={toggles} />,
        aliasPaths: ["/DashboardManagement_EditDashboard/*"],

    },
    ResetDashboardV2: {
        title: "Reset",
        gatingPermission: "ResetDashboards",
        component: ResetDashboardV2Route,
    }
}
)