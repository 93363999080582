import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from '../../Api/useAxios'

export interface IGetCustomColumn {
    id: number,
    tenantId: string,
    tableName: string,
    columnName: string,
    expression: string,
    dataType: string,
    summarizeBy: string,
    decimalPlaces: number,
    status: string,
    errorMsg: string
}

type TUseGetCustomColumn = [
    {
        customColumnInfo: IGetCustomColumn[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]

export const useGetCustomColumnInfo = (): TUseGetCustomColumn => {

const getCustomColumnsUrlPath = `${buildServerApiUrl("PowerBi.GetCalculatedColumns").toString()}/${getCustomerKey()}`


    const [{ data, loading, errors, status }, reFetchData1] = useAxiosGet(
        getCustomColumnsUrlPath
    )
    let customColumnInfo: IGetCustomColumn[] = []
    if (data) {
        customColumnInfo = data
    }
    return [{ customColumnInfo, loading, status, errors }, reFetchData1]
}


export interface IUpsertCustomColumnPayload {
    customerKey: string,
    columnName: string,
    dataType: string,
    TableName: string,
    expression: string,
    summarizeBy: string,
    decimalPlace: number,
    errorMsg: string
}

export type IUpsertCustomColumn = Omit<IUpsertCustomColumnPayload, "customerKey">

type TUseUpsertCustomColumn = [
    (payload: IUpsertCustomColumn) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const upsertCustomColumnUrl = `${buildServerApiUrl("PowerBi.UpsertCalculatedColumn").toString()}`
export const useUpsertCustomColumns = (
    options?: IUseAxiosOptions<IUpsertCustomColumnPayload>
): TUseUpsertCustomColumn => {
    
    const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertCustomColumnPayload>(
        upsertCustomColumnUrl, {}, options
    )

    const upsertCustomColumn = async (payload: IUpsertCustomColumn) => {
        const res = await upsert({ ...payload, customerKey:getCustomerKey() })
        return res
    }

    return [upsertCustomColumn, { posting, status, errors }]
}

export interface IDeleteCustomColumnPayload {
    customerKey: string,
    id: number,
    tableName: string,
    columnName: string
}

export type IDeleteCustomColumn = Omit<IDeleteCustomColumnPayload, "customerKey">

type TUseDeleteCustomColumn = [
    (payload: IDeleteCustomColumn) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const deleteCustomColumnUrl = `${buildServerApiUrl("PowerBi.DeleteCalculatedColumns").toString()}`
export const useDeleteCustomColumn = (
    options?: IUseAxiosOptions<IDeleteCustomColumnPayload>
): TUseDeleteCustomColumn => {
    
    const [execute, { posting, status, errors }] = useAxiosPost<IDeleteCustomColumnPayload>(
        deleteCustomColumnUrl, {}, options
    )

    const deleteCustomColumn = async (payload: IDeleteCustomColumn) => {
        const res = await execute({ ...payload, customerKey:getCustomerKey() })
        return res
    }

    return [deleteCustomColumn, { posting, status, errors }]
}


export interface IGetTables {
    tableName: string,
    tableAlias: string
}

type TUseGetTables = [
    {
        tablesInfo: IGetTables[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]


export const useGetTables = (): TUseGetTables => {
    
    const getTablesUrlPath = `${buildServerApiUrl("PowerBi.GetTablesFromPowerBiDataset").toString()}/${getCustomerKey()}`

    const [{ data, loading, errors, status }, reFetchData2] = useAxiosGet(
        getTablesUrlPath
    )
    let tablesInfo: IGetTables[] = []
    if (data) {
        tablesInfo = data
    }
    return [{ tablesInfo, loading, status, errors }, reFetchData2]
}