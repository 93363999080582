import { IAppNavigationUIRoute } from "navex-react"
import { IPowerBiReports } from "../../Models/GlobalState"
import { faChartBar } from "@fortawesome/free-solid-svg-icons"
import { TOGGLENAME } from "../../ts/enums/releaseToggles"
import { pascalize } from "../../Utils/utils"

export const computePowerBiDashboardNavTree = (toggles: TOGGLENAME[], powerBiReportsLeftNavMenu: IPowerBiReports[] | []): IAppNavigationUIRoute[] | null => {

    if (powerBiReportsLeftNavMenu.length === 0)
        return null;

    return computeNavPowerBiReportEntries(toggles, powerBiReportsLeftNavMenu);
}

export const computeNavPowerBiReportEntries = (toggles: TOGGLENAME[], powerBiReportsLeftNavMenu: IPowerBiReports[]): IAppNavigationUIRoute[] => {
    if (powerBiReportsLeftNavMenu.length === 0) {
        return []
    }

    const collectionSubroutes = powerBiReportsLeftNavMenu.filter(f => f.leftNavName !== "NAVEX One Insights").map<IAppNavigationUIRoute>(reports => {
        const mapResult: IAppNavigationUIRoute = {
            id: `route_${pascalize(reports.leftNavName)}`,
            title: reports.leftNavName === "RiskRate" ? "Third Party Risk" : reports.leftNavName,
            icon: faChartBar,
            subRoutes: computeNavReportEntries(toggles, reports.leftNavName, reports.powerBiReports)
        }
        return mapResult
    })
    return collectionSubroutes
}

export const computeNavReportEntries = (toggles: TOGGLENAME[], leftNavName: string, ReportSpecs: { displayName: string, reportId: string }[]): IAppNavigationUIRoute[] => {

    const sortObj = ["Campaign Overview", "Disclosure Overview", "Conflict Overview", "User Report", "User Overview", "Multiple Campaign Overview", "Active Campaign Overview", "KPIs", "Named vs. Anonymous", "Issue Types", "Open Cases", "Closed Cases", "Third Parties", "Reports and Assessments", "Risk Codes"]
    const sortedObj = ReportSpecs.slice().sort((a, b) => {
        return (
            sortObj.indexOf(a.displayName) - sortObj.indexOf(b.displayName)
        );
    });
    const result = sortedObj.map<IAppNavigationUIRoute>((obj): IAppNavigationUIRoute => {
        const { displayName, reportId } = obj
        const path = computePowerBiReportRoutePath(leftNavName, displayName)
        const route = `route_${displayName.replace(/ /g, "")}`

        const result: IAppNavigationUIRoute = {
            id: route,
            title: displayName,
            path,
            badgeType: 'new',
            badgeText: 'new'
        }

        result.data = {
            leftNavName: leftNavName,
            page: displayName,
            displayName: displayName,
            reportid: reportId,
            active: false
        }
        return result
    })

    return result
}

/**
 * @param leftNavName The leftNav name for powerBi
 * @param displayName The report name
 * @param filters Expected to already be correctly formatted
 */
export const computePowerBiReportRoutePath = (leftNavName: string, displayName: string, filters?: string) => {
    const query = !filters ? "" : `?filters=${filters}`
    return `/Reports/${encodeURIComponent(leftNavName)}_${encodeURIComponent(displayName)}${query}`
}
