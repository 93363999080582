export enum FISCALCALENDARSTATUS {
    INPROCESS = "InProcess",
    FAILED = "Failed",
    SUCCESS = "Success",
    EDITING = "Editing",
    EDITINGFAILED = "EditingFailed",
    EDITINGSUCCESS = "EditingSuccess",
    DELETING = "Deleting",
    DELETINGFAILED = "DeletingFailed",
    DELETED = "Deleted"
}
