import { useState, useEffect } from 'react'
import { getBirstTokenUrlFromServer } from '../../Api/api'
import { buildServerApiUrl, getCustomerKey, buildUrlForApiAuthRedirectToKeyCloakV3 } from '../../Api/urls'
import { useAxiosPost, IUseAxiosOptions } from '../../Api/useAxios'
import { AxiosResponse } from 'axios'
import { Omit } from '../../Utils/typeHelpers'


export const useGetDashboardUrl = (spaceId: string) => {    

    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [dashboardUrl, setUrl] = useState(undefined as undefined | URL)

    const fetchAsync = async (useSpaceId: string) => {

        const apiResult = await getBirstTokenUrlFromServer(useSpaceId, getCustomerKey())

        if (apiResult.ok) {
            const { dashboardUrl } = apiResult.response.data as any

            const url = new URL(dashboardUrl || "")
            setUrl(url)
        }
        else {
            if (apiResult.error) {
                if (apiResult.error.response) {
                    if (apiResult.error.response.status === 401) {
                        window.location.href = buildUrlForApiAuthRedirectToKeyCloakV3().toString()
                    }
                }
            }
            else {
                setHasError(true)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!spaceId) {
            setHasError(true)
            setLoading(false)
            return
        }
        fetchAsync(spaceId)
    }, [spaceId])


    return { loading, dashboardUrl, hasError, refetch: fetchAsync }
}


interface IGetBirstUrlPayload {
    spaceId: string,
    customerKey: string,
    showEditToolbar: boolean
}

export type IGetBirstUrl = Omit<IGetBirstUrlPayload, "customerKey">

type TUseGetBirstUrl = [
    (payload: IGetBirstUrl) => Promise<AxiosResponse<any> | undefined>,
    {
        birstUrlLoading: boolean,
        status?: string | number,
        birstUrl?: string,
        errors: any[],
    }
]

const getBirstUrlPath = `${buildServerApiUrl("BirstToken.GetBirstUrl", "v2").toString()}`
export const useGetBirstUrl = (options?: IUseAxiosOptions<IGetBirstUrlPayload>): TUseGetBirstUrl => {    
    const [getBirstUrl, { posting: birstUrlLoading, status, data, errors }] = useAxiosPost(
        getBirstUrlPath, {}, options)


    let birstUrl = undefined

    if (data) {
        birstUrl = data.dashboardUrl

    }

    const fetch = async (payload: IGetBirstUrl) => {
        const res = await getBirstUrl({ ...payload, customerKey:getCustomerKey() })
        return res
    }

    return [fetch, { birstUrlLoading, status, birstUrl, errors }]

}