var BirstReport = function ( container, options ) {
    if ( container === null ) {
        return;
    }
    this.options = options;
    this.container = document.getElementById( container );
    this.iframeId = container + "-iframe";
    this.setWindowListener();
};

BirstReport.prototype.postData = function ( data ) {
    this.iframe.contentWindow.postMessage( data, "*" );
};

BirstReport.prototype.getData = function ( query, callerId, executeQueryProperties ) {

    this.callerId = callerId;
    window.parent.postMessage( { operation : 'executeQuery', query : query, callerId : callerId, properties : executeQueryProperties }, "*" );
};

BirstReport.prototype.setListener = function( listener ) {
    this.listener = listener;
};

BirstReport.prototype.init = function () {
    var _self = this;
    if ( this.options.iframeSrc ) {
        _self.container.innerHTML = _self.getFrameDefBySrc( this.options.iframeSrc );
        _self.iframe = document.getElementById( _self.iframeId );
        _self.resetDimension();
    }
    else {
        var xhr = new XMLHttpRequest();

        var tokenUrl = this.options.applicationUrl + "/TokenGenerator.aspx?";
        tokenUrl += "username=" + this.options.username;
        tokenUrl += "&ssopassword=" + this.options.ssopassword;
        tokenUrl += "&BirstSpaceId=" + this.options.spaceId;

        xhr.open( 'POST', tokenUrl );
        xhr.setRequestHeader( 'Content-Type', 'application/json' );
        xhr.addEventListener( 'load', function () {
            var token = xhr.responseText; //or e.responseText
            _self.container.innerHTML = _self.getFrameDefByToken( token );
            _self.iframe = document.getElementById( _self.iframeId );
            _self.resetDimension();

        }, false );
        xhr.addEventListener( "error", function () {
            alert( "Installation Failed: Invalid Token" );
        }, false );
        xhr.send( null );
    }

    if( this.options.showSpinner ){
        var spinnerEl = document.createElement("div");
        spinnerEl.innerHTML = '<div id="iframeLoader"\n' +
            '        class="loader loader-white loading">\n' +
            '         <img alt="Loading..." src="/js/wait.gif" />' +
            '    </div>';
        spinnerEl.style.setProperty( 'position', 'absolute' );
        spinnerEl.style.setProperty( 'width', '3rem' );
        spinnerEl.style.setProperty( 'height', '3rem' );
        spinnerEl.style.setProperty( 'top', '0' );
        spinnerEl.style.setProperty( 'left', '0' );
        spinnerEl.style.setProperty( 'right', '0' );
        spinnerEl.style.setProperty( 'bottom', '0' );
        spinnerEl.style.setProperty( 'margin', 'auto' );
        _self.container.appendChild( spinnerEl );
    }
    var errorLoading = document.createElement("div");
    errorLoading.setAttribute("id", "errorLoading");
    errorLoading.innerHTML = '<div>Error Loading Report</div>\n';
    errorLoading.style.setProperty( 'position', 'absolute' );
    errorLoading.style.setProperty( 'text-align', 'center' );
    errorLoading.style.display = 'none';
    _self.container.appendChild( errorLoading );
};

BirstReport.prototype.setWindowListener = function(){
    var _self = this;
    window.onmessage = function ( e ) {
        if ( e.data.operation === "executeQueryResult" ) {
            if ( e.data.callerId === _self.callerId ) {
                if(_self.listener) _self.listener( e );
            }
        } else  if ( e.data.operation === "iFrameLoaded" ) {
            var el1 = document.getElementById( 'iframeLoader');
            if( el1 ) el1.style.display = 'none';
            if(_self.listener) _self.listener( e );
        } else  if ( e.data.operation === "errorLoadingReport" ) {
            var el2 = document.getElementById( 'errorLoading');
            if( el2 ) el2.style.display = 'contents';
            if(_self.listener) _self.listener( e );
        } else {
            if(_self.listener) _self.listener( e );
        }

    };
};

BirstReport.prototype.resetDimension = function () {
    if ( this.options.width || this.options.height ) {
        if ( this.options.width ) {
            this.iframe.width = this.options.width;
        }
        if ( this.options.height ) {
            this.iframe.height = this.options.height;
        }
        this.container.style.overflow = "auto";
        this.container.style.setProperty( 'position', 'relative', 'important' );
    }
    else {
        this.container.style.setProperty( 'position', 'relative', 'important' );
        this.container.style.setProperty( 'overflow', 'hidden', 'important' );

        //for iframe
        this.iframe.style.setProperty( 'position', 'absolute', 'important' );
        this.iframe.style.setProperty( 'top', 0, 'important' );
        this.iframe.style.setProperty( 'left', 0, 'important' );
        this.iframe.style.setProperty( 'width', '100%', 'important' );
        this.iframe.style.setProperty( 'height', '100%', 'important' );
    }

};

BirstReport.prototype.getFrameDefByToken = function ( token ) {
    var paramString = this.getIframeSrc( token );
    var frameDef = "<iframe id=\"" + this.iframeId + "\" scrolling='yes' frameborder=\"0\" ";
    frameDef += " src=\"" + paramString;
    frameDef += "\" ";
    frameDef += "></iframe>";
    return frameDef;
};

BirstReport.prototype.getFrameDefBySrc = function ( src ) {
    var frameDef = "<iframe id=\"" + this.iframeId + "\" scrolling='yes' frameborder=\"0\" ";
    frameDef += " src=\"" + src;
    frameDef += "\" ";
    frameDef += "></iframe>";
    return frameDef;
};

BirstReport.prototype.getIframeSrc = function ( token ) {
    var parseString = this.options.applicationUrl + (this.options.applicationUrl.substr( -1 ) === "/" ? "" : "/");
    parseString += "SSO.aspx?BirstSSOToken=" + token;
    for ( var propertyName in this.options ) {
        if ( propertyName === "applicationUrl" || propertyName === "username" || propertyName === "ssopassword" || propertyName === "spaceId" || propertyName === "token" ) {
            continue;
        }
        else {
            parseString += "&" + propertyName + "=" + this.options[propertyName];
        }
    }
    return parseString;
};

var BirstConfig = {
    create : function ( container, options ) {
        this.report = new BirstReport( container, options );
        this.report.init();
    },
    callBack : function ( listener ) {
        if ( !this.report ) {
            this.report = new BirstReport();
        }
        this.report.setListener( listener );
    },
    postData : function ( data ) {
        if ( !this.report ) {
            this.report = new BirstReport();
        }
        this.report.postData( data );
    },
    getData : function ( query, executeQueryProperties ) {
        if ( !this.report ) {
            this.report = new BirstReport();
        }
        var messageId = new Date().getTime() + "-" + Math.random();
        this.report.getData( query, messageId, executeQueryProperties );
    },
    registerTimeout : function( millisecond ) {
        window.parent.postMessage( {
            operation : 'registerTimeout',
            duration : millisecond
        }, '*' );
    },
    contentLoaded : function(){
        window.parent.postMessage( {
            operation : 'htmlContentLoaded',
        }, '*' );
    },
    toggleDashboardExportPanel : function( mode ) {
        window.parent.postMessage( {
            mode : mode,
            operation : 'toggleDashboardExportPanel',
        }, '*' );
    }
};

export {BirstConfig}