import React, { useCallback, useEffect, useState } from 'react'
import { DefaultPage, Modal, ToastId, Toasts } from 'navex-react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { useDeleteFiscalCalendar, useGetFiscalCalendar } from './FiscalCalendarApi'
import { FiscalCalendarListView } from './FiscalCalendarListView'
import { FiscalCalendarDetailsView } from './FiscalCalenderDetailsView'
import { PreviewFiscalCalendar } from './PreviewFiscalCalendar'
import { FISCALCALENDARSTATUS } from '../../ts/enums/fiscalCalendarStatus'
import { addDays, months } from '../../Utils/fiscalCalendarUtils'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
    toggles: string[]
}

export const FiscalCalendarRoute = (props: IProps) => {
    const toggle = props.toggles.includes(TOGGLENAME.POWERBI_V2)
    
    const [{ fiscalCalendarItems, loading, status, errors }, reFetch] = useGetFiscalCalendar(toggle)

    const [isEdit, setIsEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [deleteFiscalYear, setDeleteFiscalYear] = useState(-1)
    const [isPreview, setIsPreview] = useState(false)

    // Being used in Formik as initial value and
    // initializing the state to 0 to make the component controlled
    const [oldFiscalYear, setOldFiscalYear] = useState<number>(0)
    const [newFiscalYear, setNewFiscalYear] = useState<number>(0)

    // Setting default value
    const [fiscalFormat, setFiscalFormat] = useState("Annual Calendar")
    
    const [startDate, setStartDate] = useState<string | undefined>()
    const [endDate, setEndDate] = useState<string | undefined>()
    const [fiscalMonth, setFiscalMonth] = useState<string>('')
    const [startPoll, setStartPoll] = useState(true)

    let toastId: ToastId = 0

    const lastFiscalYearCreated = fiscalCalendarItems.length === 1 ? fiscalCalendarItems[0]
        : fiscalCalendarItems.sort((a, b) => (b.fiscalYear - a.fiscalYear))[0]

    const checkEligibleForPolling = useCallback(() => {
        let eligible = false
        fiscalCalendarItems.forEach(fci => {
            if (fci.status === FISCALCALENDARSTATUS.INPROCESS || fci.status === FISCALCALENDARSTATUS.EDITING || fci.status === FISCALCALENDARSTATUS.DELETING) {
                eligible = true
            }
        })
        return eligible
    }, [fiscalCalendarItems])

    useEffect(() => {
        if (startPoll && checkEligibleForPolling()) {
            const id = setInterval(() => { reFetch(true) }, window.fiscalCalendarPollTimeout)
            return () => { clearInterval(id) }
        }
    }, [startPoll, reFetch, checkEligibleForPolling])

    const handleAddFiscalYearBtnClick = () => {
        setIsEdit(true)
        setOldFiscalYear(0)
        setNewFiscalYear(0)
        if (lastFiscalYearCreated !== undefined) {
            const startDate = addDays(lastFiscalYearCreated.endDate, 1)
            setFiscalFormat(lastFiscalYearCreated.fiscalFormat)
            setStartDate(startDate.toLocaleDateString())
            setEndDate(addDays(lastFiscalYearCreated.endDate, 364).toLocaleDateString())
            setNewFiscalYear(lastFiscalYearCreated.fiscalYear + 1)
            setFiscalMonth(months[startDate.getMonth()])
        }
    }

    const onClickDeleteCancel = () => {
        setShowModal(false)
    }

    const [Delete] = useDeleteFiscalCalendar(toggle,{
        onSuccess: () => {
            reFetch(false)
            setStartPoll(true)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
            reFetch(false)
            setStartPoll(true)
        }
    })

    const handleDeleteBtnClick = () => {
        Delete({
            fiscalYear: deleteFiscalYear
        })
        setShowModal(false)
    }

    if (errors.length) return <UnexpectedError />
    if (loading || !status) return <Spinner />

    return (
        <DefaultPage>
            {
                !isEdit
                    ?
                    <FiscalCalendarListView {...{
                        fiscalCalendarItems, isEdit, setIsEdit, setOldFiscalYear, setFiscalFormat, setNewFiscalYear, reFetch,
                        setStartDate, setEndDate, setFiscalMonth, handleAddFiscalYearBtnClick, setShowModal, setDeleteFiscalYear,
                        startPoll, checkEligibleForPolling, setStartPoll,toggle
                    }} />
                    :
                    isPreview
                        ?
                        <PreviewFiscalCalendar {...{
                            startDate, endDate, fiscalFormat, fiscalMonth, newFiscalYear, setIsPreview
                        }} />
                        :
                        <FiscalCalendarDetailsView {...{
                            isEdit, setIsEdit, reFetch, oldFiscalYear, newFiscalYear, setOldFiscalYear, setNewFiscalYear, fiscalFormat, setFiscalFormat
                            , setIsPreview, startDate, setStartDate, endDate, setEndDate, fiscalMonth, setFiscalMonth, setStartPoll,toggle
                        }} />
            }
            <Modal id={deleteFiscalYear + "_deleteModal"}
                okButtonText="Delete"
                cancelButtonText="Cancel"
                headerText="Delete Confirmation"
                message={`Are you sure you wish to delete Fiscal Year "${deleteFiscalYear}" from Insights?`}
                onClickedOk={handleDeleteBtnClick}
                onClickedCancel={onClickDeleteCancel}
                show={showModal}
                modalType="BASIC" />
        </DefaultPage>
    )
}

