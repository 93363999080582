import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from "formik"
import { Button, FormikOption, FormikSelect, FormikDatePicker, Checkbox } from "navex-react"
import { Toasts, ToastId } from 'navex-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import React, { FC, useState } from "react"
import { object, string } from "yup"
import { Timezones } from "./TimeZones"
import { setHours, setMinutes } from "date-fns"
import { usePowerBiSubscription } from "./PowerBIEmbeddedDashboardAPI"
import { ButtonSpinner } from '../Shared/Spinner'

interface IProps {
    reportId: string,
    subscribeModal: boolean,
    setSubscribeModal: (value: boolean) => void
}

const timeZones = Timezones
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const SubscribeButton: FC<IProps> = (props: IProps) => {

    const [isSaving, setIsSaving] = useState(false)
    const [chooseWeekday, setWeekday] = useState([days[0]])
    let toastId: ToastId = 0
    const [upsert] = usePowerBiSubscription({
        onSuccess: () => {
            toastId = Toasts.success("Saved Successfully")
            handleCreateDashbordModalXClose()
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target
        if (checked) {
            setWeekday(
                [...chooseWeekday, value]
            )
        }
        else {
            setWeekday(
                chooseWeekday.filter(
                    (e: string) => e !== value
                )
            )
        }
    }

    const handleCreateDashbordModalXClose = () => {
        props.setSubscribeModal(false)
    }

    const closeRolesModalXButton = (
        <button
            id="closeXButton"
            tabIndex={0}
            className="close modal-header-close"
            onClick={handleCreateDashbordModalXClose}
            aria-label={"close"}
            type="button"
        >
            <FontAwesomeIcon icon={faTimes} />
        </button>
    )

    const computeWeekdayString = (weekdays: string[]) => {
        let result = ""
        weekdays.forEach((day, index) => {
            if (index === weekdays.length - 1) {
                result += "\"" + day + "\""
            }
            else {
                result += "\"" + day + "\"" + ","
            }
        })
        return result
    }
    return <>
        <Modal isOpen={props.subscribeModal}>
            <Formik
                initialValues={{
                    SubscriptionName: "",
                    EmailRecipient: "",
                    Format: "",
                    StartDate: "",
                    EndDate: "",
                    Frequency: "",
                    WeekDay: "",
                    MonthOnDays: "",
                    Hour: "",
                    Mins: "",
                    Meridiem: "",
                    TimeZone: ""
                }}

                onSubmit={async (values, actions) => {
                    setIsSaving(true)
                    actions.setSubmitting(true)
                    var hour = values.Meridiem === "PM" && values.Hour !== "12" ? Number(values.Hour) + 12 : values.Meridiem === "AM" && values.Hour === "12" ? Number(values.Hour) - 12 : Number(values.Hour)
                    await upsert({
                        ReportId: props.reportId,
                        SubscribeName: values.SubscriptionName,
                        RecipientMailId: values.EmailRecipient,
                        ReportTypeFormat: values.Format,
                        SubscriptionStartDate: new Date(new Date(values.StartDate).setHours(new Date(values.StartDate).getHours() + 6 + hour, (new Date(values.StartDate).getMinutes() - 30) + Number(values.Mins), 0)),
                        Frequency: values.Frequency,
                        Status: true,
                        Timezone: values.TimeZone,
                        ReportUrl: window.location.href,
                        WeekOfFrequency: values.Frequency === "Weekly" && chooseWeekday ? computeWeekdayString(chooseWeekday) : null,
                        MonthOfFrequency: values.Frequency === "Monthly" && values.MonthOnDays ? values.MonthOnDays : null
                    })
                    setIsSaving(false)
                    actions.setSubmitting(false)
                }}

                validationSchema={object().shape({
                    SubscriptionName: string()
                        .required("Enter Subscription Name"),
                    EmailRecipient: string().matches(/^(?!.*(\b(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\b).*\b\1\b)(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/g, "Enter valid email recipient(s) separated by comma")
                        .required("Enter Email Recipient"),
                    Format: string()
                        .required("Choose Format"),
                    StartDate: string()
                        .required("Set Start Date"),
                    Frequency: string()
                        .required("Choose Frequency"),
                    Hour: string()
                        .required("Choose Hour(s)"),
                    Mins: string()
                        .required("Choose Min(s)"),
                    Meridiem: string()
                        .required("Choose AM/PM"),
                    TimeZone: string()
                        .required("Choose Time Zone"),
                    MonthOnDays: string().matches(/^(?!.*(\b(?:[1-9]|[1-2]\d|3[0-1])\b).*\b\1\b)(?:[1-9]|[1-2]\d|3[0-1])(?:,(?:[1-9]|[1-2]\d|3[0-1]))*$/g, "Enter valid non-repeating day(s) separated by comma")
                })}
                validateOnBlur
            >
                {({ values,
                    errors,
                    touched,
                    setFieldValue,
                    isSubmitting, }) => (
                    <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <ModalHeader close={closeRolesModalXButton}>Subscription</ModalHeader>
                        <ModalBody style={{ maxHeight: "39rem" }}>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="SubscriptionName">Subscription Name
                                            <span className="validation-required">{" * "}</span>
                                            {errors.SubscriptionName && touched.SubscriptionName ? (<span className="validation-label" aria-hidden="false">{errors.SubscriptionName}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="SubscriptionName" name="SubscriptionName" className="form-control" aria-describedby="name-help-block"
                                                aria-invalid={errors.SubscriptionName && touched.SubscriptionName} aria-required="true" placeholder="Enter name here"
                                                required />
                                            <span className="add-clear-x form-control-feedback" style={{
                                                color: "rgb(51, 51, 51)",
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                overflow: "hidden",
                                                position: "absolute",
                                                pointerEvents: "auto",
                                                right: 0,
                                                top: 0,
                                                zIndex: "auto",
                                                display: values.SubscriptionName.length > 0 ? undefined : "none",
                                            }}
                                                onClick={() => setFieldValue("SubscriptionName", "")}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label" htmlFor="EmailRecipient">Recipient(s)
                                            <span className="validation-required">{" * "}</span>
                                            {errors.EmailRecipient && touched.EmailRecipient ? (<span className="validation-label" aria-hidden="false">{errors.EmailRecipient}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="EmailRecipient" name="EmailRecipient" className="form-control" aria-describedby="name-help-block" placeholder="Enter email id('s) separated by comma"
                                                aria-invalid={errors.EmailRecipient && touched.EmailRecipient} aria-required="true"
                                                required />
                                            <span className="add-clear-x form-control-feedback" style={{
                                                color: "rgb(51, 51, 51)",
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                overflow: "hidden",
                                                position: "absolute",
                                                pointerEvents: "auto",
                                                right: 0,
                                                top: 0,
                                                zIndex: "auto",
                                                display: values.EmailRecipient.length > 0 ? undefined : "none",
                                            }}
                                                onClick={() => setFieldValue("EmailRecipient", "")}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label" htmlFor="Format">Format
                                            <span className="validation-required">{" * "}</span>
                                            {errors.Format && touched.Format ? (<span className="validation-label" aria-hidden="false">{errors.Format}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="Format" component={FormikSelect} name="Format"
                                                aria-invalid={errors.Format && touched.Format} aria-required="true" title={values.Format ? values.Format : "Select Format"}
                                                required maxMenuHeight="200px" validationErrorMessage=""
                                                onChange={(value: any) => { setFieldValue('Format', value) }} >
                                                {['PDF', 'PPTX'].map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })}</Field>
                                        </div>
                                    </div>

                                    <label className="control-label" htmlFor="ScheduledDate">Scheduled Date
                                        <span className="validation-required">{" * "}</span>
                                    </label>
                                    <table id="ScheduledDate">
                                        <tbody>
                                            <tr>
                                                <th scope="col"><Field
                                                    id="StartDate"
                                                    name="StartDate"
                                                    label="Start Date"
                                                    required
                                                    minDate={setHours(setMinutes(new Date(), 0), 12)}
                                                    maxDate={values.EndDate ? values.EndDate : ""}
                                                    shouldCloseOnSelect
                                                    validationErrorMessage="Set Start Date"
                                                    component={FormikDatePicker}
                                                    openToDate={new Date()}
                                                    onChange={(value: any) => {
                                                        setFieldValue('StartDate', value)
                                                    }}
                                                />
                                                </th>
                                                <th scope="col" style={{ paddingLeft: "1rem" }} />
                                                <th scope="col"><Field
                                                    id="EndDate"
                                                    name="EndDate"
                                                    label="End Date"
                                                    minDate={values.StartDate ? values.StartDate : setHours(setMinutes(new Date(), 0), 12)}
                                                    shouldCloseOnSelect
                                                    component={FormikDatePicker}
                                                    openToDate={new Date()}
                                                    onChange={(value: any) => {
                                                        setFieldValue('EndDate', value)
                                                    }}
                                                />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="form-group">
                                        <label className="control-label" htmlFor="Frequency">Frequency
                                            <span className="validation-required">{" * "}</span>
                                            {errors.Frequency && touched.Frequency ? (<span className="validation-label" aria-hidden="false">{errors.Frequency}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="Frequency" component={FormikSelect} name="Frequency"
                                                aria-invalid={errors.Frequency && touched.Frequency} aria-required="true" title={values.Frequency ? values.Frequency : "Select Frequency"}
                                                required maxMenuHeight="200px" validationErrorMessage=""
                                                onChange={(value: any) => { setFieldValue('Frequency', value) }} >
                                                {['Daily', 'Weekly', 'Monthly'].map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })}</Field>
                                        </div>
                                    </div>
                                    {values.Frequency === 'Weekly' ?
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="WeekDay">Weekday(s)
                                                <span className="validation-required">{" * "}</span>
                                                {chooseWeekday.length === 0 ? (<span className="validation-label" aria-hidden="false">{errors.WeekDay}</span>) : null}
                                            </label>
                                            <table id="Weekdays">
                                                <tr>
                                                    <th scope="col">
                                                        {days.map(item =>
                                                            <Checkbox
                                                                defaultChecked={item === days[0] ? true : false}
                                                                className={item}
                                                                name={item}
                                                                label={item}
                                                                value={item}
                                                                id={item}
                                                                onChange={handleChange} enterKeyHint={undefined} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                                        )}
                                                    </th>
                                                </tr>
                                            </table>
                                        </div> :
                                        values.Frequency === 'Monthly' ?
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="MonthOnDays">Every month on day(s)
                                                <span className="validation-required">{" * "}</span>
                                                {errors.MonthOnDays && touched.MonthOnDays ? (<span className="validation-label" aria-hidden="false">{errors.MonthOnDays}</span>) : null}
                                            </label>
                                            <div className="form-control-feedback-group">
                                                <Field id="MonthOnDays" name="MonthOnDays" className="form-control" aria-describedby="name-help-block"
                                                    aria-invalid={errors.MonthOnDays && touched.MonthOnDays} aria-required="true" placeholder="Ex. 1,16,29"
                                                    required multiple />
                                                <span className="add-clear-x form-control-feedback" style={{
                                                    color: "rgb(51, 51, 51)",
                                                    cursor: "pointer",
                                                    textDecoration: "none",
                                                    overflow: "hidden",
                                                    position: "absolute",
                                                    pointerEvents: "auto",
                                                    right: 0,
                                                    top: 0,
                                                    zIndex: 100,
                                                    display: values.MonthOnDays.length > 0 ? undefined : "none",
                                                }}
                                                    onClick={() => setFieldValue("MonthOnDays", "")}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                            </div>
                                        </div> : ""}

                                    {<div className="form-group">
                                        <label className="control-label" htmlFor="TimeZone">Time Zone
                                            <span className="validation-required">{" * "}</span>
                                            {errors.TimeZone && touched.TimeZone ? (<span className="validation-label" aria-hidden="false">{errors.TimeZone}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="TimeZone" component={FormikSelect} name="TimeZone"
                                                aria-invalid={errors.TimeZone && touched.TimeZone} aria-required="true" title={values.TimeZone ? values.TimeZone : "Select Time Zone"}
                                                required maxMenuHeight="200px" validationErrorMessage=""
                                                onChange={(value: any) => { setFieldValue('TimeZone', value) }} >
                                                {timeZones.map(tz => {
                                                    return <FormikOption value={tz.value} key={tz.text}>{tz.text}</FormikOption>
                                                })}</Field>
                                        </div>
                                    </div>}

                                    {<div className="form-group">
                                        <label className="control-label" htmlFor="SceduledTime">Scheduled Time
                                            <span className="validation-required">{" * "}</span>
                                        </label>
                                        <table id="ScheduledDate" className="">
                                            <tr>
                                                <th scope="col">
                                                    <Field id="Hour" component={FormikSelect} name="Hour"
                                                        aria-invalid={errors.Hour && touched.Hour} aria-required="true"
                                                        required maxMenuHeight="200px" title={values.Hour ? values.Hour : "Hour(s)"}
                                                        onChange={(value: any) => { setFieldValue('Hour', value) }} >
                                                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(yo => {
                                                            return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                        })}
                                                    </Field>
                                                </th>
                                                <th scope="col" style={{ paddingLeft: "1rem" }}>
                                                    <Field id="Mins" component={FormikSelect} name="Mins"
                                                        aria-invalid={errors.Mins && touched.Mins} aria-required="true"
                                                        required maxMenuHeight="200px" title={values.Mins ? values.Mins : "Min(s)"}
                                                        onChange={(value: any) => { setFieldValue('Mins', value) }} >
                                                        {['00', '15', '30', '45'].map(yo => {
                                                            return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                        })}
                                                    </Field>
                                                </th>
                                                <th scope="col" style={{ paddingLeft: "1rem" }}>
                                                    <Field id="Meridiem" component={FormikSelect} name="Meridiem"
                                                        aria-invalid={errors.Meridiem && touched.Meridiem} aria-required="true"
                                                        required maxMenuHeight="200px" title={values.Meridiem ? values.Meridiem : "AM/PM"}
                                                        onChange={(value: any) => { setFieldValue('Meridiem', value) }} >
                                                        {['AM', 'PM'].map(yo => {
                                                            return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                        })}</Field>
                                                </th>
                                            </tr>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {
                                <Button purpose="primary" type="submit" id="btnCreate" style={{ float: "right" }} disabled={isSubmitting}>{isSaving ? <>Saving...<ButtonSpinner /></> : "Save"}</Button>
                            }
                        </ModalFooter>
                    </Form>
                )
                }
            </Formik >
        </Modal></>
}
