import React, { FC } from 'react'
import { NoDataError } from '../Shared/Error'
import { IGetDateDiff } from './DateDiffApi'
import { DateDiffListItem } from './DateDiffListItem'

interface IProps {
    dateDiffInfo: IGetDateDiff[],
    setAddDateDiff: (addDateDiff: boolean) => void,
    reFetchData: () => void,
    setDateDiffId: (dateDiffId: number | undefined) => void,
    dateDiffId: number | undefined,
}

export const DateDiffListView: FC<IProps> = (props) => {

    return (<>
        <table id="dateDiffTable" className="table table-striped table-bordered">
            <thead>
                <tr style={{ backgroundColor: "#edecec" }}>
                    <th>From Date Table</th>
                    <th>From Date Column</th>
                    <th>To Date Table</th>
                    <th>To Date Column</th>
                    <th>Column Display Name</th>
                    <th>Duration</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.dateDiffInfo.length > 0
                        ?
                        (props.dateDiffInfo.map(dd => {

                            return <DateDiffListItem key={props.dateDiffId} DateDiffId={props.dateDiffId} FromDateTable={dd.fromDateTable}
                                FromDateColumn={dd.fromDateColumn} ToDateTable={dd.toDateTable} ToDateColumn={dd.toDateColumn} Id={dd.id}
                                ColumnDisplayName={dd.columnDisplayName} Duration={dd.duration} setAddDateDiff={props.setAddDateDiff} reFetchData={props.reFetchData}
                                setDateDiffId={props.setDateDiffId}
                            />
                        }))
                        : (<tr>
                            <td colSpan={6}><NoDataError /></td>
                        </tr>)
                }
            </tbody>
        </table>
    </>
    )
}