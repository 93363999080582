import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./ThrottledSearchBox.module.scss"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export interface IThrottledSearchBoxProps {
    placeholder: string,
    minWidth?: string,
    changeFilterText: (filterText: string) => any
}

interface IComponentState {
    searchText: string
}

export class ThrottledSearchBox extends React.Component<IThrottledSearchBoxProps, IComponentState> {
    constructor(props: IThrottledSearchBoxProps) {
        super(props)
        this.state = { searchText: "" }
    }

    timeoutId: any = null

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTextValue = event.currentTarget.value
        clearTimeout(this.timeoutId)

        this.timeoutId = setTimeout(() => {
            // Only call the changeFilterText callback when the user has stopped typing for at least 500 ms.
            this.props.changeFilterText(newTextValue)
        }, 500)

        this.setState({ searchText: event.target.value })
    }

    onSearchButtonClick = () => {
        this.props.changeFilterText(this.state.searchText)
    }

    render() {
        return <div className={styles.filterArea}>
            <input
                style={{
                    width: "40rem",
                    minWidth: "200px",
                }}
                type="text"
                className={styles.searchTerm}
                placeholder={this.props.placeholder}
                value={this.state.searchText}
                onChange={this.handleChange}
                data-test="searchText"
            />
            {/* TODO 430 : make this match the new look uxbridge has for live search boxes */}
            {/* TODO 430 : make live search replace magnifying glass with x when search has been entered */}
            <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} id={"searchIcon"}/>
        </div>
    }
}