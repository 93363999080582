import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import './Styles/index.scss'
import { InsightsApp } from './Components/InsightsApp/InsightsApp'
import { startupStore } from './Scripts/StartupStore'
import { Provider } from 'react-redux'
import { ErrorBoundary, ToastsContainer } from 'navex-react'
import { LogoutHandler } from './Components/Logout/LogoutHandler'
import { createRoot } from 'react-dom/client';

const loadInsightsApp = () => {
  const store = startupStore()
  const rootElement = document.getElementById('root') as HTMLElement;
  const root = createRoot(rootElement);

  root.render(
    <Provider store={store}>
      <ErrorBoundary message="An error occurred. Please refresh the page and try again.">
        <ToastsContainer />
        <LogoutHandler />
        <InsightsApp />
      </ErrorBoundary>
    </Provider>
  )
}

if (window.location.pathname.includes('ClearSession')) {
  localStorage.removeItem('insightsActive')
}
else {
  loadInsightsApp()
}