import { AxiosResponse } from 'axios'
import { models } from 'powerbi-client'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from '../../Api/useAxios'

export interface IPowerBiReport {
    id: number,
    name: string,
    status: string
    embedUrl: string,
    reportStatus: string | null
}
export interface ICollection {
    powerBiReports: IPowerBiReport[],
}
export interface IDataset {
    id: string,
    name: string,
    createReportEmbedUrl: string,
    status: string
}

export interface IReport {
    id: string,
    name: string,
    embedUrl: string,
    status: string,
    description: string,
    collection: string
}
export interface IEnabledReports {
    id: string,
    name: string,
    embedUrl: string,
    status: string,
    favorite: boolean,
    description: string,
    collection: string
}

export interface IReportInfo {
    workspaceId: string,
    datasets: IDataset[],
    reports: IReport[],
    enabledReports: IEnabledReports[]

}
type TUseGetReportData = [
    {
        reportInfo: IReportInfo,
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]

export const useGetPowerBiReportsInfo = (): TUseGetReportData => {
    
    const getReportsInfoUrlPath = `${buildServerApiUrl("PowerBi.GetReportsInfo").toString()}/${getCustomerKey()}`

    const [{ data, loading, errors, status }, reFetchDashboardData] = useAxiosGet(
        getReportsInfoUrlPath
    )

    let reportInfo

    if (data) {
        reportInfo = data
    }
    return [{ reportInfo, loading, status, errors }, reFetchDashboardData]
}

interface IEmbedTokenForCreatePayload {
    customerKey: string,
    datasetId: string,
}

type TUseGetEmbedTokenForCreate = [
    (payload: IEmbedTokenForCreatePayload) => Promise<AxiosResponse<any> | undefined>,
    {
        embedTokenForCreateInfo: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const getEmbedTokenForCreateUrlPath = `${buildServerApiUrl("PowerBi.GetEmbedTokenForCreate").toString()}`
export const useGetEmbedTokenForCreate = (options?: IUseAxiosOptions<IEmbedTokenForCreatePayload>): TUseGetEmbedTokenForCreate => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEmbedTokenForCreatePayload>(
        getEmbedTokenForCreateUrlPath, {}, options
    )

    const getEmbedTokenInfo = async (payload: IEmbedTokenForCreatePayload) => {
        const res = await execute(payload)
        return res
    }


    return [getEmbedTokenInfo, { posting, status, embedTokenForCreateInfo: data, errors }]
}


interface IEmbedTokenForReportPayload {
    customerKey: string,
    reportId: string,
    accessLevel: models.ViewMode
}

type TUseGetEmbedTokenForReport = [
    (payload: IEmbedTokenForReportPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        embedTokenForReportInfo: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const getEmbedTokenForReportUrlPath = `${buildServerApiUrl("PowerBi.GetEmbedTokenForReport").toString()}`
export const useGetEmbedTokenForReport = (options?: IUseAxiosOptions<IEmbedTokenForReportPayload>): TUseGetEmbedTokenForReport => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEmbedTokenForReportPayload>(
        getEmbedTokenForReportUrlPath, {}, options
    )

    const getEmbedTokenForReport = async (payload: IEmbedTokenForReportPayload) => {
        const res = await execute(payload)
        return res
    }


    return [getEmbedTokenForReport, { posting, status, embedTokenForReportInfo: data, errors }]
}

type IEnableReportPayload = {
    customerKey: string,
    id: string,
    workSpaceId: string,
    name: string,
    embedUrl: string
}

type TUseEnableReportPayload = [
    (payload: IEnableReportPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const enableReportUrl = `${buildServerApiUrl("PowerBi.EnableReport").toString()}`
export const useEnableReport = (
    options?: IUseAxiosOptions<IEnableReportPayload>
): TUseEnableReportPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEnableReportPayload>(
        enableReportUrl, {}, options
    )

    const enable = async (payload: IEnableReportPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [enable, { posting, status, data, errors }]
}

type IDisableReportPayload = {
    customerKey: string,
    id: string,
    workSpaceId: string
}

type TUseDisableReportPayload = [
    (payload: IDisableReportPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const disableReportUrl = `${buildServerApiUrl("PowerBi.DisableReport").toString()}`
export const useDisableReport = (
    options?: IUseAxiosOptions<IDisableReportPayload>
): TUseDisableReportPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IDisableReportPayload>(
        disableReportUrl, {}, options
    )

    const disable = async (payload: IDisableReportPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [disable, { posting, status, data, errors }]
}

type IDeleteReportPayload = {
    customerKey: string,
    id: string,
    workSpaceId: string
}

type TUseDeleteReportPayload = [
    (payload: IDeleteReportPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const deleteReportUrl = `${buildServerApiUrl("PowerBi.DeleteReport").toString()}`
export const useDeleteReport = (
    options?: IUseAxiosOptions<IDeleteReportPayload>
): TUseDeleteReportPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IDeleteReportPayload>(
        deleteReportUrl, {}, options
    )

    const deleteReport = async (payload: IDeleteReportPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [deleteReport, { posting, status, data, errors }]
}

type IEditReportDescriptionPayload = {
    customerKey: string,
    id: string,
    description: string
}

type TUseEditReportDescriptionPayload = [
    (payload: IEditReportDescriptionPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]


export const editReportDescription = `${buildServerApiUrl("PowerBi.EditReportDescription").toString()}`
export const useEditReportDescription = (
    options?: IUseAxiosOptions<IEditReportDescriptionPayload>
): TUseEditReportDescriptionPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEditReportDescriptionPayload>(
        editReportDescription, {}, options
    )

    const editDescription = async (payload: IEditReportDescriptionPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [editDescription, { posting, status, data, errors }]
}
