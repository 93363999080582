import React, { useState, FC, useEffect } from 'react'
import { pathPrefix } from '../../Api/urls'
import { IRole } from '../RolesV2/RolesApi'
import { Button, Checkbox, Modal, IButtonProps } from 'navex-react'
import { createBrowserHistory } from "history"
import { useUpsertReportsRoles } from "./PowerBIEmbeddedDashboardAPI"
import { Toasts, ToastId } from 'navex-react'

interface IProps {
    roles: IRole[],
    errors: any[],
    toggles: string[],
    reportId: string,
    customerKey: string,
    reportRoleIds: any[],
    refetchReportRoles: () => void
}

interface ICheckBoxState {
    [key: string]: boolean
}

export const ShareReport: FC<IProps> = (props) => {
    const insightAppHistory = createBrowserHistory({ forceRefresh: true })
    let toastId: ToastId = 0
    const initializeCheckboxes = ():ICheckBoxState => {
        let checkedValues: ICheckBoxState = {}
        props.roles.forEach(role => {
            if (props.reportRoleIds.includes(role.id))
                checkedValues[role.id] = true;
            else
                checkedValues[role.id] = false;
        });
        return checkedValues
    }
    const [checkBoxState, setCheckboxState] = useState<ICheckBoxState>(initializeCheckboxes())
    const [ModalText, setModalText] = useState(<p>ModalText</p>)
    const [showModal, setShowModal] = useState(false)
    const [okButtonProps, setOkButtonProps] = useState<IButtonProps>({ disabled: true })

    const onClickInsightsRolesLink = () => {
        insightAppHistory.push(`${pathPrefix}/Roles`, {})
    }
    
    const [upsertReportRoles] = useUpsertReportsRoles({
        onSuccess: () => {
            toastId = Toasts.success("Report assigned to the roles successfully.")
            props.refetchReportRoles();
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred while assigning the report to the roles . Please try again.")
            }
        }
    })

    const onCheckBoxStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const roleId = Number(event.target.value)
        const isChecked = event.target.checked
        const oldCheckBoxState = { ...checkBoxState }
        oldCheckBoxState[roleId] = isChecked
        setCheckboxState(oldCheckBoxState)

        const roleIds = Object.keys(oldCheckBoxState).filter(r => !props.reportRoleIds.includes(Number(r))&& oldCheckBoxState[r] === true)
            if (roleIds.length !== 0) {
                okButtonProps.disabled = false
                setOkButtonProps(okButtonProps)
            }
            else{
                okButtonProps.disabled = true
                setOkButtonProps(okButtonProps)
            }
            updateModelText()
    }

    useEffect(() => { updateModelText() }, [checkBoxState])

    const handleShareClick = () => {
        setShowModal(true);
        if (props.errors.length > 0) {
            setModalText(<><h1>Unable to fetch roles information. Please try again after some time</h1></>)
            return;
        }
    }

    const updateModelText = () => {
        setModalText(<>
            {
                props.roles.map(role =>
                    <tr key={role.id}>
                        <td>
                            <Checkbox style={{ marginTop: "10px" }}
                            id={`checkbox_${role.id}`}
                            key={role.id}
                            value={role.id.toString()}
                            checked={props.reportRoleIds.includes(role.id) || checkBoxState[role.id]}
                            onChange={onCheckBoxStateChange}
                            disabled={props.reportRoleIds.includes(role.id) ? true : false} enterKeyHint={undefined} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            />
                        </td>
                        <td>
                            <p>{role.name}</p>
                        </td>
                    </tr>)
            }
            <Button purpose='link' onClick={onClickInsightsRolesLink}>Manage GRC Insights Roles</Button>
        </>)
    }

    const share = () => {
        const roleIds = Object.keys(checkBoxState).filter(r => checkBoxState[r] === true
            && !props.reportRoleIds.includes(Number(r)))
        if (roleIds.length !== 0)
            upsertReportRoles({ customerKey: props.customerKey, reportId: props.reportId, roleIds })
        setShowModal(false)
    }

    return (
        <>
            <Button id={`share`} purpose='default' onClick={() => { handleShareClick(); }}>Share</Button>
            <Modal
                id="shareModal"
                show={showModal}
                okButtonText="Share"
                message={ModalText}
                headerText={"Share Report:"}
                onClickedOk={share}
                okButtonProps={okButtonProps}
                onClickedCancel={() => setShowModal(false)}
            />
        </>)
}


