import { number, string } from "yup";

enum ErrorMessage {
    STARTDATE = 'Select Start Date',
    ENDDATE = 'Select End Date',
    STARTMONTH = 'Select Fiscal Month',
    YEAR = 'Select Fiscal Year'
}

export const Retail = {
    startDate: string()
        .required(ErrorMessage.STARTDATE)
        .nullable(true),
    startMonthOfYear: string()
        .required(ErrorMessage.STARTMONTH)
        .nullable(true),
    endDate: string()
        .required(ErrorMessage.ENDDATE)
        .nullable(true),
    fiscalYear: number()
        .required(ErrorMessage.YEAR)
        // Adding this validation since default value 
        // for fiscal year is 0
        .min(4, ErrorMessage.YEAR)
        .nullable(true)
}

export const Custom = {
    startDate: string()
        .required(ErrorMessage.STARTDATE)
        .nullable(true),
    fiscalYear: number()
        .required(ErrorMessage.YEAR)
        // Adding this validation since default value 
        // for fiscal year is 0
        .min(4, ErrorMessage.YEAR)
        .nullable(true)
}