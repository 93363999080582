import React, { useState, FC } from 'react'
import { Button } from 'navex-react'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useFavoriteReport } from "./PowerBIEmbeddedDashboardAPI"
import { Toasts, ToastId } from 'navex-react'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
    toggles: string[],
    reportId: string,
    customerKey: string,
    favorite: boolean
}

export const FavoriteReport: FC<IProps> = (props) => {
    let toastId: ToastId = 0
    const [favorite, setFavorite] = useState(props.favorite)

    const handleFavoriteClick = (fav: boolean) => {
        favoriteReport({ customerKey: props.customerKey, id: props.reportId, favorite: fav })
        setFavorite(fav)
    }

    const [favoriteReport] = useFavoriteReport({
        onSuccess: (_, data) => {
            if (data?.favorite)
                toastId = Toasts.success("Report added to favorites successfully!")
            else
                toastId = Toasts.success("Report removed from favorites successfully!")
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("Something went wrong, Please try again later.")
            }
            setFavorite(!favorite)
        }
    })
    return (
        <Button style={{ borderWidth: 0, marginRight: 'unset', height: "35px" }}
            id={favorite ? `unfavorite` : `favorite`}
            onClick={() => {
                handleFavoriteClick(!favorite);
            }}
            children={<><FontAwesomeIcon icon={favorite ? faStar : faStarEmpty}></FontAwesomeIcon><span style={{ paddingLeft: '6px' }}>Favorite</span></>}
        />
    )
}
