import { IProduct, loadManageData } from "./ViewDashboardApi"
import React, { useState } from "react"
import { Tabs, Tab, ToolTip } from "navex-react"
import DashboardViewRow from "./DashboardViewRow"
import { insightAppHistory } from "../InsightsApp/InsightsApp"
import styles from "./ViewDashboard.module.scss"
import { getCustomerKey, pathPrefix } from "../../Api/urls"
import { BirstEmbeddedEditDashboard } from "./BirstEmbeddedEditDashboard"

interface IProps {
    dashboardData: IProduct[],
    toggles: string[],
}

const ManageDashboardView = (props: IProps) => {
    const [poll, setPoll] = useState(0)
    const [newDashboardData, setnewDashboardData] = useState<IProduct[] | string>(props.dashboardData)
    const [newlyCreatedDashboard, setNewlyCreatedDashboard] = useState("")
    const toggle = window.location.pathname.includes('DashboardManagement_EditDashboard') ? true : false

    let dashboardData: IProduct[] | string = []
    let newDashboardData1: IProduct[] | string = newDashboardData

    const checkForPolling = (): boolean => {
        let val: boolean = false
        if (Array.isArray(newDashboardData)) {
            newDashboardData.forEach(x => x.collections.forEach(y => y.dashboards.forEach(z => {
                if (z.dashboardStatus === 'Enabling' || z.dashboardStatus === 'Deleting') {
                    val = true
                }
            })))
        }
        return val
    }

    const startPoll = async () => {
        const data = await loadManageData(getCustomerKey())
        if (Array.isArray(data)) {
            setnewDashboardData(data)
            setPoll(poll + 1)
        }
    }

    const onClickTakeToRolesScreen = () => {

        insightAppHistory.push(`${pathPrefix}/Roles`, {})
    }

    if (checkForPolling()) {
        const t = setTimeout(async () => {
            newDashboardData1 = await loadManageData(getCustomerKey())
            if (JSON.stringify(newDashboardData1) !== JSON.stringify(newDashboardData) && newDashboardData1 !== 'There was a bad response from the server') {
                setnewDashboardData(newDashboardData1)
                if ((checkForPolling()) !== true) {
                    setPoll(poll + 1)
                    clearTimeout(t)
                }
            }           
            if ((checkForPolling()) === true) {
                clearTimeout(t)
                setPoll(poll + 1)
            }
        }, window.managePollTimeout)
    }

    if (Array.isArray(newDashboardData)) {
        dashboardData = newDashboardData
    }


    return (toggle?<BirstEmbeddedEditDashboard data={dashboardData} toggles={props.toggles} />:
        <>
            <Tabs id={`viewDashboardTabBar`} withPanelBorder={false} activeTabIndex={0}>
                {dashboardData.map(val =>
                    <Tab id={val.productName}
                        key={val.productName}
                        title={val.productName}
                        children={
                            <DashboardViewRow
                                key={val.productName}
                                productName={val.productName}
                                collections={val.collections}
                                spaceId={val.spaceId}
                                startPoll={startPoll}
                                toggles={props.toggles}
                                newlyCreatedDashboard={newlyCreatedDashboard}
                                setNewlyCreatedDashboard={setNewlyCreatedDashboard}
                            />
                        } />)}
            </Tabs>
            <span style={{ padding: "10px" }}>You can now assign enabled dashboards to roles. <ToolTip id="takesToRolesScreen" onClick={() => onClickTakeToRolesScreen()} text="Takes you to roles" className={styles["custom-tool-tip"]}>
                <div style={{color:"#3265d7"}}>Click here</div>
            </ToolTip> to assign.</span>
        </>        
    )
}

export default ManageDashboardView