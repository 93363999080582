import React, { FC } from 'react'
import { IMapping } from './OutcomesApi'
import { OutcomeRow } from './OutcomeRow'
import { NoDataError } from '../Shared/Error'

interface IProps {
  options: string[],
  mappings: IMapping[],
}

export const OutcomesView: FC<IProps> = (props) => {

  const rows = props.mappings.map((mapping, index) => {
    return (<OutcomeRow key={index} mapping={mapping} options={props.options} />)
  })

  return (<div className="custom-epim-outcomes-table" style={{ maxWidth: 500 }}>
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col">
            Outcome Name
            </th>
          <th scope="col">
            Tier
            </th>
          <th scope="col">
            Substantiated Type
            </th>
        </tr>
      </thead>
      <tbody>
        {rows.length > 0
          ? rows
          : <tr>
            <td colSpan={3}><NoDataError /></td><td />
          </tr>
        }
      </tbody>
    </table>
  </div>
  )
}
