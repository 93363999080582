import { useAxiosGet, useAxiosPost, IUseAxiosOptions } from '../../Api/useAxios'
import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { Omit } from '../../Utils/typeHelpers'

export interface IMapping {
  mapping?: string,
  outcomeId: number,
  outcomeName: string,
  tierId: number,
  tierName: string,
}

type TUseGetSubstantiatedTypeMapping = [
  {
    mappings: IMapping[],
    options: string[],
    loading: boolean,
    status?: string | number,
    errors: any[],
  },
  () => Promise<AxiosResponse<any> | undefined>
]


export const useGetSubstantiatedTypeMapping = (): TUseGetSubstantiatedTypeMapping => {
  
  const getSubstantiatedTypeMappingUrl = `${buildServerApiUrl("SubstantiatedTypeMapping.Get").toString()}/${getCustomerKey()}`

  const [{ data, loading, errors, status }, reFetch] = useAxiosGet(
    getSubstantiatedTypeMappingUrl,
  )

  let mappings = [], options = []
  if (data) {
    mappings = data.mappings
    options = data.options
  }
  return [{ mappings, options, loading, status, errors }, reFetch]
}

interface IUpsertSubstantiatedTypeMappingPayload {
  outcomeId: number,
  substantiatedType: string,
  customerKey: string,
}

export type IUpsertSubstantiatedTypeMapping = Omit<IUpsertSubstantiatedTypeMappingPayload, "customerKey">

type TUseUpsertSubstantiatedTypeMapping = [
  (payload: IUpsertSubstantiatedTypeMapping) => Promise<AxiosResponse<any> | undefined>,
  {
    posting: boolean,
    status?: string | number,
    errors: any[],
  }
]

const upsertSubstantiatedTypeMappingUrl = `${buildServerApiUrl("SubstantiatedTypeMapping.Upsert").toString()}`
export const useUpsertSubstantiatedTypeMapping = (
  options?: IUseAxiosOptions<IUpsertSubstantiatedTypeMappingPayload>
): TUseUpsertSubstantiatedTypeMapping => {
  
  const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertSubstantiatedTypeMappingPayload>(
    upsertSubstantiatedTypeMappingUrl, {}, options)

  const update = async (payload: IUpsertSubstantiatedTypeMapping) => {
    const res = await upsert({ ...payload, customerKey:getCustomerKey() })
    return res
  }

  return [update, { posting, status, errors }]
}
