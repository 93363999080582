import { useState, useEffect, useRef } from 'react'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { axiosInstance } from './api'
import { buildUrlForApiAuthRedirectToKeyCloakV3 } from './urls'

export interface IUseAxiosOptions<T> {
  before?: () => void,
  after?: () => void,
  onSuccess?: (data?: any, context?: T) => void
  onError?: (errors?: any, context?: T) => void
}

type TUseAxios<T> = [
  (payload?: T) => Promise<AxiosResponse<any> | undefined>,
  {
    pending: boolean
    status?: number | string
    data?: any
    errors: any[]
    response?: any
  }
]
const noop = () => { }
export const useAxios = <T>(
  url: string, config?: AxiosRequestConfig | undefined, options?: IUseAxiosOptions<T>
): TUseAxios<T> => {
  const isMounted = useRef(true)
  const [pending, setPending] = useState(false)
  const [status, setStatus] = useState(undefined as number | string | undefined)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState([] as any[])
  const [response, setResponse] = useState({})

  useEffect(() => {
    return function cleanUp() {
      isMounted.current = false
    }
  }, [])

  const opts = { before: noop, after: noop, onSuccess: noop, onError: noop, ...options }

  const execute = async (payload?: T) => {
    const safeUpdate = (cb: (...args: any[]) => void, ...args: any[]) => {

      if (isMounted.current === false) return
      cb(...args)
    }

    safeUpdate(opts.before)
    let res = undefined
    safeUpdate(setPending, true)
    safeUpdate(setErrors, [])

    try {
      res = await axiosInstance(url, {
        ...config,
        data: payload
      })
      if (res.data.status) {// is navex standard response
        if (String(res.data.status).toLowerCase() === "success") {
          safeUpdate(setData, res.data.data)
          safeUpdate(opts.onSuccess, res.data.data, payload)
        } else {
          safeUpdate(setErrors, res.data.errors)
          safeUpdate(opts.onError, res.data.errors, payload)
        }
        safeUpdate(setStatus, res.data.status)
      } else { // is unknown response type
        safeUpdate(setData, res.data)
        safeUpdate(setStatus, res.status)
        safeUpdate(opts.onSuccess, res.data, payload)
      }
      safeUpdate(setResponse, res)
    } catch (error) {
      if ((error as any).response) {
        if ((error as any).response.status === 401) {
          window.location.href = buildUrlForApiAuthRedirectToKeyCloakV3().toString()
          return
        }
      }
      safeUpdate(setErrors, [error])
      safeUpdate(opts.onError, [error], payload)
    }
    safeUpdate(setPending, false)
    safeUpdate(opts.after)
    return res
  }

  return [execute, { pending, status, data, errors, response }]
}

type TUseAxiosGet = [
  {
    loading: boolean
    status?: number | string
    data?: any
    errors: any[]
    response?: any
  },
  () => Promise<AxiosResponse<any> | undefined>
]
export const useAxiosGet = <T>(
  url: string, config?: AxiosRequestConfig | undefined, options?: IUseAxiosOptions<T>
): TUseAxiosGet => {
  const [execute, { pending, status, data, errors, response }] = useAxios(url, {
    ...config,
    method: 'get'
  }, options)

  const configString = JSON.stringify(config)
  // Very important that async functions are not called directly inside useEffect, must be wrapped in a regular function

  // DO NOT add excute to the list of dependencies in the useEffect. Trust Me!!!
  useEffect(() => {

    execute(undefined)

  }, [url, configString]) // eslint-disable-line

  return [{ loading: pending, status, data, errors, response }, execute]
}

type TUseAxiosPost<T> = [
  (payload?: T) => Promise<AxiosResponse<any> | undefined>,
  {
    posting: boolean
    status?: number | string
    data?: any
    errors: any[]
    response?: any
  }
]
export const useAxiosPost = <T>(
  url: string, config?: AxiosRequestConfig | undefined, options?: IUseAxiosOptions<T>
): TUseAxiosPost<T> => {
  const [execute, { pending, status, data, errors, response }] = useAxios(url, {
    ...config,
    method: 'post'
  }, options)

  return [execute, { posting: pending, status, data, errors, response }]
}