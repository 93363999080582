import React, { FC, useState, useEffect, useRef } from 'react'
import { Field, Form, Formik } from 'formik'
import { Button } from 'navex-react'
import { FormikTextArea } from 'navex-react'
import { Tab } from 'navex-react'
import { Tabs } from 'navex-react'
import { Toasts, ToastId } from 'navex-react'
import { object, string } from 'yup'
import { IProduct, useResetDashboardsV2, getTemplateDashboards } from './ResetDashboardV2Api'
import { ResetDashboardTableView } from './ResetDashboardTableView'
import { DASHBOARDRESETSTATUS } from '../../ts/enums/dashboardResetStatus'

interface IProps {
    templateData: IProduct[]
}

export interface ICheckBoxState<T> {
    [key: string]: T
}

export const ResetDashboardTabView: FC<IProps> = (props) => {

    const initialCheckBoxDashState: ICheckBoxState<Array<number>> = {}

    const [templateData, setTemplateData] = useState<IProduct[]>(props.templateData)

    const [checkBoxDashState, setCheckBoxDashState] = useState(initialCheckBoxDashState)

    const [dashboardsPolling, setDashboardsPolling] = useState<ICheckBoxState<boolean>>({})

    const [startPoll, setStartPoll] = useState(true)

    let toastId: ToastId = 0

    const isEmpty = (obj: ICheckBoxState<Array<number>>): boolean => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const useInterval = (callback: () => void, isPolling: boolean) => {
        const savedCallback = useRef(() => { })
        useEffect(() => {
            savedCallback.current = callback
        }, [callback])

        useEffect(() => {
            function poll() {
                savedCallback.current()
            }
            if (isPolling) {
                const id = setInterval(poll, window.resetPollTimeout)
                return () => { clearInterval(id) }
            }
        }, [isPolling])
    }

    useInterval(async () => {
        if (startPoll) {
            const templateData = await getTemplateDashboards()
            if (typeof templateData === 'string') {
                setStartPoll(false)
            }
            if (Array.isArray(templateData)) {
                const checkEligibleForPoll = () => {
                    let isEligibleForPoll = false
                    templateData.forEach(td => td.collections.forEach(col => col.dashboards.forEach(d => {
                        if (d.status === DASHBOARDRESETSTATUS.INPROCESS) {
                            isEligibleForPoll = true
                        }
                    })))
                    return isEligibleForPoll
                }
                if (checkEligibleForPoll()) {
                    setTemplateData(templateData)
                }
                else {
                    setStartPoll(false)
                    setTemplateData(templateData)
                }
            }
        }
    }, startPoll)

    const [reset] = useResetDashboardsV2({
        onSuccess: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.success("Reset Dashboard(s) requested. Check Reset Status field.")
            }
            setStartPoll(true)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
            setDashboardsPolling({})
        }
    })

    return (
        <>
            <Formik
                initialValues={
                    {
                        reason: ""
                    }
                }
                onSubmit={(values, actions) => {

                    reset({
                        dashboardIdsByProducts: checkBoxDashState,
                        reason: values.reason
                    })
                    actions.setSubmitting(false)
                    actions.resetForm()
                }}

                validationSchema={object().shape({
                    reason: string()
                        .required()
                        .min(10, "Must be at least 10 characters")
                        .max(250, "Must be 250 characters or less")
                })}
            >
                {({ values,
                    errors,
                    isSubmitting
                }) => (
                    <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <div className="form-group">
                            <Tabs>
                                {templateData.map(td =>
                                    <Tab
                                        id={td.productName}
                                        key={td.productName}
                                        title={td.productName}
                                        children={<ResetDashboardTableView collections={td.collections} productName={td.productName}
                                            checkBoxDashState={checkBoxDashState} setCheckBoxDashState={setCheckBoxDashState}
                                            {...{ dashboardsPolling, setDashboardsPolling }} />}
                                    />
                                )}
                            </Tabs>
                        </div>
                        <div className="form-control-feedback-group" style={{ marginTop: "-25px" }}>
                            <div className="col-12 col-sm-6 mx-auto">
                                <Field
                                    id="reason-value"
                                    name="reason"
                                    placeholder="Enter reset reason here..."
                                    required
                                    label="Reason"
                                    component={FormikTextArea}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div>
                                <Button style={{ float: "left" }}
                                    id="btnReset"
                                    purpose="primary"
                                    type="submit"
                                    disabled={
                                        values.reason.length === 0 || !!errors.reason || isSubmitting
                                        || isEmpty(checkBoxDashState)
                                    }
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}