import React, { FC, useState } from 'react'
import { IRole } from './RolesApi'
import { ThrottledSearchBox } from '../SearchBox/ThrottledSearchBox'
import { ActionIcon } from 'navex-react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { NoDataError } from '../Shared/Error'
import { RolesListItem } from './RolesListItem'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
  roles: IRole[],
  setIsEdit: (isEdit: boolean) => void,
  setRoleId: (roleId: number | undefined) => void,
  reFetch: () => void,
  toggles: string[],
}

const computeFilteredRoleIds = (filterText: string, roles: IRole[]) => {
  const searchText = filterText.toLocaleUpperCase()

  const filteredRoleIds = roles.filter(role => role.name.toLocaleUpperCase().includes(searchText)).map(role => role.id)
  return filteredRoleIds
}

export const RolesListView: FC<IProps> = (props) => {
  const [filterText, setFilterText] = useState('')

  const filteredRoleIds = computeFilteredRoleIds(filterText, props.roles)


  const handleAddRoleBtnLinkClick = () => {
    props.setIsEdit(true)
    props.setRoleId(undefined)
  }


  return (<>
    <ThrottledSearchBox placeholder="Search roles" changeFilterText={setFilterText} />
    <br />
    <table className="table table-striped table-bordered">
      <thead>
        <tr style={{
          backgroundColor: "#edecec"
        }}>
          <th scope="col">
            Roles
          </th>
          <th scope="col">Users in role</th>
          <th scope="col">{props.toggles.includes(TOGGLENAME.POWERBI_V2)? "Number of reports in role" : "Number of dashboards in role"}</th>
          <th scope="col">Actions</th>
          <th scope="col">
            <ActionIcon id="AddRole" icon={faPlus} toolTip="Add Role"
              onClick={handleAddRoleBtnLinkClick}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {
          filteredRoleIds.length > 0
            ?
            (filteredRoleIds.map(roleId => {

              const role = props.roles.filter(r => r.id === roleId)[0]

              let defaultRoleId: number | undefined
              const defaultRoles = props.roles.filter(rd => rd.isDefault === true)

              if (defaultRoles.length >= 1) {
                defaultRoleId = defaultRoles[0].id
              }

              return <RolesListItem key={roleId} roleId={roleId} role={role} defaultRoleId={defaultRoleId}
                setIsEdit={props.setIsEdit} setRoleId={props.setRoleId}
                reFetch={props.reFetch} toggles={props.toggles}
              />
            }))
            : (<tr>
              <td colSpan={4}><NoDataError /></td>
            </tr>)
        }
      </tbody>
    </table>
  </>
  )
}