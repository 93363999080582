import React from 'react'
import { OutcomesRoute } from '../Outcomes'
import { IAdminScreenDefinitions } from "./adminScreenTypes"
import { EpimCustomFieldsRoute } from '../EpimCustomFieldsV2/EpimCustomFieldsRoute'
import { FiscalCalendarRoute } from '../FiscalCalendar/FiscalCalendarRoute'

export const AdminScreenDefinitions = (toggles: string[], features: string[]): IAdminScreenDefinitions => ({
    CustomFieldMappingsV2: {
        title: "Custom Fields",
        gatingPermission: "CustomFieldMappingsEdit",
        component: EpimCustomFieldsRoute
    },
    SubstantiatedTypeMapping: {
        title: "Outcomes",
        gatingPermission: "OutcomeMappingsEdit",
        component: OutcomesRoute
    },
    FiscalCalendar: {
        title: "Fiscal Calendar",
        gatingPermission: features.filter(item => item === "FiscalCalendar").length > 0 ? "FiscalCalendar" : "",
        component: (props: any) => <FiscalCalendarRoute {...props} toggles={[]} />,
        featureName: features.filter(item => item === "FiscalCalendar").length > 0 ? "FiscalCalendar" : "",
    }
})
