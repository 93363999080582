import React, { FC, useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Toasts, ToastId } from "navex-react"
import { Button } from "navex-react"
import { SmallSpinner } from "../Shared/Spinner"
import { pascalize } from "../../Utils/utils"
import { Checkbox } from "navex-react"
import styles from "./ViewDashboard.module.scss"
import { useEnableDashboard } from "./ViewDashboardApi"

interface IProps {
  customerKey: string,
  spaceId: string,
  dashboardName: string,
  collectionName: string,
  setActiveCardIndex: (arg: number) => void,
  startPoll: () => void,
  newlyCreatedDashboard: string,
}

type props = IProps

const EnableDashboard: FC<props> = (props) => {
  let toastId: ToastId = 0

  const [showEnableModal, setShowEnableModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkboxState, setCheckboxState] = useState(false)

  const handleEnableDashbordModalXClose = () => {
    setShowEnableModal(false)
  }

  const [enable] = useEnableDashboard({
    onSuccess: (_correlationId) => {
      if (!Toasts.isActive(toastId)) {
        if (props.dashboardName === props.newlyCreatedDashboard) {
          toastId = Toasts.success("Enabling the dashboard, Please wait for status to update...")
        }
        else {
          toastId = Toasts.success("Enabling the dashboard")
        }
      }
      setIsSubmitting(false)
      setCheckboxState(false)
      setShowEnableModal(false)
      props.setActiveCardIndex(1)
      if (props.dashboardName === props.newlyCreatedDashboard) {
        setTimeout(() => {
          props.startPoll()
        }, window.managePollTimeout);
      }
      else {
        props.startPoll()
      }

    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Enable dashboard failed, please try again later.")
      }
      setIsSubmitting(false)
      setCheckboxState(false)
      setShowEnableModal(false)
    }
  })


  const enableDashboard = () => {
    enable({
      customerKey: props.customerKey,
      spaceId: props.spaceId,
      collectionName: props.collectionName,
      dashboardName: props.dashboardName,
      isVisible: checkboxState
    })
    setIsSubmitting(true)
  }

  const closeModalXButton = (
    <button
      id="closeXButton"
      tabIndex={0}
      className="close modal-header-close"
      onClick={handleEnableDashbordModalXClose}
      aria-label={"close"}
      type="button"
      disabled={isSubmitting}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  )

  return (
    <>
      <Modal isOpen={showEnableModal}>
        <ModalHeader close={closeModalXButton}>
          Enable Dashboard
            </ModalHeader>
        <ModalBody>
          <>
            Do you want to assign this dashboard to Insights Roles?
          <Checkbox id="checkboxForEnable"
            label="Assignable to roles"
            onChange={() => { setCheckboxState(!checkboxState) } }
            checked={checkboxState}
            disabled={isSubmitting}
            className={styles.enablecheckbox} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}            />
          </>
        </ModalBody>
        <ModalFooter>
          {
            isSubmitting ?
              <SmallSpinner />
              : <Button purpose="primary" id="btnEnable"
                style={{ float: "right" }}
                onClick={enableDashboard}>Enable</Button>
          }
        </ModalFooter>
      </Modal>
      <Button
        id={`${pascalize(props.dashboardName)}_${pascalize(props.collectionName)}_enable`}
        purpose="default"
        onClick={() => setShowEnableModal(true)}>Enable</Button>
    </>
  )
}

export default EnableDashboard