import React, { FC, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'navex-react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { object, ref, string } from 'yup'
import { Toasts, ToastId } from 'navex-react'
import { ButtonRow, FormikOption, FormikSelect } from 'navex-react'
import useButtonLoader from './CustomFormulaLoader'
import { IGetTables } from './CustomColumnsApi'
import { IGetDateDiff, useGetTablesForDateDiff, useUpsertDateDiff } from './DateDiffApi'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'

interface IProps {
    setAddDateDiff: (value: boolean) => void,
    reFetchData: () => void,
    dateDiff?: IGetDateDiff,
    dateDiffInfo: IGetDateDiff[],
    tableData: IGetTables[]
}

export const DateDiffDetailView: FC<IProps> = (props) => {
    const [{ tablesInfo, errors: tableErrors, loading: tableDataLoading, status: tableDataStatus }] = useGetTablesForDateDiff()
    const [isAdding, setIsAdding] = useButtonLoader("Add", "Adding...")
    const [isUpdating, setIsUpdating] = useButtonLoader("Update", "Updating...")
    let toastId: ToastId = 0
    const [upsert] = useUpsertDateDiff({
        onSuccess: () => {
            toastId = Toasts.success(props.dateDiff === undefined ? "DateDiff Added sucessfully" : "DateDiff Updated sucessfully")
            props.reFetchData()
            handleDateDiffClose()
        },
        onError: (errors: any) => {
            if (errors && errors[0].response && errors[0].response.data && errors[0].response.data.errors.length > 0) {
                if (!Toasts.isActive(toastId)) {
                    toastId = Toasts.alert(errors[0].response.data.errors[0].message)
                }
            }
            else {
                if (!Toasts.isActive(toastId)) {
                    toastId = Toasts.alert("An error occurred processing your request. Please try again.")
                }
            }
        }
    })

    const handleDateDiffClose = () => {
        props.setAddDateDiff(false)
    }

    if (tableErrors.length) return <UnexpectedError />
    if (tableDataLoading || !tableDataStatus) return <Spinner />

    const tableNames = tablesInfo && tablesInfo.map(t => t.tableName)
    const columnDisplayNames: string[] = props.dateDiffInfo.map(d => d.columnDisplayName)
    const columnDisplayNamesWithoutCurrent = columnDisplayNames.filter(d => d !== props.dateDiff?.columnDisplayName)

    return (
        <>
            <Formik
                initialValues={props.dateDiff === undefined ? {
                    FromDateTable: "",
                    FromDateColumn: "",
                    ToDateTable: "",
                    ToDateColumn: "",
                    ColumnDisplayName: "",
                    Duration: ""
                } : {
                    FromDateTable: props.dateDiff.fromDateTable,
                    FromDateColumn: props.dateDiff.fromDateColumn,
                    ToDateTable: props.dateDiff.toDateTable,
                    ToDateColumn: props.dateDiff.toDateColumn,
                    ColumnDisplayName: props.dateDiff.columnDisplayName,
                    Duration: props.dateDiff.duration
                }}

                onSubmit={async (values, actions) => {
                    setIsAdding(true)
                    setIsUpdating(true)
                    await upsert({
                        Id: props.dateDiff === undefined ? undefined : props.dateDiff.id,
                        FromDateTable: values.FromDateTable,
                        FromDateColumn: values.FromDateColumn,
                        ToDateTable: values.ToDateTable,
                        ToDateColumn: values.ToDateColumn,
                        ColumnDisplayName: values.ColumnDisplayName,
                        Duration: values.Duration,
                        FromDateTableName: tablesInfo?.find(t => t.tableName === values.FromDateTable)?.tableAlias,
                        FromDateColumnName: tablesInfo.find(t => t.tableName === values.FromDateTable)?.columns?.find(c => c.columnName === values.FromDateColumn)?.columnSource,
                        ToDateTableName: tablesInfo?.find(t => t.tableName === values.ToDateTable)?.tableAlias,
                        ToDateColumnName: tablesInfo.find(t => t.tableName === values.ToDateTable)?.columns?.find(c => c.columnName === values.ToDateColumn)?.columnSource
                    })

                    actions.setSubmitting(false)
                    setIsAdding(false)
                    setIsUpdating(false)
                }}

                validationSchema={object().shape({
                    FromDateTable: string()
                        .required("From Date Table is required"),
                    FromDateColumn: string()
                        .required("From Date Column is required"),
                    ToDateTable: string()
                        .required("To Date Table is required"),
                    ToDateColumn: string()
                        .notOneOf([ref('FromDateColumn')], "'From Date Column' should not be equal to 'To Date Column'")
                        .required("To Date Column is required"),
                    ColumnDisplayName: props.dateDiff === undefined ? string()
                        .required("Column Display Name is required").notOneOf(columnDisplayNames, "Name already exists") : string()
                            .required("Column Display Name is required").notOneOf(columnDisplayNamesWithoutCurrent, "Name already exists"),
                    Duration: string()
                        .required("Duration is required"),
                })}
                validateOnBlur
            >
                {({ values,
                    errors,
                    touched,
                    setFieldValue,
                    isSubmitting, }) => (
                    <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label className="control-label" htmlFor="FromDateTable">From Date Table
                                        <span className="validation-required">{" * "}</span>
                                        {errors.FromDateTable && touched.FromDateTable ? (<span className="validation-label" aria-hidden="false">{errors.FromDateTable}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="FromDateTable" component={FormikSelect} name="FromDateTable"
                                            aria-invalid={errors.FromDateTable && touched.FromDateTable} aria-required="true" validationErrorMessage=""
                                            required maxMenuHeight="200px" title={values.FromDateTable ? values.FromDateTable : "Select From Date Table"}
                                            onChange={(value: any) => { setFieldValue('FromDateTable', value); setFieldValue("FromDateColumn", "") }} >
                                            {
                                                tableNames.map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })
                                            }</Field>
                                    </div>
                                </div>

                                {values.FromDateTable ?
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="FromDateColumn">From Date Column
                                            <span className="validation-required">{" * "}</span>
                                            {errors.FromDateColumn && touched.FromDateColumn ? (<span className="validation-label" aria-hidden="false">{errors.FromDateColumn}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="FromDateColumn" component={FormikSelect} name="FromDateColumn"
                                                aria-invalid={errors.FromDateColumn && touched.FromDateColumn} aria-required="true" validationErrorMessage=""
                                                required maxMenuHeight="200px" title={values.FromDateColumn ? values.FromDateColumn : "Select From Date Column"}
                                                onChange={(value: any) => { setFieldValue('FromDateColumn', value) }} >
                                                {
                                                    values.FromDateTable && tablesInfo.length > 0 && tablesInfo.find(t => t.tableName === values.FromDateTable)?.columns?.map(d => {
                                                        return <FormikOption value={d.columnName} key={d.columnName}>{d.columnName}</FormikOption>
                                                    })
                                                }</Field>
                                        </div> </div> : ""}


                                <div className="form-group">
                                    <label className="control-label" htmlFor="ToDateTable">To Date Table
                                        <span className="validation-required">{" * "}</span>
                                        {errors.ToDateTable && touched.ToDateTable ? (<span className="validation-label" aria-hidden="false">{errors.ToDateTable}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="ToDateTable" component={FormikSelect} name="ToDateTable"
                                            aria-invalid={errors.ToDateTable && touched.ToDateTable} aria-required="true" validationErrorMessage=""
                                            required maxMenuHeight="200px" title={values.ToDateTable ? values.ToDateTable : "Select To Date Table"}
                                            onChange={(value: any) => { setFieldValue('ToDateTable', value); setFieldValue("ToDateColumn", "") }} >
                                            {
                                                tableNames.map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })
                                            }</Field>
                                    </div>
                                </div>

                                {values.ToDateTable ?
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="ToDateColumn">To Date Column
                                            <span className="validation-required">{" * "}</span>
                                            {errors.ToDateColumn && touched.ToDateColumn ? (<span className="validation-label" aria-hidden="false">{errors.ToDateColumn}</span>) : null}
                                        </label>
                                        <div className="form-control-feedback-group">
                                            <Field id="ToDateColumn" component={FormikSelect} name="ToDateColumn"
                                                aria-invalid={errors.ToDateColumn && touched.ToDateColumn} aria-required="true" validationErrorMessage=""
                                                required maxMenuHeight="200px" title={values.ToDateColumn ? values.ToDateColumn : "Select To Date Column"}
                                                onChange={(value: any) => { setFieldValue('ToDateColumn', value) }} >
                                                {
                                                    values.ToDateTable && tablesInfo.length > 0 && tablesInfo.find(t => t.tableName === values.ToDateTable)?.columns.map(d => {
                                                        return <FormikOption value={d.columnName} key={d.columnName}>{d.columnName}</FormikOption>
                                                    })
                                                }</Field>
                                        </div></div> : ""}

                                <div className="form-group">
                                    <label className="control-label" htmlFor="ColumnDisplayName">Column Display Name
                                        <span className="validation-required">{" * "}</span>
                                        {errors.ColumnDisplayName && touched.ColumnDisplayName ? (<span className="validation-label" aria-hidden="false">{errors.ColumnDisplayName}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="ColumnDisplayName" name="ColumnDisplayName" className="form-control" aria-describedby="name-help-block"
                                            aria-invalid={errors.ColumnDisplayName && touched.ColumnDisplayName} aria-required="true" placeholder="Enter name here"
                                            required />
                                        <span className="add-clear-x form-control-feedback" style={{
                                            color: "rgb(51, 51, 51)",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            overflow: "hidden",
                                            position: "absolute",
                                            pointerEvents: "auto",
                                            right: 0,
                                            top: 0,
                                            zIndex: "auto",
                                            display: values.ColumnDisplayName.length > 0 ? undefined : "none",
                                        }}
                                            onClick={() => setFieldValue("ColumnDisplayName", "")}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label" htmlFor="Duration">Duration
                                        <span className="validation-required">{" * "}</span>
                                        {errors.Duration && touched.Duration ? (<span className="validation-label" aria-hidden="false">{errors.Duration}</span>) : null}
                                    </label>
                                    <div className="form-control-feedback-group">
                                        <Field id="Duration" component={FormikSelect} name="Duration"
                                            aria-invalid={errors.Duration && touched.Duration} aria-required="true" validationErrorMessage=""
                                            required maxMenuHeight="200px" title={values.Duration ? values.Duration : "Select Duration"}
                                            onChange={(value: any) => { setFieldValue('Duration', value) }} >
                                            {
                                                ["Minutes", "Hours", "Days", "Week", "Month", "Year"].map(yo => {
                                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                                })
                                            }</Field>
                                    </div>
                                </div>

                                <div className="form-group d-flex justify-content-end">
                                    <ButtonRow>
                                        <Button id="btnCancelColumn" disabled={isSubmitting} onClick={handleDateDiffClose}>Cancel</Button>
                                        {props.dateDiff === undefined
                                            ? <Button id="btnAddColumn" purpose="primary" type="submit" disabled={isSubmitting} innerRef={isAdding}>Add</Button>
                                            : <Button id="btnUpdateColumn" purpose="primary" type="submit" disabled={isSubmitting} innerRef={isUpdating}>Update</Button>
                                        }
                                    </ButtonRow>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
                }
            </Formik >
        </>
    )
}

