import { AxiosResponse } from "axios"
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { useAxiosGet, IUseAxiosOptions, useAxiosPost } from '../../Api/useAxios'
import { axiosInstance } from '../../Api/api'
import { ERRORS } from '../Shared/Error'

export interface IDashboard {
    id: number,
    name: string,
    status: string,
    isVisible: boolean | null,
    dashboardStatus: string | null,
    fromCollectionName: string
    failedCorrelationId: string | null,
}

export interface ICollection {
    collectionName: string,
    dashboards: IDashboard[],
}

export interface IProduct {
    productName: string,
    spaceId: string,
    collections: ICollection[],
}

type TUseDashboardData = [
    {
        dashboards: IProduct[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]

export const useGetDashboards = (): TUseDashboardData => {
    const getDashboardUrlPath = `${buildServerApiUrl("Dashboard.Get").toString()}/${getCustomerKey()}`

    const [{ data, loading, errors, status }, reFetchDashboardData] = useAxiosGet(
        getDashboardUrlPath
    )

    let dashboards = []

    if (data) {
        dashboards = data
    }
    return [{ dashboards, loading, status, errors }, reFetchDashboardData]
}

 type IAssignDashboardPayload = {
    dashboardId: number, 
    isAssignable:boolean, 
    customerKey: string
}

type TUseAssignDashboardPayload = [
    (payload: IAssignDashboardPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

 export const AssignDashboardUrl = `${buildServerApiUrl("Dashboard.AssignDashboard").toString()}`
 export const useAssignDashboard = (
     options?: IUseAxiosOptions<IAssignDashboardPayload>
 ): TUseAssignDashboardPayload => {
     const [execute, { posting, status, data, errors }] = useAxiosPost<IAssignDashboardPayload>(
        AssignDashboardUrl, {}, options
    )

    const assignDash = async (payload: IAssignDashboardPayload) => {
        const res = await execute(payload)
        return res
    }

    return [assignDash, { posting, status, data, errors }]
}
type IEnableDashboardPayload = {
    customerKey: string,
    spaceId: string,
    collectionName: string,
    dashboardName: string,
    isVisible: boolean,
}

type TUseEnableDashboardPayload = [
    (payload: IEnableDashboardPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const enableDashboardUrl = `${buildServerApiUrl("Dashboard.EnableDashboard").toString()}`
export const useEnableDashboard = (
    options?: IUseAxiosOptions<IEnableDashboardPayload>
): TUseEnableDashboardPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEnableDashboardPayload>(
        enableDashboardUrl, {}, options
    )

    const enable = async (payload: IEnableDashboardPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [enable, { posting, status, data, errors }]
}


type IDisableDashboardPayload = {
    customerKey: string,
    spaceId: string,
    dashboardId: number,
}

type TUseDisableDashboardPayload = [
    (payload: IDisableDashboardPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const disableDashboardUrl = `${buildServerApiUrl("Dashboard.DisableDashboard").toString()}`
export const useDisableDashboard = (
    options?: IUseAxiosOptions<IDisableDashboardPayload>
): TUseDisableDashboardPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IDisableDashboardPayload>(
        disableDashboardUrl, {}, options
    )

    const disable = async (payload: IDisableDashboardPayload) => {
        const res = await execute(payload)
        return res
    }

    return [disable, { posting, status, data, errors }]
}



type IDeleteDashboardPayload = {
    customerKey: string,
    spaceId: string,
    collectionName: string,
    name: string,
    dashboardId: number | null,
}

type TUseDeleteDashboardPayload = [
    (payload: IDeleteDashboardPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const deleteDashboardUrl = `${buildServerApiUrl("Dashboard.DeleteDashboard").toString()}`
export const useDeleteDashboard = (
    options?: IUseAxiosOptions<IDeleteDashboardPayload>
): TUseDeleteDashboardPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IDeleteDashboardPayload>(
        deleteDashboardUrl, {}, options
    )

    const deleteDash = async (payload: IDeleteDashboardPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [deleteDash, { posting, status, data, errors }]
}

export const loadManageData = async (customerKey: string):Promise<IProduct[]|string> => {
    const url = `${buildServerApiUrl("Dashboard.Get").toString()}/${customerKey}`
    let errorKey: number | undefined = undefined
        let response = await axiosInstance.get(url)
        .catch(error => {
            if(error) {
                errorKey = ERRORS.DEFAULT_ERROR
                return undefined
            }
        })
        let res:IProduct[]=[]
        if(response === undefined || errorKey) { return 'There was a bad response from the server' }
        if (!!response) {
            const products: IProduct[] = response.data.data
            res = products
          }
          return res
}