import { pascalize } from "../../Utils/utils"
import { IAppNavigationUIRoute } from "navex-react"
import { IDashboardCollection } from "../../Models/GlobalState"
import { faChartBar } from "@fortawesome/free-solid-svg-icons"
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

export const computeDashboardNavTree = (toggles: TOGGLENAME[], dashboardCollections: IDashboardCollection[]): IAppNavigationUIRoute[] | null => {

    if (dashboardCollections.length === 0) {
        return null
    }

    return computeNavCollectionEntries(toggles, dashboardCollections)
}

export const computeNavCollectionEntries = (toggles: TOGGLENAME[], dashboardCollections: IDashboardCollection[]): IAppNavigationUIRoute[] => {
    if (dashboardCollections.length === 0) {
        return []
    }

    const collectionSubroutes = dashboardCollections.map<IAppNavigationUIRoute>(collection => {
        const mapResult: IAppNavigationUIRoute = {
            id: `route_${pascalize(collection.displayName)}`,
            title: collection.displayName,
            icon: faChartBar,
            subRoutes: computeNavDashboardEntries(toggles, collection.name, collection.spaceId, collection.dashboards)
        }
        return mapResult
    })

    return collectionSubroutes
}

export const computeNavDashboardEntries = (toggles: TOGGLENAME[], collectionName: string, collectionSpaceId: string, dashSpecs: { name: string }[]): IAppNavigationUIRoute[] => {

    const result = dashSpecs.map<IAppNavigationUIRoute>((obj): IAppNavigationUIRoute => {
        const { name } = obj
        const path = computeBirstDashboardRoutePath(collectionName, name)
        const route = `route_${collectionName}_${name.replace(/ /g, "")}`

        const result: IAppNavigationUIRoute = {
            id: route,
            title: name,
            path,
        }

        result.data = {
            collectionName: collectionName,
            spaceId: collectionSpaceId,
            page: name,
            active: false
        }

        return result
    })

    return result
}

export const computeBirstDashboardRoutePath = (collectionName: string, page: string, filters?: string) => {
    const query = !filters ? "" : `?filters=${filters}`
    return `/Dashboards/${encodeURIComponent(collectionName)}_${encodeURIComponent(page)}${query}`
}