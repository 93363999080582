import { faChartPie, faPencilAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { ActionIcon } from 'navex-react'
import React, { FC, useState } from 'react'
import { Toasts, ToastId, Button } from 'navex-react'
import { useDisableReport, useEditReportDescription } from './ManagePowerBIApi'
import { getCustomerKey } from '../../Api/urls'
import { pascalize } from '../../Utils/utils'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { useFavoriteReport } from '../PowerBIEmbeddedDashboard/PowerBIEmbeddedDashboardAPI'
import ExportReport from '../PowerBIEmbeddedDashboard/ExportReport'
import { SmallSpinner } from '../Shared/Spinner'

interface IProps {
  id: string,
  name: string,
  description: string,
  collection: string,
  embedUrl: string,
  isDraft: boolean,
  workSpaceId: string,
  setReportMode: (mode: "Create" | "View" | "Edit" | "") => void
  setReportId: (reportId: string) => void
  setEmbedUrl: (embedUrl: string) => void,
  status: string,
  reFetch: () => void,
  favorite: boolean,
  permissions: string[]
}

const ManagePowerBIRowEnable: FC<IProps> = (props) => {

  const { reFetch } = props
  let toastId: ToastId = 0

  const [favorite, setFavorite] = useState(props.favorite)
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setinputValue] = useState(props.description)
  const [lastValue, setLastValue] = useState(props.description)
  const [error, setError] = useState('')
  const [exportSpinner, setExportSpinner] = useState(false)

  const [disable] = useDisableReport({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Disabling the report")
      }
      reFetch()
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Disable report failed, please try again later.")
      }
    }
  })

  const [editReportDescription] = useEditReportDescription({
    onSuccess: () => {
    },
    onError: () => {
    }
  })

  const handleOnViewClick = () => {
    props.setReportId(props.id)
    props.setEmbedUrl(props.embedUrl)
    props.setReportMode("View")
  }

  const handleFavoriteClick = (fav: boolean) => {
    favoriteReport({ customerKey: getCustomerKey(), id: props.id, favorite: fav })
    setFavorite(fav)
  }

  const [favoriteReport] = useFavoriteReport({
    onSuccess: (_, data) => {
      if (data?.favorite)
        toastId = Toasts.success("Report added to favorites successfully!")
      else
        toastId = Toasts.success("Report removed from favorites successfully!")
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Something went wrong, Please try again later.")
      }
      setFavorite(!favorite)
    }
  })

  const disableReport = () => {
    disable({
      customerKey: getCustomerKey(),
      id: props.id,
      workSpaceId: props.workSpaceId
    })
  }

  const handleOnEditDescriptionClick = () => {
    setIsEditing(true)
  }

  const validateInput = (validValue: any) => {
    return validValue?.length <= 1000
  }

  const handleSave = () => {
    const isValid = validateInput(inputValue)
    if (!isValid) {
      setError('Input should be less than  1000 characters')
    }
    else {
      editReportDescription({
        customerKey: getCustomerKey(),
        id: props.id,
        description: inputValue
      })
      setIsEditing(false)
      setLastValue(inputValue)
      setError('')
    }
  }

  const handleCancel = () => {
    setIsEditing(false)
    setinputValue(lastValue)
    setError('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const isValid = validateInput(inputValue)
    if (!isValid) {
      setError('')
    }
    setinputValue(event.target.value)
  }

  return (
    <tr>
      {isEditing ?
        <td colSpan={5}>
          {
            <div>
              <span className="titleBoldSpan">Report Name</span>
              <span className="editDescriptionSpan">{props.name}</span>
              <span className="titleBoldSpan">Collection</span>
              <span className="editDescriptionSpan">{props.collection}</span>
              <span className="titleBoldSpan">Description</span>
              <span className="editDescriptionSpan" style={{ width: "750px" }}>

                <textarea
                  id={`${pascalize(props.name)}_editTextBox`}
                  className="form-control no-ie-clear field"
                  defaultValue={inputValue}
                  onChange={handleChange}
                />
                {error && <span className="validation-label" id={`${pascalize(props.name)}_validationError`} style={{ color: "red", lineHeight: "20px", display: "inline-block" }}>{error}</span>}
                <div style={{ float: "right" }}>
                  <Button style={{ margin: '0px 10px' }}
                    id={`${pascalize(props.name)}_cancel`}
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button purpose="primary"
                    id={`${pascalize(props.name)}_save`}
                    type="submit" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </span>
            </div>
          }
        </td> :
        <td>
          {props.name}
        </td>}
      {
        isEditing ? null : <td style={{ lineHeight: "1.5" }}>
          {inputValue}
        </td>
      }
      {
        isEditing ? null : <td>{props.collection}</td>
      }
      {
        isEditing ? null :
          <td>
            {
              exportSpinner ? <>Exporting...<SmallSpinner /></> : <>{props.status}</>
            }
          </td>
      }
      {
        isEditing ? null :
          <td style={{ display: "flex" }} >
            {
              <><ActionIcon
                id={`${props.id}_ViewIcon`}
                toolTip={"View Report"}
                onClick={handleOnViewClick}
                icon={faChartPie} />

                <ActionIcon
                  id={favorite ? `unfavorites` : `favorites`}
                  toolTip={favorite ? "Remove from Favorites" : "Add to Favorites"}
                  icon={favorite ? faStar : faStarEmpty}
                  onClick={() => {
                    handleFavoriteClick(!favorite);
                  }}
                  style={{ paddingRight: "3px" }} />

                <ActionIcon
                  id={`${pascalize(props.name)}_editIcon`}
                  toolTip={"Edit description"}
                  onClick={handleOnEditDescriptionClick}
                  icon={faPencilAlt}
                />
                {props.permissions.includes('Export') ?
                  <ExportReport reportName={props.name} customerKey={getCustomerKey()} reportId={props.id} showText={false}
                    setExportSpinner={setExportSpinner} forReport={false} /> : false
                }
                <Button id={`${pascalize(props.name)}_${pascalize("EthicsPoint")}_disable`}
                  onClick={disableReport}>
                  Disable
                </Button>

              </>
            }
          </td>
      }
    </tr>
  )
}

export default ManagePowerBIRowEnable