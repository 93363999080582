import React from 'react'
import { FavoriteView } from './FavoriteView'
import { useGetFavoriteReportsInfo } from './FavoriteApi'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'

export const FavoriteRoute = () => {

    const [{ reportInfo, loading, errors, status }, reFetchData] = useGetFavoriteReportsInfo()

    if (errors.length) return <UnexpectedError />
    if (loading || !status) return <Spinner />

    return (
        <FavoriteView reportInfo={reportInfo} refetch={reFetchData}/>
        )
}
