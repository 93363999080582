import React, { useRef, useState, useEffect, RefObject } from "react";

const useButtonLoader = (
  defaultText: string ,
  loadingText: string
): [RefObject<HTMLButtonElement>, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const element = useRef<HTMLButtonElement>(null);

     useEffect(() => {
       if (element.current) {
         if (isLoading) {
           element.current.disabled = true;
           element.current.innerHTML =
           loadingText +
               ' <div id="smallSpinner" class ="spinner-border spinner-border-sm text-primary" style= "vertical-align: middle" > </div>' ;
         } else {
           element.current.disabled = false;
           element.current.innerHTML = defaultText;
         }
       }
     }, [isLoading]);

  return [element, setLoading];
};

export default useButtonLoader;