import React, { FC, useEffect, useRef } from 'react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { getCustomerKey } from '../../Api/urls'
import { useGetEmbedTokenForReport } from './ManagePowerBIApi'
import './report.css'
import { models } from 'powerbi-client'

interface Iprops {
	reportId: string,
    embedUrl: string,
	reportMode: models.ViewMode
}

export const PowerBIReport: FC<Iprops> = (props) => {
    
	const [getEmbedTokenInfo, { posting, status, embedTokenForReportInfo, errors }] = useGetEmbedTokenForReport({
        onSuccess: () => {},
        onError: () => {}
    })

	const embedReportContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getEmbedTokenInfo({
            customerKey: getCustomerKey(),
            reportId: props.reportId,
            accessLevel: props.reportMode,
        })
    }, [])

    useEffect(() => {
        if (embedTokenForReportInfo && embedReportContainerRef.current) {
            let embedReportConfiguration = {
                type: "report",
                accessToken: embedTokenForReportInfo.token,
                id: props.reportId,
                tokenType: models.TokenType.Embed,
                embedUrl: props.embedUrl,
				viewMode:  props.reportMode,
				permissions: models.Permissions.All,
            }            
            window.powerbi.embed(embedReportContainerRef.current, embedReportConfiguration)            
        }
    }, [embedTokenForReportInfo, embedReportContainerRef, props.embedUrl, props.reportId, props.reportMode])

    return (<>
        {
            !!errors.length && <UnexpectedError />
        }{
            (posting || !status) && <Spinner />
        }
        <div id="embedReportContainer" ref={embedReportContainerRef} className={'report'} ></div>
    </>          
    )
}
