import React, { useState } from 'react'
import { CustomFieldsView } from './CustomFieldsView'
import { ICaseSection, loadCustomFieldData, UseEpimCustomFieldData } from '../EpimCustomFieldsV2/EpimCustomFieldsApi'
import { Spinner } from '../Shared/Spinner'
import { UnexpectedError } from '../Shared/Error'
import { DefaultPage } from 'navex-react'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
    toggles: string[]
    
}
export const CustomFieldsRoute = (props: IProps) => {

    const toggle =props.toggles.includes(TOGGLENAME.POWERBI_V2)
    const [{ customFieldData, loading, status, errors }, reFetch] = UseEpimCustomFieldData(toggle)
    
    const [poll, setPoll] = useState(0)
    const [newCaseSectionData, setnewCaseSectionData] = useState<ICaseSection[] | string>()

    if (errors.length) return <UnexpectedError />
    if (loading || !status) return <Spinner />

    let newCustomFieldData: ICaseSection[] | string = []
    let newDashboardData1: ICaseSection[] | string = customFieldData.caseSections

    const checkForPolling = (): boolean => {
        let val: boolean = false
        if (Array.isArray(newCaseSectionData)) {
            newCaseSectionData.forEach(x => x.customFieldItems.forEach(y => {
                if (y.status === "Processing" || y.status === "Deleting") {
                    val = true
                }
            }))
        } return val
    }

    const startPoll = async () => {
        const data = await loadCustomFieldData(toggle)
        if (Array.isArray(data)) {
            setnewCaseSectionData(data)
            setPoll(poll + 1)
        }
    }


    if (checkForPolling()) {
        const t = setTimeout(async () => {
            newDashboardData1 = await loadCustomFieldData(toggle)
            if (JSON.stringify(newDashboardData1) !== JSON.stringify(newCaseSectionData) && newDashboardData1 !== 'There was a bad response from the server') {
                setnewCaseSectionData(newDashboardData1)
                if ((checkForPolling()) !== true) {
                    setPoll(poll + 1)
                    clearTimeout(t)
                }
            }
            if (newDashboardData1 === 'There was a bad response from the server') {
                clearTimeout(t)
                reFetch()
            }
            if ((checkForPolling()) === true) {
                clearTimeout(t)
                setPoll(poll + 1)
            }
        }, window.customFieldPollTimeout)
    }

    if(newCaseSectionData === undefined){
        setnewCaseSectionData(newDashboardData1)
    }

    if (Array.isArray(newCaseSectionData)) {
        newCustomFieldData = newCaseSectionData.filter(x => x.customFieldItems.length !== 0 && x.isActive)
    }


    return (<DefaultPage>
        <CustomFieldsView
            customFieldData={newCustomFieldData}
            customerId={customFieldData.customerId}
            customerKey={customFieldData.customerKey}
            setPoll={startPoll}
            poll={poll}
            standardFieldNames={customFieldData.standardFieldNames}
            toggle ={toggle}/>
    </DefaultPage>)
}