import React, { useState } from 'react'
import { ICaseSection, ICustomFieldItem, ICustomFields, useUpsertCustomFieldMappings } from '../EpimCustomFieldsV2/EpimCustomFieldsApi'
import { Accordion, Card, ButtonRow, Button, ActionIcon, ToastId, Toasts, IButtonProps, PopOver } from "navex-react"
import { ThrottledSearchBox } from '../SearchBox/ThrottledSearchBox'
import { faCheck, faTimes, faExclamationCircle, faTrashAlt, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { isValidIdentifier, calculateSuggestedFieldName } from '../../Utils/customFieldUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './CustomFields.module.scss'
import { NoDataError } from '../Shared/Error'
import { Modal } from 'navex-react'

interface IProps {
    customFieldData: ICaseSection[],
    customerId: number,
    customerKey: string,
    setPoll: () => void,
    poll: number,
    standardFieldNames: string[],
    toggle: boolean
}

interface ICheckboxState {
    [keyId: number]: boolean
}

interface IFieldState {
    [keyId: number]: string
}

const computeIsEditState = (customFieldData: ICaseSection[]) => {
    let isEdit: ICheckboxState = {}
    customFieldData.forEach(customFieldItem =>
        customFieldItem.customFieldItems.forEach(customField => {
            const key = customField.customFieldId
            isEdit[key] = false
        })
    )
    return isEdit
}

const computeFilteredCustomFields = (customFields: ICaseSection[], filterText: string = '', caseSectionClicked: string, sortOrder: string, sortBy: string) => {
    const data: ICaseSection[] = JSON.parse(JSON.stringify(customFields))
    if (filterText === '') {
        data.map(caseSection =>
            caseSection.customFieldItems =
            caseSection.customFieldItems.filter(
                customField => customField.customFieldName !== null &&
                    customField.isInsightsEligible)
        )
        const filteredData = data.filter(caseSection => caseSection.customFieldItems.length !== 0)
        return sortData(filteredData, caseSectionClicked, sortOrder, sortBy)
    }
    const text = filterText.toLocaleUpperCase()
    data.map(caseSection =>
        caseSection.customFieldItems =
        caseSection.customFieldItems.filter(
            customField => customField.customFieldName !== null &&
                customField.customFieldName.toLocaleUpperCase().includes(text) &&
                customField.isInsightsEligible)
    )
    const filteredData = data.filter(caseSection => caseSection.customFieldItems.length !== 0)
    return sortData(filteredData, caseSectionClicked, sortOrder, sortBy)
}

const sortData = (customFields: ICaseSection[], caseSectionClicked: string, sortOrder: string, sortBy: string) => {
    switch (sortBy) {
        case 'customField':
            customFields.forEach(caseSection => {
                if (caseSection.name === caseSectionClicked) {
                    if (sortOrder === 'asc')
                        caseSection.customFieldItems.sort((a, b) => (a.customFieldName!.toUpperCase() > b.customFieldName!.toUpperCase()) ? 1 : -1)
                    if (sortOrder === 'desc')
                        caseSection.customFieldItems.sort((a, b) => (a.customFieldName!.toUpperCase() > b.customFieldName!.toUpperCase()) ? -1 : 1)
                }
            })
            break;
        case 'insightsField':
            customFields.forEach(caseSection => {
                if (caseSection.name === caseSectionClicked) {
                    if (sortOrder === 'asc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.insightsFieldName === null) ? "" : "".concat(a.insightsFieldName),
                                vb = (b.insightsFieldName === null) ? "" : "".concat(b.insightsFieldName)
                            if (va === "")
                                return 1
                            return va > vb ? 1 : (va.toUpperCase() === vb.toUpperCase() ? 0 : -1)
                        })
                    if (sortOrder === 'desc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.insightsFieldName === null) ? "" : "".concat(a.insightsFieldName),
                                vb = (b.insightsFieldName === null) ? "" : "".concat(b.insightsFieldName)
                            if (va === "")
                                return 1
                            return va.toUpperCase() > vb.toUpperCase() ? -1 : (va.toUpperCase() === vb.toUpperCase() ? 0 : 1)
                        })
                }
            })
            break;
        case 'status':
            customFields.forEach(caseSection => {
                if (caseSection.name === caseSectionClicked) {
                    if (sortOrder === 'asc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.status === null) ? "" : "".concat(a.status),
                                vb = (b.status === null) ? "" : "".concat(b.status)
                            return va > vb ? 1 : (va.toUpperCase() === vb.toUpperCase() ? 0 : -1)
                        })
                    if (sortOrder === 'desc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.status === null) ? "" : "".concat(a.status),
                                vb = (b.status === null) ? "" : "".concat(b.status)
                            return va.toUpperCase() > vb.toUpperCase() ? -1 : (va.toUpperCase() === vb.toUpperCase() ? 0 : 1)
                        })
                }
            })
            break;
        case 'lastUpdated':
            customFields.forEach(caseSection => {
                if (caseSection.name === caseSectionClicked) {
                    if (sortOrder === 'asc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.modifiedDate.toString() === "0001-01-01T00:00:00") ? null : new Date(a.modifiedDate),
                                vb = (b.modifiedDate.toString() === "0001-01-01T00:00:00") ? null : new Date(b.modifiedDate)
                            if (va === null)
                                return 1
                            if (vb === null)
                                return -1
                            return va > vb ? 1 : (va === vb ? 0 : -1)
                        })
                    if (sortOrder === 'desc')
                        caseSection.customFieldItems.sort(function (a, b) {
                            var va = (a.modifiedDate.toString() === "0001-01-01T00:00:00") ? null : new Date(a.modifiedDate),
                                vb = (b.modifiedDate.toString() === "0001-01-01T00:00:00") ? null : new Date(b.modifiedDate)
                            if (va === null)
                                return 1
                            if (vb === null)
                                return -1
                            return va > vb ? -1 : (va === vb ? 0 : 1)
                        })
                }
            })
            break;
    }
    return customFields
}

export const CustomFieldsView = (props: IProps) => {

    let toastId: ToastId = 0

    const [upsert] = useUpsertCustomFieldMappings(props.toggle, {
        onSuccess: () => {
            setCustomFieldNames({} as IFieldState)
            setCustomFieldNames2({} as IFieldState)
            props.setPoll()
            setDisableSaveState(false)
        },
        onError: (errors) => {
            if (!Toasts.isActive(toastId)) {
                if (!Toasts.isActive(toastId)) {
                    if (errors[0].response.data.errors[0].key === 202) {
                        setIsError(true)
                        setErrorMessage(errors[0].response.data.errors[0].message)
                    }
                    else {
                        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
                    }
                }
            }
            setDisableSaveState(false)
        }
    })


    const initialIsEditState: ICheckboxState = computeIsEditState(props.customFieldData)
    const [isEdit, setIsEdit] = useState(initialIsEditState)
    const [customFieldNames, setCustomFieldNames] = useState({} as IFieldState)
    const [customFieldNames2, setCustomFieldNames2] = useState({} as IFieldState)
    const [okButtonProps, setOkButtonProps] = useState({} as IButtonProps)
    const [filteredText, setFilteredText] = useState('')
    const [fieldRequestedForDelete, setFieldRequestedForDelete] = useState(-1)
    const [showModal, setShowModal] = useState(false)
    const [ModalText, setModalText] = useState(<p>Modal Text</p>)
    const open = () => setShowModal(true)
    const close = () => setShowModal(false)
    const [caseSectionChanged, setCaseSectionChanged] = useState([] as number[])
    const [isError, setIsError] = useState(false)
    const [activeCardIndex, setActiveCardIndex] = useState(-1)
    const [errorMessage, setErrorMessage] = useState("")
    const [caseSectionClicked, setCaseSectionClicked] = useState("")
    const [sortOrder, setSortOrder] = useState("")
    const [sortBy, setSortBy] = useState("")
    const [disableSaveState, setDisableSaveState] = useState(false)

    const handleDelete = async (customField: ICustomFieldItem) => {
        open()
        setFieldRequestedForDelete(customField.customFieldId)
        let buttonProps = {} as IButtonProps
        buttonProps.disabled = false
        setOkButtonProps(buttonProps)
        setModalText(
            <>
                Are you sure you wish to delete "{customField.insightsFieldName}" field from Insights?
                <div className={styles.alignCenter}>
                </div></>)
    }

    const upsertCustomFields = () => {
        setDisableSaveState(true)
        let upsertData: ICustomFields[] = []
        props.customFieldData.forEach(caseSection => caseSection.customFieldItems.forEach(customField => {
            if (customFieldNames.hasOwnProperty(customField.customFieldId)) {
                upsertData.push({
                    actionType: 0,
                    isMeasure: false,
                    isAttribute: true,
                    analyzeByDate: false,
                    customFieldId: customField.customFieldId,
                    dataTypeId: customField.dataTypeId,
                    dataType: customField.dataType,
                    customFieldName: customField.customFieldName,
                    insightsFieldName: customFieldNames[customField.customFieldId],
                    hierarchy: customField.hierarchy,
                    isActive: customField.isActive,
                    customerId: props.customerId,
                    displaySectionId: customField.caseSectionId,
                })
            }
        }))

        let error: boolean = false
        let caseSections: number[] = []
        let errormessage: number[] = []
        upsertData.forEach(val => {
            if (val.isAttribute === false && val.isMeasure === false) {
                error = true
                if (!caseSections.includes(val.displaySectionId)) {
                    caseSections.push(val.displaySectionId)
                }
                errormessage.push(val.dataTypeId)
            }
        })

        if (error) {
            ((errormessage.includes(3) === true) || (errormessage.includes(4) === true) || (errormessage.includes(7) === true)) === true ?
                setErrorMessage("* Please select a custom field as a Measure or an Attribute. It cannot be empty.") :
                setErrorMessage("* Please select a custom field as an Attribute. It cannot be empty.")
            setIsError(error)
            setDisableSaveState(false)
            setCaseSectionChanged(caseSections)
            setActiveCardIndex(-1)
            window.scrollTo(0, 0)
        }
        else {
            setIsError(false)
            setCaseSectionChanged([])
            upsert({
                customerId: props.customerId,
                customFieldMappingList: upsertData,
            })
        }
    }

    const submitDelete = () => {
        let upsertData: ICustomFields[] = []
        props.customFieldData.forEach(caseSection => caseSection.customFieldItems.forEach(customField => {
            if (customField.customFieldId === fieldRequestedForDelete) {
                upsertData.push({
                    actionType: 2,
                    isMeasure: false,
                    isAttribute: false,
                    analyzeByDate: false,
                    customFieldId: fieldRequestedForDelete,
                    dataTypeId: customField.dataTypeId,
                    dataType: customField.dataType,
                    customFieldName: customField.customFieldName,
                    insightsFieldName: customField.insightsFieldName!,
                    hierarchy: customField.hierarchy,
                    isActive: customField.isActive,
                    customerId: props.customerId,
                    displaySectionId: customField.caseSectionId,
                })
            }
        }))
        upsert({
            customerId: props.customerId,
            customFieldMappingList: upsertData,
        })
        close()
    }

    const retryUpsertCustomField = (customField: ICustomFieldItem) => {
        setDisableSaveState(true)
        let upsertData: ICustomFields[] = []
        upsertData.push({
            actionType: 3,
            isMeasure: false,
            isAttribute: true,
            analyzeByDate: false,
            customFieldId: customField.customFieldId,
            dataTypeId: customField.dataTypeId,
            dataType: customField.dataType,
            customFieldName: customField.customFieldName,
            insightsFieldName: customField.insightsFieldName!,  //failed will always have insightsFieldName due to checks before so no need to recheck
            hierarchy: customField.hierarchy,
            isActive: customField.isActive,
            customerId: props.customerId,
            displaySectionId: customField.caseSectionId,
        })
        upsert({
            customerId: props.customerId,
            customFieldMappingList: upsertData,
        })
    }

    const onClickCancel = (id: number) => {
        const oldEditState = { ...isEdit }
        oldEditState[id] = false
        setIsEdit(oldEditState)
        const oldCustomFieldNames = { ...customFieldNames }
        delete oldCustomFieldNames[id]
        setCustomFieldNames(oldCustomFieldNames)
    }

    const enableRowEdit = (id: number) => {
        const oldIsEditState = { ...isEdit }
        oldIsEditState[id] = true
        setIsEdit(oldIsEditState)
        const oldCustomFieldNames2 = { ...customFieldNames2 }
        delete oldCustomFieldNames2[id]
        setCustomFieldNames2(oldCustomFieldNames2)
    }

    const isProposedInsightsFieldNameUnique = (proposedInsightsFieldName: string | undefined) => {
        if (!proposedInsightsFieldName)
            return false
        let insightsFieldNames: string[] = Object.entries(customFieldNames2).filter(([k, v]) => v).map(([k, v]) => v.toUpperCase())
        props.customFieldData.forEach(caseSection => caseSection.customFieldItems.forEach(customField => {
            if (customField.insightsFieldName !== null)
                insightsFieldNames.push(customField.insightsFieldName.toUpperCase())
        }))
        return !insightsFieldNames.includes(proposedInsightsFieldName.toUpperCase())
    }

    const cardChanged = (newActiveCardIndex: number) => {
        setActiveCardIndex(newActiveCardIndex)
    }

    const setInAscendingOrderForCustomFieldName = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('asc')
        setSortBy("customField")
    }

    const setInDescendingOrderForCustomFieldName = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('desc')
        setSortBy("customField")
    }

    const setInAscendingOrderForInsightsFieldName = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('asc')
        setSortBy("insightsField")
    }

    const setInDescendingOrderForInsightsFieldName = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('desc')
        setSortBy("insightsField")
    }

    const setInAscendingOrderForStatus = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('asc')
        setSortBy("status")
    }

    const setInDescendingOrderForStatus = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('desc')
        setSortBy("status")
    }

    const setInAscendingOrderForLastUpdatedBy = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('asc')
        setSortBy("lastUpdated")
    }

    const setInDescendingOrderForLastUpdatedBy = (casesection: string) => {
        setCaseSectionClicked(casesection)
        setSortOrder('desc')
        setSortBy("lastUpdated")
    }

    const filteredCustomFields = computeFilteredCustomFields(JSON.parse(JSON.stringify(props.customFieldData)), filteredText, caseSectionClicked, sortOrder, sortBy)
    const disableSave = props.customFieldData.filter(caseSection => caseSection).some(caseSection => caseSection.customFieldItems.some(customField => customField.status === "Processing" || customField.status === "Deleting"))

    // const IsUnSavedChagesAreThere = () => {
    //     return ((Object.keys(customFieldNames).length > 0) || Object.values(isEdit).includes(true))
    // }

    return (<>
        <ThrottledSearchBox placeholder="Search Custom Fields" changeFilterText={setFilteredText} />
        <br />
        {filteredCustomFields.length ? <>
            <Accordion activeCardIndex={activeCardIndex} onActiveCardChange={cardChanged}>
                {
                    filteredCustomFields.map(casesection =>
                        <Card title={<div>{casesection.name}<span className="validation-label" hidden={!caseSectionChanged.includes(casesection.caseSectionId)}>{"*"}</span></div>} id={casesection.name} badgeNumber={casesection.customFieldItems.length} key={casesection.name}>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ overflow: "hidden" }}>EthicsPoint Custom Field<>
                                            {
                                                sortOrder === 'desc' && caseSectionClicked === casesection.name && sortBy === "customField" ?
                                                    <ActionIcon
                                                        id={`descendingOrderForCustomFieldName_${casesection.name}`}
                                                        icon={faCaretDown}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInAscendingOrderForCustomFieldName(casesection.name)} /> :
                                                    <ActionIcon
                                                        id={`ascendingOrderForCustomFieldName_${casesection.name}`}
                                                        icon={faCaretUp}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInDescendingOrderForCustomFieldName(casesection.name)} />
                                            }
                                        </></th>
                                        <th scope="col" style={{ whiteSpace: "nowrap" }}>Insights Field Name<>
                                            {
                                                sortOrder === 'desc' && caseSectionClicked === casesection.name && sortBy === "insightsField" ?
                                                    <ActionIcon
                                                        id={`descendingOrderForInsightsFieldName_${casesection.name}`}
                                                        icon={faCaretDown}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInAscendingOrderForInsightsFieldName(casesection.name)} /> :
                                                    <ActionIcon
                                                        id={`ascendingOrderForInsightsFieldName_${casesection.name}`}
                                                        icon={faCaretUp}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInDescendingOrderForInsightsFieldName(casesection.name)} />
                                            }
                                        </></th>
                                        <th scope="col" style={{ whiteSpace: "nowrap" }}>Status<>
                                            {
                                                sortOrder === 'desc' && caseSectionClicked === casesection.name && sortBy === "status" ?
                                                    <ActionIcon
                                                        id={`descendingOrderForStatus_${casesection.name}`}
                                                        icon={faCaretDown}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInAscendingOrderForStatus(casesection.name)} /> :
                                                    <ActionIcon
                                                        id={`ascendingOrderForStatus_${casesection.name}`}
                                                        icon={faCaretUp}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInDescendingOrderForStatus(casesection.name)} />
                                            }
                                        </></th>
                                        <th scope="col" style={{ whiteSpace: "nowrap" }}>Added By</th>
                                        <th scope="col" style={{ overflow: "hidden" }}>History<>
                                            {
                                                sortOrder === 'desc' && caseSectionClicked === casesection.name && sortBy === "lastUpdated" ?
                                                    <ActionIcon
                                                        id={`descendingOrderForLastUpdated_${casesection.name}`}
                                                        icon={faCaretDown}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInAscendingOrderForLastUpdatedBy(casesection.name)} /> :
                                                    <ActionIcon
                                                        id={`ascendingOrderForLastUpdated_${casesection.name}`}
                                                        icon={faCaretUp}
                                                        toolTip=""
                                                        purpose={"widget"}
                                                        style={{ float: "right" }}
                                                        onClick={() => setInDescendingOrderForLastUpdatedBy(casesection.name)} />
                                            }
                                        </></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>

                                        <th scope="col">Action</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        casesection.customFieldItems.map(customField =>
                                            <tr key={`${customField.customFieldId}`}>
                                                <td>{customField.customFieldName}</td>
                                                <td>{customField.insightsFieldName !== null ? customField.insightsFieldName :
                                                    isEdit[customField.customFieldId] ?
                                                        <Formik
                                                            validateOnChange={true}
                                                            validateOnBlur={true}
                                                            initialValues={
                                                                {
                                                                    insightsFieldNameUnderEdit: calculateSuggestedFieldName((customField.customFieldName !== null) ? customField.customFieldName : "", isProposedInsightsFieldNameUnique)
                                                                }}
                                                            onSubmit={(values, actions) => {
                                                                actions.setSubmitting(false)
                                                                const oldEditState = { ...isEdit }
                                                                oldEditState[customField.customFieldId] = false
                                                                setIsEdit(oldEditState)
                                                                const oldCustomFieldNames = { ...customFieldNames }
                                                                oldCustomFieldNames[customField.customFieldId] = values.insightsFieldNameUnderEdit
                                                                setCustomFieldNames(oldCustomFieldNames)
                                                                const oldCustomFieldNames2 = { ...customFieldNames2 }
                                                                oldCustomFieldNames2[customField.customFieldId] = values.insightsFieldNameUnderEdit
                                                                setCustomFieldNames2(oldCustomFieldNames2)
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                insightsFieldNameUnderEdit: Yup.string()
                                                                    .required("Enter Insights field name")
                                                                    .min(5, "Must be at least 5 characters")
                                                                    .max(35, "Must be 35 characters or less")
                                                                    .test("isUnique", "This value is already mapped to another field", isProposedInsightsFieldNameUnique)
                                                                    .test("isValidDbIdentifier", "Value is not a valid field name", isValidIdentifier)
                                                            })} >
                                                            {({ values, errors, isSubmitting, status, touched }) => (
                                                                <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                                    <div className="row">
                                                                        <div className="form-control-feedback-group" >
                                                                            <Field
                                                                                id={`${customField.customFieldId}_EditField`}
                                                                                name="insightsFieldNameUnderEdit"
                                                                                className="form-control no-ie-clear field"
                                                                                aria-describedby="name-help-block"
                                                                                aria-invalid={errors.insightsFieldNameUnderEdit && touched.insightsFieldNameUnderEdit}
                                                                                aria-required="true"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                        </div>
                                                                        <Button purpose="primary" style={{ margin: '0px 10px' }}
                                                                            id={`${customField.customFieldId}_submit`}
                                                                            type="submit" onClick={() => {
                                                                                const oldCustomFieldNames = { ...customFieldNames }
                                                                                oldCustomFieldNames[customField.customFieldId] = values.insightsFieldNameUnderEdit
                                                                                setCustomFieldNames(oldCustomFieldNames)
                                                                            }}>
                                                                            <FontAwesomeIcon
                                                                                icon={faCheck} />
                                                                        </Button>
                                                                        <Button purpose="primary" id={`${customField.customFieldId}_cancel`}
                                                                            onClick={() => onClickCancel(customField.customFieldId)}>
                                                                            <FontAwesomeIcon
                                                                                icon={faTimes} />
                                                                        </Button>
                                                                    </div>
                                                                    <label className="control-label" htmlFor="insightsFieldNameUnderEdit" style={{ marginBottom: 0 }}>
                                                                        <span className="validation-required">{" * "}</span>
                                                                        {errors.insightsFieldNameUnderEdit && touched.insightsFieldNameUnderEdit ? (<span className="validation-label" id={`${customField.customFieldId}_validationError`} aria-hidden="false">{errors.insightsFieldNameUnderEdit}</span>) : null}
                                                                    </label>
                                                                </Form>
                                                            )}
                                                        </Formik> : customFieldNames.hasOwnProperty(customField.customFieldId) ?
                                                            <Button purpose="link" id={`${customField.customFieldId}_editInsightsField`}
                                                                onClick={() => enableRowEdit(customField.customFieldId)}>{customFieldNames[customField.customFieldId]}</Button>
                                                            : null}</td>
                                                <td>
                                                    {customField.status == null && (
                                                        <>
                                                            <div style={{ color: "#929090" }}>
                                                                Not Added</div>
                                                        </>
                                                    )}
                                                    {customField.status === "Failed" && (
                                                        <>
                                                            {"Adding Failed"}
                                                            <ActionIcon id={`${customField.customFieldId}_toolTip`}
                                                                color={'red'} className={styles.toolTip} toolTip={`Some error occured, Error Id : ${customField.correlationId}. Please retry.`} icon={faExclamationCircle} />
                                                        </>
                                                    )}
                                                    
                                                    {customField.status === "Success" && (<div>Added</div>)}
                                                    {customField.status === "DeleteFailed" && (<>
                                                        {"Deleting Failed"}
                                                        <ActionIcon id={`${customField.customFieldId}_toolTip`}
                                                            color={'red'} className={styles.toolTip} toolTip={`Some error occured, Error Id : ${customField.correlationId}. Please retry.`} icon={faExclamationCircle} />
                                                    </>)}
                                                    
                                                </td>

                                                <td>{customField.modifiedBy}</td>
                                                <td>{
                                                    customField.history.length > 0 ?
                                                        <div>{customField.history[0]}
                                                            {
                                                                customField.history.length > 1 ?
                                                                    <PopOver id={`${customField.customFieldId}_popOver`} size="large">
                                                                        <label role='alert' aria-labelledby={`${customField.customFieldId}_popOver`}>{customField.history.slice(1).map(x => <div key={x}>{x}{<br />}</div>)}</label>
                                                                    </PopOver> : null}
                                                        </div> : null
                                                }</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {(customField.status === null || customField.status === "DeleteSuccess") ?
                                                        isEdit[customField.customFieldId] || customFieldNames.hasOwnProperty(customField.customFieldId) ?
                                                            <Button
                                                                id={`${customField.customFieldId}_addIconDisabled`}
                                                                disabled={true}>
                                                                Add
                                                            </Button>
                                                            : <Button
                                                                id={`${customField.customFieldId}_addIcon`}
                                                                onClick={() => enableRowEdit(customField.customFieldId)}>
                                                                Add
                                                            </Button>
                                                        :
                                                        (customField.status === 'Failed') ?
                                                            <Button
                                                                id={`${customField.customFieldId}_retryButton`}
                                                                purpose="primary"
                                                                disabled={disableSave || disableSaveState}
                                                                onClick={() => retryUpsertCustomField(customField)}>
                                                                Retry
                                                            </Button>
                                                            :
                                                            <Button
                                                                id={`${customField.customFieldId}_addIconDisabled`}
                                                                disabled={true}>
                                                                Add
                                                            </Button>}
                                                </td>
                                                <td>
                                                    {customField.status === "Success" &&
                                                        <ActionIcon
                                                            id={`${customField.customFieldId}_deleteIcon`}
                                                            toolTip={"Delete field"}
                                                            icon={faTrashAlt}
                                                            onClick={() => handleDelete(customField)}
                                                            disabled={disableSave} />}

                                                    {customField.status === "DeleteFailed" &&
                                                        <Button purpose="primary"
                                                            id={`${customField.customFieldId}_deleteRetry`}
                                                            onClick={() => handleDelete(customField)}
                                                            disabled={disableSave}
                                                        >Retry</Button>}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </Card>
                    )
                }
            </Accordion>
            <Modal
                id="deleteModal"
                show={showModal}
                okButtonProps={okButtonProps}
                okButtonText="Delete"
                message={ModalText}
                headerText="Delete Confirmation"
                onClickedOk={submitDelete}
                onClickedCancel={close}
            />
            <ButtonRow alignment="left" className={styles.buttonRowSave}>
                <Button purpose="primary" id={"CustomFieldV3Save"}
                    disabled={!Object.keys(customFieldNames).length || Object.values(isEdit).includes(true) || disableSave || disableSaveState}
                    onClick={upsertCustomFields}>
                    Save
                </Button>
                <span id="errorMessageOnSaveButton" className="validation-label" hidden={!isError}>{errorMessage}</span>
            </ButtonRow></> : <NoDataError />
        }
    </>
    )
}

export default CustomFieldsView
