import React from 'react'
import { OutcomesView } from './OutcomesView'
import { useGetSubstantiatedTypeMapping } from './OutcomesApi'
import './outcomes.scss'
import { Spinner } from '../Shared/Spinner'
import { UnexpectedError } from '../Shared/Error'
import { DefaultPage } from 'navex-react'

export const OutcomesRoute = () => {
  
  const [{ mappings, options, loading, status, errors }] = useGetSubstantiatedTypeMapping()

  if (errors.length) return <UnexpectedError />
  if (loading || !status) return <Spinner />

  return (<DefaultPage>
    <OutcomesView mappings={mappings} options={options} /> 
  </DefaultPage>)
}
