import React, { FC } from 'react'
import { Spinner } from '../Shared/Spinner'
import { UnexpectedError } from '../Shared/Error'
import { DefaultPage } from 'navex-react'
import { useGetDashboards } from './ViewDashboardApi'
import ManageDashboardView from './ManageDashboardView'
import { BirstEmbeddedEditDashboard } from './BirstEmbeddedEditDashboard'

interface IProps {
  toggles: string[]
}

export const ViewDashboardRoute: FC<IProps> = (props) => {

  const [{ dashboards, loading, status, errors }] = useGetDashboards()

  if (errors.length) return <UnexpectedError />
  if (loading || !status) return <Spinner />
  
  const toggleEditDashboard = window.location.pathname.includes('DashboardManagement_EditDashboard') ? false : true

  return (<DefaultPage>
    {
      toggleEditDashboard
        ?
        <ManageDashboardView dashboardData={dashboards} toggles={props.toggles}/>
        :
        <BirstEmbeddedEditDashboard data={dashboards} toggles={props.toggles}/>
    }

  </DefaultPage>
  )
}