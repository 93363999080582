import React, { FC } from 'react'
import { Checkbox } from 'navex-react'
import { IDashboardResetData } from './ResetDashboardV2Api'
import { DASHBOARDRESETSTATUS } from '../../ts/enums/dashboardResetStatus'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'
import { ICheckBoxState } from './ResetDashboardTabView'
import { SmallSpinner } from '../Shared/Spinner'

interface IProps {
    collectionName: string,
    dashboards: IDashboardResetData[],
    productName: string,
    checkBoxDashState: ICheckBoxState<number[]>,
    setCheckBoxDashState: (checkBoxState: ICheckBoxState<number[]>) => void,
    dashboardsPolling: ICheckBoxState<boolean>,
    setDashboardsPolling: (checkBoxState: ICheckBoxState<boolean>) => void
}

export const ResetDashboardListItem: FC<IProps> = (props) => {
    const { collectionName, dashboards, checkBoxDashState, setCheckBoxDashState,
        dashboardsPolling, setDashboardsPolling } = props

    const handleCheckboxChange = (productName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        var idThatChanged = Number(event.target.value)
        var isChecked = event.target.checked

        const oldDashboardsPolling = { ...dashboardsPolling }
        oldDashboardsPolling[idThatChanged] = isChecked
        setDashboardsPolling(oldDashboardsPolling)

        const oldCheckBoxDashState = { ...checkBoxDashState }

        if (!Object.keys(oldCheckBoxDashState).includes(productName)) {
            oldCheckBoxDashState[productName] = []
        }

        if (isChecked && !oldCheckBoxDashState[productName].includes(idThatChanged)) {
            oldCheckBoxDashState[productName].push(idThatChanged)
        }
        else {
            var index = oldCheckBoxDashState[productName].indexOf(idThatChanged)
            if (index > -1) {
                oldCheckBoxDashState[productName].splice(index, 1)
            }
            if (Object.keys(oldCheckBoxDashState[productName]).length === 0) {
                delete oldCheckBoxDashState[productName]
            }
        }
        setCheckBoxDashState(oldCheckBoxDashState)
    }

    const disableInProcessDashboards = (status: string) => {
        return status === DASHBOARDRESETSTATUS.INPROCESS ? true : false
    }


    return (
        <>
            {dashboards.map(d => {
                return (
                    <tr key={d.dashboardId} id={`tr_${d.dashboardId.toString()}`}>
                        <td>
                            <Checkbox style={{ marginTop: "10px" }}
                            id={`dashboard_${d.dashboardId.toString()}`}
                            key={d.dashboardId}
                            value={d.dashboardId.toString()}
                            onChange={handleCheckboxChange(props.productName)}
                            disabled={disableInProcessDashboards(d.status)}
                            checked={dashboardsPolling[d.dashboardId] || false} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}                            />
                        </td>
                        <td>{d.dashboardName}</td>
                        <td>{collectionName}</td>
                        <td>
                            {d.status === DASHBOARDRESETSTATUS.FAILED &&
                                <>
                                    {d.status}
                                    {/* Using reactstrap since ActionIcon cuts off the tooltip text (max-width limited to 200px) */}
                                    <span style={{ color: "red", paddingLeft: "3px" }} id={"toolTip_" + d.dashboardId}>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="icon" style={{ paddingTop: "4px" }} />
                                    </span>
                                    <UncontrolledTooltip placement="bottom" target={"toolTip_" + d.dashboardId}>
                                        {`Some error occured, Error Id : ${d.correlationId}. Please retry.`}
                                    </UncontrolledTooltip>
                                </>
                            }
                            {d.status === DASHBOARDRESETSTATUS.COMPLETED && d.status}
                            {d.status === DASHBOARDRESETSTATUS.INPROCESS && <SmallSpinner />}
                        </td>
                        <td>{d.lastRequestedBy}</td>
                        <td>{d.lastRequested ? d.lastRequested.slice(0, 10) : d.lastRequested}</td>
                        <td>{d.lastRequestedReason}</td>
                    </tr>
                )
            })}
        </>
    )
} 