import React, { FC } from 'react'
import moment from 'moment'
import styles from "./FiscalCalendar.module.scss"
import { IQuarters } from './FiscalCalendarApi'
import { isLeapYear } from './FiscalCalenderDetailsView'
import { DAYS_OF_THE_WEEK } from './FiscalQuarters'
import { months } from '../../Utils/fiscalCalendarUtils'

interface IProps {
    quarters: IQuarters[],
    startDateToLocale: string,
    endDateToLocale: string,
    fiscalYear: number,
}

type TDisplayType = "COMPLETE" | "FIRST" | "LAST" | "MERGETOFIRST" | "MERGETOLAST"
type TQuarter = { type: TDisplayType, date1: string, date2?: string }

export const PreviewAnnualCalendar: FC<IProps> = (props) => {

    const { quarters, startDateToLocale, fiscalYear } = props
    const startDate = new Date(startDateToLocale)

    let quarter1Months = [] as TQuarter[], quarter2Months = [] as TQuarter[],
        quarter3Months = [] as TQuarter[], quarter4Months = [] as TQuarter[]
    let quarter1Info, quarter2Info, quarter3Info, quarter4Info

    const addYear = (date: Date) => {
        const isLeapYr = isLeapYear(date.getFullYear())
        let result = moment(date).add(1, 'year')
        if (isLeapYr && date.getDate() === 29 && date.getMonth() === 1) {
            return result.toDate()
        }
        else {
            result.subtract(1, 'day').toDate()
        }
        return result.toDate()
    }

    const endDate = moment(addYear(startDate)).toDate()

    const getShortMonthNameInDate = (startDate: string, endDate: string) => {
        const sDate = moment(new Date(startDate)).format('ll')
        const eDate = moment(new Date(endDate)).format('ll')
        return `Start Date: ${sDate}, End Date: ${eDate}`
    }

    const monthsBtwnDates = (startDate: string, endDate: string) => {
        const sDate = new Date(startDate)
        const eDate = new Date(endDate)
        return Math.max(
            (eDate.getFullYear() - sDate.getFullYear()) * 12 +
            eDate.getMonth() -
            sDate.getMonth(),
            0
        )
    }

    const countWeeksOfMonth = (date: Date) => {
        var year = date.getFullYear()
        var month_number = date.getMonth() + 1
        var firstOfMonth = new Date(year, month_number - 1, 1)
        var lastOfMonth = new Date(year, month_number, 0)
        var used = firstOfMonth.getDay() + lastOfMonth.getDate();
        return Math.ceil(used / 7);
    }

    const calMonths = (quarterStartDate: string, quarterEndDate: string, months: TQuarter[]) => {
        const quarSDate = new Date(quarterStartDate)
        const quarEDate = new Date(quarterEndDate)
        const endDayOfQuarSDate = new Date(quarSDate.getFullYear(), quarSDate.getMonth() + 1, 0)

        const noOfMonths = monthsBtwnDates(quarterStartDate, quarterEndDate)
        const month1 = moment(new Date(quarterStartDate)).add(1, 'month').toDate()
        const firstDayOfMonth1 = new Date(month1.getFullYear(), month1.getMonth(), 1)

        const month2 = moment(new Date(quarterStartDate)).add(2, 'month').toDate()
        const endDayOfMonth2 = new Date(month2.getFullYear(), month2.getMonth() + 1, 0)

        let daysAvailable = 6 - endDayOfMonth2.getDay()

        const noOfWeeks = countWeeksOfMonth(endDayOfMonth2)
        if (noOfWeeks === 5) {
            daysAvailable += 7
        }

        const month3 = moment(new Date(quarterStartDate)).add(3, 'month').toDate()

        if (startDate.getDate() === 1) {
            months.push({ type: 'COMPLETE', date1: quarterStartDate })
            months.push({ type: 'COMPLETE', date1: month1.toLocaleDateString() })
            months.push({ type: 'COMPLETE', date1: quarterEndDate })
        }
        else {
            const noOfDays = moment(endDayOfQuarSDate).diff(quarSDate, 'days')
            if (noOfDays < 6 && firstDayOfMonth1.getDay() > noOfDays && firstDayOfMonth1.getDay() > 0) {
                months.push({ type: "MERGETOFIRST", date1: quarterStartDate, date2: firstDayOfMonth1.toLocaleDateString() })
                if (noOfMonths === 3) {
                    months.push({ type: 'COMPLETE', date1: month2.toLocaleDateString() })
                }
                if (noOfMonths === 4) {
                    months.push({ type: 'COMPLETE', date1: month2.toLocaleDateString() })
                    if (quarEDate.getDate() <= 2) {
                        months.push({ type: 'MERGETOLAST', date1: month3.toLocaleDateString(), date2: quarterEndDate })
                    }
                }
                if (quarEDate.getDate() >= 3) {
                    months.push({ type: 'FIRST', date1: quarterEndDate })
                }
            }
            else {
                if (quarSDate.getDate() >= 28) {
                    months.push({ type: "MERGETOFIRST", date1: quarterStartDate, date2: firstDayOfMonth1.toLocaleDateString() })
                }
                else {
                    months.push({ type: 'LAST', date1: quarterStartDate })
                    months.push({ type: 'COMPLETE', date1: month1.toLocaleDateString() })
                }
                if (noOfMonths === 3) {
                    if (quarEDate.getDate() <= daysAvailable) {
                        months.push({ type: "MERGETOLAST", date1: month2.toLocaleDateString(), date2: quarterEndDate })

                    } else {
                        months.push({ type: 'COMPLETE', date1: month2.toLocaleDateString() })
                    }
                }
                else if (noOfMonths === 4) {
                    months.push({ type: 'COMPLETE', date1: month2.toLocaleDateString() })
                    if (quarEDate.getDate() <= 2) {
                        months.push({ type: 'MERGETOLAST', date1: month3.toLocaleDateString(), date2: quarterEndDate })
                    }
                }
                if (quarEDate.getDate() > daysAvailable) {
                    months.push({ type: 'FIRST', date1: quarterEndDate })
                }
            }
        }
    }

    if (quarters.length > 0) {
        quarters.forEach(q => {
            if (q.quarter === 'One') {
                quarter1Info = getShortMonthNameInDate(q.quarterStartDate, q.quarterEndDate)
                calMonths(q.quarterStartDate, q.quarterEndDate, quarter1Months)
            }
            else if (q.quarter === 'Two') {
                quarter2Info = getShortMonthNameInDate(q.quarterStartDate, q.quarterEndDate)
                calMonths(q.quarterStartDate, q.quarterEndDate, quarter2Months)
            }
            else if (q.quarter === 'Three') {
                quarter3Info = getShortMonthNameInDate(q.quarterStartDate, q.quarterEndDate)
                calMonths(q.quarterStartDate, q.quarterEndDate, quarter3Months)
            }
            else {
                quarter4Info = getShortMonthNameInDate(q.quarterStartDate, q.quarterEndDate)
                calMonths(q.quarterStartDate, q.quarterEndDate, quarter4Months)
            }
        })
    }

    const getDaysOfMonth = (date: string) => {
        const days = moment(new Date(date)).daysInMonth()
        const startDayOfWeek = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1).getDay()
        const allDays = Array(days + startDayOfWeek)
            .fill(null)
            .map((_, index) => {
                const d = index - (startDayOfWeek - 1)
                return d
            })
        return allDays
    }

    const calDaysForCompleteType = (date: string) => {
        const toDate = new Date(date)
        const days = getDaysOfMonth(date)
        const eDate = new Date(endDate).toLocaleDateString()

        return (
            days.map((d, index) => {
                const isStartDate = d === toDate.getDate() && date === startDateToLocale
                const isEndDate = d === toDate.getDate() && date === eDate
                return (
                    <li
                        style={isStartDate || isEndDate ? { border: "1px solid black", borderRadius: "20px" } : {}}
                        key={`${index}+${d}+${new Date().getMilliseconds}`}>{d > 0 ? d : ''}</li>
                )
            })
        )
    }

    const calDaysForMergeToFirstType = (d1: string, d2: string) => {
        const date1 = new Date(d1)
        const date2 = new Date(d2)
        const lastDayOfD1 = new Date(date1.getFullYear(), date1.getMonth() + 1, 0)
        const lastDayOfD2 = new Date(date2.getFullYear(), date2.getMonth() + 1, 0)
        const blanks = [] as { d: number | string, date: string }[]
        const days = [] as { d: number | string, date: string }[]

        for (let i = 1; i <= date1.getDay(); i++) {
            blanks.push({ d: '', date: '' })
        }
        for (let i = date1.getDate(); i <= lastDayOfD1.getDate(); i++) {
            days.push({ d: i, date: date1.toLocaleDateString() })
        }
        for (let i = date2.getDate(); i <= lastDayOfD2.getDate(); i++) {
            days.push({ d: i, date: date2.toLocaleDateString() })
        }
        const allDays = [...blanks, ...days]
        return (
            allDays.map((allD, index) => {
                let isStartDate = allD.d === startDate.getDate() && allD.date === startDateToLocale
                return (
                    <li
                        style={isStartDate ? { border: "1px solid black", borderRadius: "20px" } : {}}
                        key={`${index}+${allD.d}+${new Date().getMilliseconds}`}>{allD.d > 0 ? allD.d : ''}</li>
                )
            })
        )
    }

    const calDaysForMergeToLastType = (d1: string, d2: string) => {
        const date1 = new Date(d1)
        const date2 = new Date(d2)
        const startDayOfD1 = new Date(date1.getFullYear(), date1.getMonth(), 1)
        const lastDayOfD1 = new Date(date1.getFullYear(), date1.getMonth() + 1, 0)
        const blanks = [] as { d: number | string, date: string }[]
        const days = [] as { d: number | string, date: string }[]

        for (let i = 1; i <= startDayOfD1.getDay(); i++) {
            blanks.push({ d: '', date: '' })
        }
        for (let i = 1; i <= lastDayOfD1.getDate(); i++) {
            days.push({ d: i, date: date1.toLocaleDateString() })
        }
        for (let i = 1; i <= date2.getDate(); i++) {
            days.push({ d: i, date: date2.toLocaleDateString() })
        }

        const allDays = [...blanks, ...days]

        return (
            allDays.map((allD, index) => {
                let isEndDate = allD.d === date2.getDate() && allD.date === endDate.toLocaleDateString()
                return (
                    <li
                        style={isEndDate ? { border: "1px solid black", borderRadius: "20px" } : {}}
                        key={`${index}+${allD.d}+${new Date().getMilliseconds}`}>{allD.d > 0 ? allD.d : ''}</li>
                )
            })
        )
    }

    const calDaysForFirstType = (date: string) => {
        const startDate = new Date(date)
        const beginDayStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
        const blanks = []
        const partialDays = []
        const eDate = new Date(endDate).toLocaleDateString()
        let days = []
        for (var i = 1; i <= beginDayStartDate.getDay(); i++) {
            blanks.push('')
        }
        for (var j = 1; j <= startDate.getDate(); j++) {
            partialDays.push(j)
        }
        days = [...blanks, ...partialDays]
        return (
            days.map((d, index) => {
                const isEndDate = d === startDate.getDate() && date === eDate
                return (
                    <li
                        style={isEndDate ? { border: "1px solid black", borderRadius: "20px" } : {}}
                        key={`${index}+${d}+${new Date().getMilliseconds}`}>{d > 0 ? d : ''}</li>
                )
            })
        )
    }

    const calDaysForLastType = (date: string) => {
        const startDate = new Date(date)
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
        const weekDayOfStartDate = startDate.getDay()
        const blanks = []
        const partialDays = []
        let days = []
        for (var i = 1; i <= weekDayOfStartDate; i++) {
            blanks.push('')
        }
        for (var j = startDate.getDate(); j <= endDate.getDate(); j++) {
            partialDays.push(j)
        }
        days = [...blanks, ...partialDays]
        return (
            days.map((d, index) => {
                const isStartDate = d === startDate.getDate() && date === startDateToLocale
                return (
                    <li
                        style={isStartDate ? { border: "1px solid black", borderRadius: "20px" } : {}}
                        key={`${index}+${d}+${new Date().getMilliseconds}`}>{d > 0 ? d : ''}</li>
                )
            })
        )
    }

    const DisplayDays: FC<{ quarter: TQuarter }> = (props) => {
        let type: TDisplayType, date1: string, date2: string

        type = props.quarter.type
        date1 = props.quarter.date1

        if (type === 'MERGETOFIRST' || type === "MERGETOLAST") {
            date2 = props.quarter.date2!
        }

        const month = type !== 'MERGETOFIRST' ? new Date(date1).getMonth() : new Date(date2!).getMonth()

        return (
            <div className={styles.custom} >
                <p>{months[month]}</p>
                <hr />
                <ul>
                    {DAYS_OF_THE_WEEK.map((d) => {
                        return (
                            <li className={styles.weekDayCustom} key={d}>{d}</li>
                        )
                    })}
                    {type === "COMPLETE" ? calDaysForCompleteType(date1) : null}
                    {type === "FIRST" ? calDaysForFirstType(date1) : null}
                    {type === "LAST" ? calDaysForLastType(date1) : null}
                    {type === 'MERGETOFIRST' ? calDaysForMergeToFirstType(date1, date2!) : null}
                    {type === 'MERGETOLAST' ? calDaysForMergeToLastType(date1, date2!) : null}
                </ul>
            </div >
        )
    }

    return (
        <>
            <div className={styles.block}>
                <div className={styles.title} id="fiscalYear">
                    {`FY${fiscalYear} - Annual Calendar`}
                </div>
            </div>
            <div className={styles.quarters}>
                <div className={styles.row}>
                    <div className={styles.col} id="quarterOne">
                        <div>
                            <div className={styles.quarterTitle}>
                                <p id='q1Title'>{quarter1Info}</p>
                            </div>
                            <div className={styles.quarterName}>
                                <p>Q1</p>
                            </div>
                        </div>
                        {quarter1Months.map(q => {
                            return <DisplayDays quarter={q} key={q.date1} />
                        })}
                    </div>
                    <span className={styles.verticalLineCustom} />
                    <div className={styles.col} id="quarterTwo">
                        <div>
                            <div className={styles.quarterTitle}>
                                <p id='q2Title'>{quarter2Info}</p>
                            </div>
                            <div className={styles.quarterName}>
                                <p>Q2</p>
                            </div>
                        </div>
                        {quarter2Months.map(q => {
                            return <DisplayDays quarter={q} key={q.date1} />
                        })}
                    </div>
                    <span className={styles.verticalLineCustom} />
                    <div className={styles.col} id="quarterThree">
                        <div>
                            <div className={styles.quarterTitle}>
                                <p id='q3Title'>{quarter3Info}</p>
                            </div>
                            <div className={styles.quarterName}>
                                <p>Q3</p>
                            </div>
                        </div>
                        {quarter3Months.map(q => {
                            return <DisplayDays quarter={q} key={q.date1} />
                        })}
                    </div>
                    <span className={styles.verticalLineCustom} />
                    <div className={styles.col} id="quarterFour">
                        <div>
                            <div className={styles.quarterTitle}>
                                <p id='q4Title'>{quarter4Info}</p>
                            </div>
                            <div className={styles.quarterName}>
                                <p>Q4</p>
                            </div>
                        </div>
                        {quarter4Months.map(q => {
                            return <DisplayDays quarter={q} key={q.date1} />
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}