import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from "../../Api/useAxios"
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { ICheckBoxState } from './ResetDashboardTabView'
import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../Api/api'

export interface IDashboardResetData {
  correlationId: string,
  dashboardId: number,
  dashboardName: string,
  status: string,
  lastRequestedBy: string,
  lastRequested: string,
  lastRequestedReason: string
}

export interface ICollection {
  collectionName: string,
  dashboards: IDashboardResetData[],
}

export interface IProduct {
  productName: string,
  collections: ICollection[],
}

type TUseTemplateData = [
  {
    templateData: IProduct[],
    loading: boolean,
    status?: string | number,
    errors: any[],
  }
]

export const UseTemplateData = (): TUseTemplateData => {
const getTemplateDashboardsUrl = `${buildServerApiUrl("Dashboard.GetTemplateDashboards", "v1").toString()}/${getCustomerKey()}`

  const [{ data, loading, errors, status }] = useAxiosGet(
    getTemplateDashboardsUrl
  )

  let templateData: IProduct[] = []

  if (data) {
    templateData = data
  }
  return [{ templateData, loading, status, errors }]
}

interface IResetDashboardV2Payload {
  dashboardIdsByProducts: ICheckBoxState<Array<number>>,
  reason: string,
  customerKey: string,
}

type TResetPayloadV2 = Omit<IResetDashboardV2Payload, "customerKey">

type TUseResetPayloadV2 = [
  (payload: TResetPayloadV2) => Promise<AxiosResponse<any> | undefined>,
  {
    data: any,
    posting: boolean,
    status?: string | number,
    errors: any[],
  }
]

const resetDashboardsV2Url = buildServerApiUrl("Dashboard.ResetDashboards", "v2").toString()
export const useResetDashboardsV2 = (options?: IUseAxiosOptions<IResetDashboardV2Payload>): TUseResetPayloadV2 => {

  const [execute, { posting, status, data, errors }] = useAxiosPost<IResetDashboardV2Payload>(
    resetDashboardsV2Url, {}, options
  )

  const resetV2 = async (payload: TResetPayloadV2) => {
    const res = await execute({ customerKey:getCustomerKey(), ...payload })
    return res
  }

  return [resetV2, { posting, status, data, errors }]
}

export const getTemplateDashboards = async (): Promise<IProduct[] | string> => {
  const getTemplateDashboardsUrl = `${buildServerApiUrl("Dashboard.GetTemplateDashboards", "v1").toString()}/${getCustomerKey()}`

  let templateData: IProduct[] = []
  const response = await axiosInstance.get(getTemplateDashboardsUrl)
  if (!!response) {
    templateData = response.data.data
  }
  else {
    return 'There was a bad response from the server'
  }
  return templateData
}