import React, { FC } from 'react'
import { IQuarters } from './FiscalCalendarApi'
import { FORMAT } from '../../ts/enums/calendarFormat'
import { PreviewAnnualCalendar } from './PreviewAnnual'
import { PreviewRetailCalendar } from './PreviewRetail'

export const DAYS_OF_THE_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

interface IProps {
    fiscalYear: number,
    quarters: IQuarters[],
    fiscalFormat: string | undefined,
    startDateToLocale: string,
    endDateToLocale: string
}

export const FiscalQuarters: FC<IProps> = (props) => {

    const { quarters, fiscalYear, fiscalFormat, startDateToLocale, endDateToLocale } = props

    return (
        fiscalFormat === FORMAT.ANNUALCALENDAR ?
            <PreviewAnnualCalendar {...{ quarters, startDateToLocale, endDateToLocale, fiscalYear }} />
            : <PreviewRetailCalendar {...{ quarters, fiscalYear, fiscalFormat }} />
    )
}