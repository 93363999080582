import React, { FC, useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { Button, ButtonRow, FormikOption, FormikSelect } from 'navex-react'
import { FormikDatePicker } from 'navex-react'
import { Label } from 'reactstrap'
import { Toasts, ToastId } from "navex-react"
import { object } from 'yup'
import { useUpsertFiscalCalendar } from './FiscalCalendarApi'
import { RadioButton } from 'navex-react'
import styles from "./FiscalCalendar.module.scss"
import { Custom, Retail } from './ValidationShape'
import { FORMAT } from '../../ts/enums/calendarFormat'
import moment from 'moment'
import {
    addDays, createDateFromMonthIndexAndDay,
    getDate, getEndDateOfAMonth, getMonth, months
} from '../../Utils/fiscalCalendarUtils'
import './FiscalCalendar.css'

interface IProps {
    isEdit: boolean,
    setIsEdit: (isEdit: boolean) => void,
    reFetch: (silentFetch: boolean) => void,
    oldFiscalYear: number,
    setOldFiscalYear: (year: number) => void
    fiscalFormat: string,
    setFiscalFormat: (format: string) => void,
    setIsPreview: (isPreview: boolean) => void,
    startDate: string | undefined,
    setStartDate: (date: string | undefined) => void,
    endDate: string | undefined,
    setEndDate: (date: string | undefined) => void,
    fiscalMonth: string,
    setFiscalMonth: (month: string) => void
    newFiscalYear: number,
    setNewFiscalYear: (year: number) => void,
    setStartPoll: (status: boolean) => void,
    toggle: boolean
}

type TDate = Date | string | undefined | null

export const isLeapYear = (year: number) => {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)
}

export const FiscalCalendarDetailsView: FC<IProps> = (props) => {

    const { setIsEdit, reFetch, oldFiscalYear, setFiscalFormat, fiscalFormat, setIsPreview,
        startDate, setStartDate, endDate, setEndDate, fiscalMonth, setFiscalMonth, setNewFiscalYear,
        newFiscalYear, setOldFiscalYear, setStartPoll } = props

    const yearFormatOptions = [
        { label: "Annual Calendar", value: "Annual Calendar", defaultChecked: true },
        { label: "4-5-4 Calendar", value: "4-5-4", defaultChecked: false },
        { label: "4-4-5 Calendar", value: "4-4-5", defaultChecked: false },
        { label: "5-4-4 Calendar", value: "5-4-4", defaultChecked: false }
    ]

    const defaultYearFormat = yearFormatOptions.filter(yfo => yfo.defaultChecked)[0]

    const [errorMessage, setErrorMessage] = useState("")

    const [retailFormatEndDate, setRetailFormatEndDate] = useState<{ 'Days364': Date, 'Days371': Date }>()

    const [excludeDates, setExludeDates] = useState([] as Date[])

    const [eligibleMonths, setEligibleMonths] = useState<string[]>([])

    const currentYear = new Date().getFullYear()

    const yearOptions = [...[4, 3, 2, 1].map(n => { return currentYear - n }), currentYear,
    ...Array.from({ length: 8 }, (_, k) => k + 1 + currentYear)]

    let toastId: ToastId = 0

    const [upsertFiscalCalendar] = useUpsertFiscalCalendar(props.toggle,{
        onSuccess: () => {
            setErrorMessage("")
            setIsEdit(false)
            setStartDate(undefined)
            setEndDate(undefined)
            setFiscalMonth('')
            setOldFiscalYear(0)
            setNewFiscalYear(0)
            setFiscalFormat(defaultYearFormat.value)
            reFetch(false)
            setStartPoll(true)
        },
        onError: (errors) => {
            showErrorToastMessage(errors)
        }
    })

    const showErrorToastMessage = (errors: any) => {
        if (errors && errors[0].response) {
            if (errors[0].response.status === 400 && errors[0].response.data && errors[0].response.data.errors.length > 0) {
                setErrorMessage(errors[0].response.data.errors[0].message)
            }
            else {
                if (!Toasts.isActive(toastId)) {
                    toastId = Toasts.alert("Something went wrong. Please try again later.")
                    setErrorMessage('')
                }
            }
        }
        else {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("Something went wrong. Please try again later.")
            }
        }
    }

    const handleCancelBtnClick = () => {
        setIsEdit(false)
        setStartDate(undefined)
        setEndDate(undefined)
        setFiscalMonth('')
        setOldFiscalYear(0)
        setNewFiscalYear(0)
        setFiscalFormat(defaultYearFormat.value)
    }

    const getTotalDays = (startDate: TDate, endDate: TDate) => {
        if ((startDate !== undefined || startDate !== null) && fiscalFormat === FORMAT.ANNUALCALENDAR) {
            if (startDate instanceof Date) {
                const endDateFromStartDate = moment(new Date(startDate)).add(1, 'years').toDate()
                const diffInMs = Math.abs(endDateFromStartDate.getTime() - startDate.getTime())
                const diffInDays = diffInMs / (24 * 60 * 60 * 1000)
                const isLeapYr = isLeapYear(startDate.getFullYear())
                if (isLeapYr && startDate.getDate() === 29 && startDate.getMonth() === 1) {
                    return diffInDays + 1
                }
                return diffInDays
            }
        }
        else if ((startDate === undefined || startDate === null || endDate === undefined || endDate === null)
            && fiscalFormat !== FORMAT.ANNUALCALENDAR) {
            return undefined
        }
        else {
            if (startDate instanceof Date && endDate instanceof Date) {
                const diffInMs = Math.abs(endDate.getTime() - startDate.getTime())
                const diffInDays = diffInMs / (24 * 60 * 60 * 1000)
                return diffInDays + 1
            }
        }
    }

    const handleYearFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFiscalFormat(event.target.value)
        if (errorMessage != null) {
            setErrorMessage('')
        }
    }

    const handlePreviewBtnClick = () => {
        setIsEdit(true)
        setIsPreview(true)
    }

    const disablePreviewLink = (startDate: TDate, endDate: TDate, fiscalYear: number) => {
        if (fiscalFormat !== FORMAT.ANNUALCALENDAR) {
            if (getTotalDays(startDate, endDate) === 364) return false
            else if (fiscalYear === 0) return true
            else if (getTotalDays(startDate, endDate) === 371) return false
            else return true
        }
        else {
            if (startDate === undefined || startDate === null) return true
            else if (fiscalYear === 0) return true
            else return false
        }
    }

    yearFormatOptions.forEach(yfo => {
        if (yfo.value === fiscalFormat) { yfo.defaultChecked = true }
        else { yfo.defaultChecked = false }
    })

    useEffect(function calCulateEligibleEndDatesForRetailFormat() {
        if (startDate !== undefined) {
            setRetailFormatEndDate({
                'Days364': addDays(new Date(startDate).toLocaleDateString(), 363),
                'Days371': addDays(new Date(startDate).toLocaleDateString(), 370)
            })
        }
    }, [startDate])

    useEffect(function calCulateDatesToBeExludedApartFromEligibleDates() {
        if (retailFormatEndDate !== undefined && retailFormatEndDate.Days364 !== undefined
            && retailFormatEndDate.Days371 !== undefined) {
            const dates = getExludedDates(retailFormatEndDate.Days364, retailFormatEndDate.Days371)
            setExludeDates([...dates])
        }

    }, [retailFormatEndDate])

    useEffect(function calEligibleMonthsFromStartDate() {
        if (startDate !== undefined) {
            const sDate = new Date(startDate)
            const validMonths = [1, 2].map((n) => { return months[(sDate.getMonth() + n) % 12] })
            setEligibleMonths([months[sDate.getMonth()], ...validMonths])
        }
    }, [startDate])

    const getExludedDates = (Days364: Date, Days371: Date) => {
        const dates = []
        // Both end dates belong to same month
        if (getMonth(Days364) === getMonth(Days371)) {
            for (let i = getDate(Days364) + 1; i <= getDate(Days371) - 1; i++) {
                const date = createDateFromMonthIndexAndDay(Days364, i)
                dates.push(date)
            }
        }
        // End dates belong to different month
        else {
            for (let i = getDate(Days364) + 1; i <= getEndDateOfAMonth(Days364); i++) {
                const date = createDateFromMonthIndexAndDay(Days364, i)
                dates.push(date)
            }
            for (let i = Days371.getDate() - 1; i >= 1; i--) {
                const date = createDateFromMonthIndexAndDay(Days371, i)
                dates.push(date)
            }
        }
        return dates
    }

    const validationShape = fiscalFormat === FORMAT.ANNUALCALENDAR ? Custom : Retail

    return (
        <Formik
            initialValues={
                {
                    startDate: startDate !== undefined ? new Date(startDate) : undefined,
                    endDate: endDate !== undefined ? new Date(endDate) : undefined,
                    // Do not initialize to undefined
                    startMonthOfYear: fiscalMonth,
                    fiscalYear: newFiscalYear
                }
            }

            onSubmit={(values, actions) => {
                const startDateToLocale = new Date(values.startDate!).toLocaleDateString('en-US').replace(/\u200e/g, '')
                const endDateToLocale = new Date(values.endDate!).toLocaleDateString('en-US').replace(/\u200e/g, '')
                const fiscalMonth = months.indexOf(values.startMonthOfYear) + 1
                const newFiscalYear1 = Number(values.fiscalYear)

                upsertFiscalCalendar({
                    beginDate: startDateToLocale,
                    endDate: endDateToLocale,
                    fiscalFormat: fiscalFormat,
                    firstMonth: isNaN(fiscalMonth) ? 0 : fiscalMonth,
                    fiscalYear: newFiscalYear1,
                    oldFiscalYear: oldFiscalYear === 0 ? 0 : oldFiscalYear,
                })

                actions.setSubmitting(false)
            }}

            validationSchema={object().shape(validationShape)}

        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form autoComplete="off" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <div className="form-control-feedback-group">
                        <div className="col-12 col-sm-6">
                            <Field
                                id="fieldFiscalYear"
                                name="fiscalYear"
                                label="Fiscal Year"
                                required
                                title={newFiscalYear === 0 ? 'Select Year' : newFiscalYear}
                                component={FormikSelect}
                                aria-haspopup={false}
                                maxMenuHeight="160px"
                                onChange={(value: any) => { setNewFiscalYear(value); setFieldValue('fiscalYear', value) }}
                            >
                                {yearOptions.map(yo => {
                                    return <FormikOption value={yo} key={yo}>{yo}</FormikOption>
                                })}
                            </Field>


                            <Label id="fiscalYearFormatLabel" className="control-label" htmlFor="yearFormat">Fiscal Year format</Label>
                            <RadioButton
                                id="yearFormat"
                                items={yearFormatOptions}
                                onChange={handleYearFormatChange}
                                style={{ marginBottom: "20px" }}
                            />

                            <Field
                                id="fieldStartDate"
                                name="startDate"
                                label="Start Date of Fiscal Year"
                                required
                                shouldCloseOnSelect
                                component={FormikDatePicker}
                                openToDate={
                                    values.startDate !== undefined ? new Date(values.startDate) :
                                        values.fiscalYear !== 0
                                            ? new Date(values.fiscalYear, new Date(values.fiscalYear).getMonth())
                                            : new Date()}
                                onChange={(value: any) => {
                                    setStartDate(value)
                                    setFieldValue('startDate', value)
                                    setFieldValue('startMonthOfYear', months[new Date(value).getMonth()])
                                    setFiscalMonth(months[new Date(value).getMonth()])
                                }}
                            />

                            {fiscalFormat === FORMAT.ANNUALCALENDAR ? null :
                                <>
                                    <Field
                                        id="fieldStartMonthOfYear"
                                        name="startMonthOfYear"
                                        label="Start Month of Fiscal Year"
                                        required
                                        title={fiscalMonth === '' ? 'Select Start Month' : fiscalMonth}
                                        component={FormikSelect}
                                        onChange={(value: any) => {
                                            setFiscalMonth(value)
                                            setFieldValue('startMonthOfYear', value)
                                        }}
                                    >
                                        {eligibleMonths.map(em => {
                                            return <FormikOption value={em} key={em}>{em}</FormikOption>
                                        })}
                                    </Field>

                                    <Field
                                        id="fieldEndDate"
                                        name="endDate"
                                        label="End Date of Fiscal Year"
                                        required
                                        component={FormikDatePicker}
                                        minDate={retailFormatEndDate?.Days364}
                                        maxDate={retailFormatEndDate?.Days371}
                                        excludeDates={excludeDates}
                                        dayClassName={() => values.startDate !== undefined ? 'react-datepicker__day--custom' : 'no css attached'}
                                        openToDate={retailFormatEndDate?.Days364}
                                        onChange={(value: any) => { setEndDate(value); setFieldValue('endDate', value) }}
                                    />
                                </>
                            }

                            <p id="totalDays" className={styles.totalDays}>Total number of days in this year : <b>{getTotalDays(values.startDate!, values.endDate!)}</b></p>

                            <Button id="btnPreviewFiscalYear" onClick={handlePreviewBtnClick} className={styles.previewBtn} purpose="link"
                                disabled={disablePreviewLink(values.startDate, values.endDate, values.fiscalYear)
                                }
                            >Preview Fiscal Year</Button>
                            <br />
                            <label id="errorMessage" style={{ color: "red" }} hidden={!errorMessage.length}>
                                <span>{" * "}{errorMessage}</span>
                            </label>
                            <ButtonRow alignment="right">
                                <Button
                                    id="btnCancelFiscalYear"
                                    purpose="default"
                                    disabled={isSubmitting}
                                    onClick={handleCancelBtnClick}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="btnSaveFiscalYear"
                                    purpose="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </ButtonRow>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}