import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { DefaultPage } from "navex-react"


export enum ERRORS { DEFAULT_ERROR, UNAUTHORIZED, DISABLED }

export const UnexpectedError = (props: { errorKey?: number }) => {
    let errorMessage = ""

    switch (props.errorKey) {
        case ERRORS.DISABLED:
            errorMessage = "Please contact your Gateway Administrator."
            break
        case ERRORS.UNAUTHORIZED:
        case ERRORS.DEFAULT_ERROR:
        default:
            errorMessage = "An error occurred processing your request. Please try again."
            break

    }

    return (<DefaultPage>
        <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#e3094c', fontSize: "20px" }} />
        <h2 id='unexpectedError' className="text-danger" style={{ marginLeft: '15px', display: 'inline' }}>
            {errorMessage}</h2>

    </DefaultPage>)
}

export const NoDataError = () => (
    <span id='noDataMessage'>No data was returned.</span>
)

export const NoPersmissionError = () => {
    return (
        <DefaultPage>
            <div id="NoPermission">You don't have permission to see this screen.</div>
        </DefaultPage>
    )
}
