import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ActionIcon, ButtonRow } from 'navex-react'
import React, { FC } from 'react'
import { NoDataError } from '../Shared/Error'
import { IFiscalCalendarData } from './FiscalCalendarApi'
import { FiscalCalendarListItem } from './FiscalCalendarListItem'

interface IProps {
    fiscalCalendarItems: IFiscalCalendarData[],
    isEdit: boolean,
    reFetch: (silentFetch: boolean) => void,
    setIsEdit: (isEdit: boolean) => void,
    setOldFiscalYear: (fiscalYear: number) => void,
    setFiscalFormat: (format: string) => void,
    setStartDate: (date: string | undefined) => void,
    setEndDate: (date: string | undefined) => void,
    setFiscalMonth: (month: string) => void,
    setNewFiscalYear: (year: number) => void,
    handleAddFiscalYearBtnClick: () => void,
    setShowModal: (status: boolean) => void,
    setDeleteFiscalYear: (fiscalYear: number) => void,
    startPoll: boolean,
    setStartPoll: (status: boolean) => void,
    checkEligibleForPolling: () => boolean,
    toggle:boolean
}

export const FiscalCalendarListView: FC<IProps> = (props) => {

    const { setOldFiscalYear, setFiscalFormat, setStartDate, setEndDate, setFiscalMonth,
        setNewFiscalYear, setShowModal, setDeleteFiscalYear, startPoll, checkEligibleForPolling,
        reFetch, setStartPoll,toggle} = props

    const fiscalCalendarItems = props.fiscalCalendarItems.map(fci => {
        return <FiscalCalendarListItem fiscalCalendarItem={fci} setIsEdit={props.setIsEdit} {...{
            setOldFiscalYear, setFiscalFormat, setStartDate, setEndDate, setFiscalMonth, setNewFiscalYear, setShowModal, setDeleteFiscalYear, startPoll, checkEligibleForPolling,
            reFetch, setStartPoll,toggle
        }} key={fci.fiscalYear} />
    })

    return (
        <table id="fiscal-calendar" className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th scope="col">Fiscal Year</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">History</th>
                    <th scope="col">Action</th>
                    <th scope="col">
                        <ButtonRow>
                            <div>
                                <ActionIcon id='btnAddFiscalYear' icon={faPlus} toolTip={'Add Fiscal Year'}
                                    onClick={props.handleAddFiscalYearBtnClick} disabled={startPoll && checkEligibleForPolling() ? true : false}
                                />
                            </div>
                        </ButtonRow>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    fiscalCalendarItems.length > 0
                        ?
                        fiscalCalendarItems
                        :
                        <tr><td colSpan={7}><NoDataError /></td></tr>
                }
            </tbody>
        </table>
    )
}