import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { IUseAxiosOptions, useAxiosGet, useAxiosPost } from '../../Api/useAxios'

export interface IGetCustomFormula {
    formula: string,
    name: string,
    id: number,
    format:string,
}

export interface IUpsertCustomFormulaPayload {
    customerKey: string,
    name: string,
    formula: string,
    id?: number,
    datasetId?: number,
    format:string
}

export interface IDeleteCustomFormulaPayload {
    customerKey: string,
    id: number
}

type TUseGetCustomFormula = [
    {
        customFormulaInfo: IGetCustomFormula[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    () => Promise<AxiosResponse<any> | undefined>
]


export const useGetCustomFormulaInfo = (): TUseGetCustomFormula => {

const getCustomFormulaUrlPath = `${buildServerApiUrl("Dataset.GetCustomFormula").toString()}/${getCustomerKey()}`

    const [{ data, loading, errors, status }, reFetchData] = useAxiosGet(
        getCustomFormulaUrlPath
    )
    let customFormulaInfo: IGetCustomFormula[] = []
    if (data) {
        customFormulaInfo = data
    }
    return [{ customFormulaInfo, loading, status, errors }, reFetchData]
}

export type IUpsertCustomFormula = Omit<IUpsertCustomFormulaPayload, "customerKey">

type TUseUpsertCustomFormula = [
    (payload: IUpsertCustomFormula) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const upsertCustomFormulaUrl = `${buildServerApiUrl("Dataset.UpsertCustomFormula").toString()}`
export const useUpsertCustomFormula = (
    options?: IUseAxiosOptions<IUpsertCustomFormulaPayload>
): TUseUpsertCustomFormula => {


    const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertCustomFormulaPayload>(
        upsertCustomFormulaUrl, {}, options
    )

    const upsertCustomFormula = async (payload: IUpsertCustomFormula) => {
        const res = await upsert({ ...payload, customerKey:getCustomerKey() })
        return res
    }

    return [upsertCustomFormula, { posting, status, errors }]
}

export type IDeleteCustomFormula = Omit<IDeleteCustomFormulaPayload, "customerKey">

type TUseDeleteCustomFormula = [
    (payload: IDeleteCustomFormula) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const deleteCustomFormulaUrl = `${buildServerApiUrl("Dataset.DeleteCustomFormula").toString()}`
export const useDeleteCustomFormula = (
    options?: IUseAxiosOptions<IDeleteCustomFormulaPayload>
): TUseDeleteCustomFormula => {


    const [execute, { posting, status, errors }] = useAxiosPost<IDeleteCustomFormulaPayload>(
        deleteCustomFormulaUrl, {}, options
    )

    const deleteCustomFormula = async (payload: IDeleteCustomFormula) => {
        const res = await execute({ ...payload, customerKey:getCustomerKey() })
        return res
    }

    return [deleteCustomFormula, { posting, status, errors }]
}
