/* eslint-disable */
import React, { FC, useEffect, useState } from 'react'
import { Spinner } from '../Shared/Spinner'
import { createDashboard, ICreateDashboardProps } from '../BirstEmbeddedDashboard/createDashboard'
import { IProduct } from './ViewDashboardApi'
import { getDashboardsFromEditPath, IBirstFilter, ISetFiltersEventData, buildQueryFromBirstFiltersArray, getFiltersFromQueryParams, IDrillAcrossEventData } from '../BirstEmbeddedDashboard/birstUtils'
import { useGetBirstUrl } from '../BirstEmbeddedDashboard/BirstEmbeddedDashboardApi'
import { computeEditDashboardRoutePath } from './DashboardViewRow'
import { connect } from "react-redux"
import { InsightsActionCreators } from '../../Reducers/InsightsReducer'
import { pathPrefix } from '../../Api/urls'

interface IProps {
    data: IProduct[],
    toggles: string[],
}

interface IPropsThatDispatch {
    setCurrentPage: (value: string) => void
}

type props = IProps & IPropsThatDispatch 

const handleDrillAcrossEventData = (drillAcross: IDrillAcrossEventData, productNameFromUrl: string) => {
    document.title =  "NAVEX One - " + drillAcross.page
    window.history.pushState("", drillAcross.page, `${pathPrefix}${computeEditDashboardRoutePath(productNameFromUrl, drillAcross.dashboard, drillAcross.page)}`)
}

const handleSetFiltersEventData = ({ filters }: ISetFiltersEventData) => {
    const filtersQuery = buildQueryFromBirstFiltersArray(filters)
    const titlePrefix =  "NAVEX One - " 
    const pageName = document.title.replace(titlePrefix, "")
    window.history.pushState("", pageName, `${window.location.pathname}?filters=${filtersQuery}`)
}

const removePositionAttr = () => {
    const element = document.getElementById('BirstDash')
    if (element) {
        element.style.removeProperty('position')
    }
}

export const BirstEmbeddedEditDashboardUnwrappedForTesting: FC<props> = (props: props) => {
    const [iFrameLoading, setIframeLoading] = useState(false)
    const [dashboardNameFromUrl, setDashboardNameFromUrl] = useState('' as string)
    const [collectionNameFromUrl, setcollectionNameFromUrl] = useState('' as string)
    const [productNameFromUrl, setProductNameFromUrl] = useState('' as string)

    const [getBirstUrl, { birstUrlLoading, birstUrl, errors }] = useGetBirstUrl()

    const hasError = errors.length

    let product: IProduct;
    const BirstMessageHandler = ({ operation, drillAcross, filters }: {
        operation?: string, drillAcross?: IDrillAcrossEventData, filters?: IBirstFilter[]
    }) => {
        switch (operation) {
            case "initialized":
                setIframeLoading(false)
                removePositionAttr()
                break
            case "drillToDashboard":
                if (drillAcross) {
                    handleDrillAcrossEventData(drillAcross, productNameFromUrl)
                    props.setCurrentPage(drillAcross.page)
                }
                break
            case "setFilters":
                if (filters) {
                    handleSetFiltersEventData({ filters })
                }
                break
            case "iFrameLoaded":
                removePositionAttr()
                break
        }
    }
    const init = () => {
        const { productName, collectionName, dashboardName } = getDashboardsFromEditPath(window.location.pathname)
        props.setCurrentPage(decodeURIComponent(dashboardName))
        setProductNameFromUrl(decodeURIComponent(productName))
        setDashboardNameFromUrl(decodeURIComponent(dashboardName))
        setcollectionNameFromUrl(decodeURIComponent(collectionName))
    }

    useEffect(() => {
        product = props.data.filter(collection => collection.productName === productNameFromUrl)[0] || ""

        const spaceId = product.spaceId || ""

        if (spaceId) {
            setIframeLoading(false)
            getBirstUrl({ spaceId, showEditToolbar: true })
        }
    }, [productNameFromUrl])

    useEffect(() => {
        if (!birstUrl && !birstUrlLoading && !hasError) {
            init()
        }
    }, [birstUrl, birstUrlLoading, hasError])

    useEffect(() => {
        if (birstUrl && !iFrameLoading) {
            const filters = getFiltersFromQueryParams(window.location.search)
            const dashboardUrl = new URL(birstUrl)
            const dashboardName = dashboardNameFromUrl
            const collectionName = collectionNameFromUrl
            const options: ICreateDashboardProps = {
                dashboardUrl, dashboardName, collectionName, filters
            }

            setIframeLoading(true)
            createDashboard(options)

            const BirstMessageListener = (event: MessageEvent) => {
                if (dashboardUrl.origin === event.origin) {
                    BirstMessageHandler(event.data)
                }
            }

            window.addEventListener("message", BirstMessageListener, false)

            return function cleanup() {
                window.removeEventListener("message", BirstMessageListener)
            }
        }

    }, [birstUrl])

    const showSpinner = (!hasError && (iFrameLoading || birstUrlLoading))
    const styleDiv = {
        width: '100%',
        height: '100%',
        display: (showSpinner || hasError) ? "none" : undefined,
    }

    let displayMessage = ""

    if (hasError) displayMessage = "Dashboard is currently unavailable for edit. Please refresh and try again."

    return (
        <>
            <div id="BirstDash" style={styleDiv}></div>
            {showSpinner && <Spinner />}
            <div id="displayMessage" style={{ display: hasError ? undefined : "none" }}><span>{displayMessage}</span></div>
        </>
    )
}

export const mapDispatchToProps = (dispatch: any): IPropsThatDispatch => {
    return {
        setCurrentPage: (value: string) => dispatch(InsightsActionCreators.setCurrentPage(value))
    }
}

export const BirstEmbeddedEditDashboard = connect(null, mapDispatchToProps)(BirstEmbeddedEditDashboardUnwrappedForTesting)