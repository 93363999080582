import React, { FC, useState } from 'react'
import { faCheck, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { IGetSubscription, useDeleteSubscription, useUpdateSubscription } from './SubscriptionApi'
import { ActionIcon, Button, IButtonProps, Modal, ToastId, Toasts } from 'navex-react'
import styles from "./Subscription.module.scss"
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
    subscriptionId: number | undefined,
    subscription: IGetSubscription,
    reFetch: () => void,
    setSubscriptionId: (subscriptionId: number | undefined) => void,
    setShowModal: (showModal: boolean) => void,
    leftNavMenu: string[],
    email: string
}

export const SubscriptionListItem: FC<IProps> = (props) => {
    const [showModalDeleteSubscription, setShowModalDeleteSubscription] = useState(false)
    const [showModalRemoveRecipient, setShowModalRemoveRecipient] = useState(false)
    const [showModalAddRecipient, setShowModalAddRecipient] = useState(false)
    const [fieldRequestedForRemove, setFieldRequestedForRemove] = useState("")
    const [okButtonProps, setOkButtonProps] = useState({} as IButtonProps)
    const [ModalText, setModalText] = useState(<p>Modal Text</p>)
    const open = () => setShowModalRemoveRecipient(true)
    const close = () => setShowModalRemoveRecipient(false)
    let reportId = ""
    props.leftNavMenu.map((item: any) => item.map((report: any) => { if (report.displayName === props.subscription.reportName) { reportId += report.reportId } }));
    const recipients = props.subscription.recipients.split(',')

    const handleDelete = async (item: string) => {
        open()
        let buttonProps = {} as IButtonProps
        setFieldRequestedForRemove(item)
        buttonProps.disabled = false
        setOkButtonProps(buttonProps)
        setModalText(
            <>
                Are you sure you wish to remove "{item}"?
                <div className={styles.alignCenter}>
                </div></>)
    }

    let toastId: ToastId = 0
    const [deleteSubscription] = useDeleteSubscription({
        onSuccess: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.success(`Deleted Successfully`)
            }
            props.reFetch()
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const [update] = useUpdateSubscription({
        onSuccess: () => {
            toastId = Toasts.success("Updated Successfully")
            props.reFetch()
            setShowModalRemoveRecipient(false)
            setShowModalAddRecipient(false)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const handleSubscriptionDelete = () => {
        if (props.subscriptionId !== undefined) {
            deleteSubscription({ subscriptionId: props.subscriptionId })
        }
    }

    const handleEditSubscriptionBtnLinkClick = () => {
        props.setSubscriptionId(props.subscriptionId)
        props.setShowModal(true)
    }


    switch (props.subscription.frequency) {
        case 2:
            props.subscription.frequency = "Daily"
            break
        case 3:
            props.subscription.frequency = "Weekly"
            break
        case 4:
            props.subscription.frequency = "Monthly"
            break
    }

    const handleRemoveRecipient = async (item: string) => {
        const removeRecipient = recipients.filter((recipient) => recipient !== item).join(',')
        await update({
            SubscriptionId: props.subscription.subscriptionId,
            ReportId: reportId,
            SubscribeName: props.subscription.subscriptionName,
            RecipientMailId: removeRecipient,
            ReportTypeFormat: props.subscription.reportTypeFormat,
            SubscriptionStartDate: props.subscription.subscriptionStartDate,
            Frequency: props.subscription.frequency,
            Timezone: props.subscription.timezone,
            ReportUrl: window.location.href,
            WeekOfFrequency: props.subscription.frequency === "Weekly" ? props.subscription.weekOfFrequency : null,
            MonthOfFrequency: props.subscription.frequency === "Monthly" ? props.subscription.monthOfFrequency : null,
            author: props.subscription.author
        })
    }

    return (
        <tr key={props.subscriptionId}>
            <td>{props.subscription.subscriptionName}</td>
            <td>{props.subscription.reportName}</td>
            <td>
                {props.subscription.author === props.email ?
                    <div id="dataArea" className={styles.dataArea}>
                        {recipients.map((item) => (
                            <span
                                id={'recipientArea_' + item}
                                style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}
                                className={styles.recipientArea}
                                key={props.subscriptionId}
                            >
                                {item}
                                <ActionIcon
                                    id={item + `_removeIcon`}
                                    toolTip={"Remove recipient"}
                                    icon={faTimes}
                                    onClick={() => handleDelete(item)}
                                />
                            </span>
                        ))}
                        <span id="AddRecipients" style={{ paddingLeft: "5px" }}>
                            {showModalAddRecipient ? <Formik
                                validateOnChange={true}
                                validateOnBlur={true}
                                initialValues={
                                    {
                                        RecipientMailId: ""
                                    }}
                                onSubmit={async (values) => {
                                    let addRecipient = props.subscription.recipients + ',' + values.RecipientMailId
                                    await update({
                                        SubscriptionId: props.subscription.subscriptionId,
                                        ReportId: reportId,
                                        SubscribeName: props.subscription.subscriptionName,
                                        RecipientMailId: addRecipient,
                                        ReportTypeFormat: props.subscription.reportTypeFormat,
                                        SubscriptionStartDate: props.subscription.subscriptionStartDate,
                                        Frequency: props.subscription.frequency,
                                        Timezone: props.subscription.timezone,
                                        ReportUrl: window.location.href,
                                        WeekOfFrequency: props.subscription.frequency === "Weekly" ? props.subscription.weekOfFrequency : null,
                                        MonthOfFrequency: props.subscription.frequency === "Monthly" ? props.subscription.monthOfFrequency : null,
                                        author: props.subscription.author
                                    })

                                }}
                                validationSchema={Yup.object().shape({
                                    RecipientMailId: Yup.string()
                                        .email("Enter a valid Mail Id")
                                        .required("Enter Mail Id")
                                })} >
                                {({ values, errors, isSubmitting, status, touched }) => (
                                    <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <div className="row">
                                            <div className="form-control-feedback-group" >
                                                <Field
                                                    id={`${props.subscription.subscriptionName}_AddRecipient`}
                                                    name="RecipientMailId"
                                                    className="form-control no-ie-clear field"
                                                    aria-describedby="name-help-block"
                                                    aria-invalid={errors.RecipientMailId && touched.RecipientMailId}
                                                    aria-required="true"
                                                    placeholder="Enter Mail Id"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <Button purpose="primary" style={{ margin: '0px 10px' }}
                                                id={`_submit`}
                                                type="submit">
                                                <FontAwesomeIcon
                                                    icon={faCheck} />
                                            </Button>
                                            <Button purpose="primary" id={`cancelBtn`}
                                                onClick={() => setShowModalAddRecipient(false)}>
                                                <FontAwesomeIcon
                                                    icon={faTimes} />
                                            </Button>
                                        </div>
                                        <label className="control-label" htmlFor="RecipientMailId" style={{ marginBottom: 0 }}>
                                            <span className="validation-required">{" * "}</span>
                                            {errors.RecipientMailId && touched.RecipientMailId ? (<span className="validation-label" id={`_validationError`} aria-hidden="false">{errors.RecipientMailId}</span>) : null}
                                        </label>
                                    </Form>
                                )}
                            </Formik> : <a onClick={() => setShowModalAddRecipient(true)}>Add&nbsp;Recipient(s)</a>}
                        </span>
                    </div> :
                    recipients.includes(props.email) ? props.email : ""}
            </td>
            <td>{props.subscription.frequency}</td>
            <td>{props.subscription.author}</td>
            <td style={{ whiteSpace: "nowrap" }}>
                {props.email === props.subscription.author ?
                    <ActionIcon
                        id={props.subscriptionId + `_editIcon`}
                        toolTip={"Edit subscription"}
                        icon={faPencilAlt}
                        onClick={handleEditSubscriptionBtnLinkClick}
                    /> : null}
                <ActionIcon
                    id={props.subscriptionId + `_deleteIcon`}
                    toolTip={"Delete field"}
                    icon={faTrashAlt}
                    onClick={() => props.email === props.subscription.author ? setShowModalDeleteSubscription(true) : handleDelete(props.email)}
                />
                <Modal
                    id={props.subscriptionId + "_modalDeleteSubscription"}
                    headerText={"Delete Subscription"}
                    show={showModalDeleteSubscription}
                    okButtonText={"Delete"}
                    message={`Are you sure you want to delete ${props.subscription.subscriptionName}?`}
                    onClickedOk={handleSubscriptionDelete}
                    onClickedCancel={() => setShowModalDeleteSubscription(false)}
                />
            </td>
            <td></td>
            <Modal
                id="removeModal"
                show={showModalRemoveRecipient}
                okButtonProps={okButtonProps}
                okButtonText="Remove"
                message={ModalText}
                headerText={props.subscription.subscriptionName}
                onClickedOk={() => handleRemoveRecipient(fieldRequestedForRemove)}
                onClickedCancel={close}
            />
        </tr>

    );
}