import React, { FC, useState } from 'react'
import { ActionIcon, Button, ToastId, Toasts } from 'navex-react'
import './report.css'
import { ButtonSpinner } from '../Shared/Spinner'
import { IReportResponse, callExportResponse, useExportReportId, useExportReport } from './PowerBIEmbeddedDashboardAPI'
import { faFilePowerpoint, faFilePdf } from '@fortawesome/free-regular-svg-icons'

interface IProps {
  reportId: string,
  showText: boolean,
  customerKey: string
  reportName: string,
  setExportSpinner: (arg: boolean) => void,
  forReport: boolean
}

const ExportReport: FC<IProps> = (props: IProps) => {
  let toastId: ToastId = 0
  const [pptxSpinner, exportPPTX] = useState(false)
  const [pdfSpinner, exportPDF] = useState(false)
  const [exportReportId] = useExportReportId({
    onSuccess: (ret, data) => {
      let responseData: IReportResponse = {
        customerKey: props.customerKey,
        format: data !== undefined ? data.format : "",
        reportId: props.reportId,
        responseWithExportId: ret
      }

      const t = setInterval(async () => {
        const res = await callExportResponse(responseData)
        if (res !== undefined && res.status === 'Succeeded') {
          clearInterval(t)
          const response = await exportReport(responseData)
          if (response?.status !== 200 || response.data.errors.length > 0) {
            props.setExportSpinner(false)
            exportPPTX(false)
            exportPDF(false)
          }
          else {
            var link = document.createElement('a')
            link.href = 'data:application/octet-stream;base64,' + response?.data.data.fileContents
            link.download = response?.data.data.fileDownloadName
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
            props.setExportSpinner(false)
            exportPPTX(false)
            exportPDF(false)
          }
        }
        if (res !== undefined)
          responseData.responseWithExportId = res
        else {
          props.setExportSpinner(false)
          exportPPTX(false)
          exportPDF(false)
          clearInterval(t)
        }
      }, window.customFieldPollTimeout)
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred while exporting the report. Please try again later.")
      }
      props.setExportSpinner(false)
      exportPPTX(false)
      exportPDF(false)
    }
  })

  const [exportReport] = useExportReport({
    onSuccess: () => { },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred while exporting the report. Please try again later.")
      }
      props.setExportSpinner(false)
      exportPPTX(false)
      exportPDF(false)
    }
  })

  const handleExportClick = async (customerKey: string, reportId: string, format: string) => {
    if (props.forReport) {
      const bookmark = await window.report.bookmarksManager.capture()
      props.setExportSpinner(true)
      if (format === "PPTX") { exportPPTX(true) }
      else { exportPDF(true) }
      exportReportId({ customerKey: customerKey, reportId: reportId, format: format, bookmark: bookmark })
    }
    else {
      props.setExportSpinner(true)
      if (format === "PPTX") { exportPPTX(true) }
      else { exportPDF(true) }
      exportReportId({ customerKey: customerKey, reportId: reportId, format: format, bookmark: null })
    }
  }

  return (
    props.forReport ?
      <>
        <Button id="powerpointExport" onClick={() => !pptxSpinner && handleExportClick(props.customerKey, props.reportId, "PPTX")} style={pptxSpinner ? { width: "110px", cursor: "default" } : { width: "110px" }}> {pptxSpinner ? <>Exporting  <ButtonSpinner /></> : "Export PPT"}</Button>
        <Button id="pdfExport" onClick={() => !pdfSpinner && handleExportClick(props.customerKey, props.reportId, "PDF")} style={pdfSpinner ? { width: "110px", cursor: "default" } : { width: "110px" }}> {pdfSpinner ? <>Exporting  <ButtonSpinner /></> : "Export PDF"} </Button></>
      :
      <>
       <ActionIcon
          id={`${props.reportId}_pptxExport`}
          icon={faFilePowerpoint}
          toolTip="Powerpoint"
          onClick={() => handleExportClick(props.customerKey, props.reportId, "PPTX")} />
        <ActionIcon
          id={`${props.reportId}_pptExport`}
          icon={faFilePdf}
          toolTip="PDF"
          onClick={() => handleExportClick(props.customerKey, props.reportId, "PDF")} />
      </>
  )
}

export default ExportReport;
