import {
  useAxiosGet,
  IUseAxiosOptions,
  useAxiosPost,
} from "../../Api/useAxios";
import { AxiosResponse } from "axios";
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls";
import { axiosInstance } from "../../Api/api";

export interface ICustomFieldData {
  caseSections: ICaseSection[];
  customerId: number;
  customerKey: string;
  releaseToggles: string[];
  standardFieldNames: string[];
}

export interface ICaseSection {
  name: string;
  isActive: boolean;
  hierarchy: number;
  caseSectionId: number;
  customFieldItems: ICustomFieldItem[];
}

export interface ICustomFieldItem {
  customFieldId: number;
  caseSectionId: number;
  dataTypeId: number;
  dataType: string;
  customFieldName: null | string;
  insightsFieldName: null | string;
  hierarchy: number;
  isActive: boolean;
  rowVersion: null | string;
  isInsightsEligible: boolean;
  isMeasure: boolean | null;
  isAttribute: boolean | null;
  analyzeByDate: boolean | null;
  modifiedBy: string | null;
  modifiedDate: Date;
  actionType: number;
  status: string | null;
  message: string | null;
  correlationId: string | null;
  history: string[];
}

export type TUseCustomFieldData = [
  {
    customFieldData: ICustomFieldData;
    loading: boolean;
    status?: string | number;
    errors: any[];
  },
  () => Promise<AxiosResponse<any> | undefined>
];

export const UseEpimCustomFieldData = (toggle:boolean): TUseCustomFieldData => {
  const getEpimCustomFieldDataUrl =toggle?`${buildServerApiUrl("CustomFieldMapping.Get","v3").toString()}/${getCustomerKey()}`
                                         :`${buildServerApiUrl("CustomFieldMapping.Get","v2").toString()}/${getCustomerKey()}`;
  

  const [{ data, loading, errors, status }, reFetch] = useAxiosGet(
    getEpimCustomFieldDataUrl
  );

  let customFieldData;
  if (data) {
    customFieldData = data;
  }

  return [{ customFieldData, loading, status, errors }, reFetch];
};

export interface ICustomFields {
  actionType: number;
  isMeasure: boolean;
  isAttribute: boolean;
  analyzeByDate: boolean;
  customFieldId: number;
  dataTypeId: number;
  dataType: string;
  customFieldName: string | null;
  insightsFieldName: string;
  hierarchy: number;
  isActive: boolean;
  customerId: number;
  displaySectionId: number;
}
export interface IUpsertCustomFieldPayload {
  customerKey: string;
  customerId: number;
  customFieldMappingList: ICustomFields[];
}

export type IUpsertCustomFields = Omit<
  IUpsertCustomFieldPayload,
  "customerKey"
>;

type TUseUpsertCustomFields = [
  (payload: IUpsertCustomFields) => Promise<AxiosResponse<any> | undefined>,
  {
    posting: boolean;
    status?: string | number;
    errors: any[];
  }
];


export const useUpsertCustomFieldMappings = (toggle:boolean,
  options?: IUseAxiosOptions<IUpsertCustomFieldPayload>
): TUseUpsertCustomFields => {
  const upsertCustomFieldsUrl = toggle?`${buildServerApiUrl("CustomFieldMapping.UpdateMappings","v3").toString()}` 
                                      :`${buildServerApiUrl("CustomFieldMapping.UpdateMappings","v2").toString()}`;
  const [upsert, { posting, status, errors }] =
    useAxiosPost<IUpsertCustomFieldPayload>(upsertCustomFieldsUrl, {}, options);

  const update = async (payload: IUpsertCustomFields) => {
    const res = await upsert({ ...payload, customerKey: getCustomerKey() });
    return res;
  };

  return [update, { posting, status, errors }];
};

export interface IGetCustomFieldInfo {
  correlationId: string;
  result: string[];
}

export interface IGetCustomFieldInfoPayload {
  customFieldId: number;
  customerKey: string;
}

export const loadCustomFieldData = async (toggle:boolean): Promise<
  ICaseSection[] | string
> => {
  const getEpimCustomFieldDataUrl = toggle ? `${buildServerApiUrl("CustomFieldMapping.Get","v3").toString()}/${getCustomerKey()}`
                                           : `${buildServerApiUrl("CustomFieldMapping.Get","v2").toString()}/${getCustomerKey()}` ;

  let errorKey: number | undefined = undefined;
  let response = await axiosInstance
    .get(getEpimCustomFieldDataUrl)
    .catch((error) => {
      if (error) {
        errorKey = error.DEFAULT_ERROR;
        return undefined;
      }
    });
  let res: ICaseSection[] = [];
  if (response === undefined || errorKey) {
    return "There was a bad response from the server";
  }
  if (!!response) {
    const products: ICaseSection[] = response.data.data.caseSections;
    res = products;
  }
  return res;
};
