import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from '../../Api/urls'
import { IUseAxiosOptions, useAxiosPost } from '../../Api/useAxios'
import { axiosInstance } from '../../Api/api'
import { models } from 'powerbi-client'

interface IEmbedInfoPayload {
    customerKey: string,
    reportId: string,
}

type TUseEmbedInfoPayload = [
    (payload: IEmbedInfoPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        embedInfo: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const getEmbedInfoUrlPath = `${buildServerApiUrl("PowerBI.GetEmbedInfo").toString()}`
export const useGetEmbedInfo = (options?: IUseAxiosOptions<IEmbedInfoPayload>): TUseEmbedInfoPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IEmbedInfoPayload>(
        getEmbedInfoUrlPath, {}, options
    )

    const getEmbedInfo = async (payload: IEmbedInfoPayload) => {
        const res = await execute(payload)
        return res
    }

    return [getEmbedInfo, { posting, status, embedInfo: data, errors }]
}

type IUpsertReportRolesPayload = {
    customerKey: string,
    reportId: string,
    roleIds: any[]
}

type TUseReportRolesPayload = [
    (payload: IUpsertReportRolesPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const upsertReportRolesUrl = `${buildServerApiUrl("PowerBi.UpsertReportRoles").toString()}`
export const useUpsertReportsRoles = (options?: IUseAxiosOptions<IUpsertReportRolesPayload>): TUseReportRolesPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IUpsertReportRolesPayload>(
        upsertReportRolesUrl, {}, options
    )

    const upsertReportRoles = async (payload: IUpsertReportRolesPayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [upsertReportRoles, { posting, status, data, errors }]
}

interface GetReportRoleModel {
    roles: number[],
    favorite: boolean
}

export const getnewReportRoles = async (reportId: string): Promise<GetReportRoleModel|undefined> => {
    const getReportRolesUrlPath = `${buildServerApiUrl("PowerBI.GetReportRoles").toString()}/${getCustomerKey()}`
    let token = undefined
    const url = getReportRolesUrlPath + `/${reportId}`

    try {
        const response = await axiosInstance.get(url)
        token = response.data.data
        return Promise.resolve(token)

    } catch (error) {
        console.log(`An error occurred trying to get the data : `, error)
        return Promise.resolve(undefined)
    }
}

type IFavoritePayload = {
    customerKey: string,
    id: string,
    favorite: boolean
}

type IExportReportIdPayload = {
    customerKey: string,
    reportId: string,
    format: string,
    bookmark: models.IReportBookmark | null
}

type TFavoritePayload = [
    (payload: IFavoritePayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

type TExportIdPayload = [
    (payload: IExportReportIdPayload) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

type TExportPayload = [
    (payload: IReportResponse) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

const favoriteReportUrl = `${buildServerApiUrl("PowerBi.FavoriteReport").toString()}`
export const useFavoriteReport = (options?: IUseAxiosOptions<IFavoritePayload>): TFavoritePayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IFavoritePayload>(
        favoriteReportUrl, {}, options
    )

    const favoriteReport = async (payload: IFavoritePayload) => {
        const res = await execute({ ...payload })
        return res
    }

    return [favoriteReport, { posting, status, data, errors }]
}
const exportReportIdUrl = `${buildServerApiUrl("PowerBi.GetPowerBIExportReportId").toString()}`
export const useExportReportId = (options?: IUseAxiosOptions<IExportReportIdPayload>): TExportIdPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IExportReportIdPayload>(
        exportReportIdUrl, {}, options
    )

    const exportReportId = async (payload: IExportReportIdPayload) => {
        const res = await execute({ ...payload })
        return res
    }
    return [exportReportId, { posting, status, data, errors }]
}

export type IReportResponse = {
    customerKey: string,
    reportId: string,
    format: string,
    responseWithExportId: IExport
}

export type IExport = {
    id: string,
    createdDateTime: string,
    lastActionDateTime: string,
    reportId: string,
    reportName: string | null
    status: string
    percentComplete: string
    resourceLocation: string | null
    ResourceFileExtension: string | null
    expirationTime: string
}

const exportReportResponseUrl = `${buildServerApiUrl("PowerBi.GetPowerBIReportResponseStatus").toString()}`

export const callExportResponse = async (resp: IReportResponse): Promise<IExport | undefined> => {
    let errorKey: number | undefined = undefined
    let response = await axiosInstance.post(exportReportResponseUrl, resp)
        .catch(error => {
            if (error) {
                errorKey = error.DEFAULT_ERROR
                return undefined
            }
        })
    let res: IExport | undefined = undefined
    if (response === undefined || errorKey) { return undefined }
    if (!!response) {
        res = response.data.data
    }
    return res
}

const exportReportUrl = `${buildServerApiUrl("PowerBi.ExportReport").toString()}`
export const useExportReport = (options?: IUseAxiosOptions<IReportResponse>): TExportPayload => {
    const [execute, { posting, status, data, errors }] = useAxiosPost<IReportResponse>(
        exportReportUrl, {}, options
    )

    const exportReport = async (payload: IReportResponse) => {
        const res = await execute({ ...payload })
        return res
    }
    return [exportReport, { posting, status, data, errors }]
}

export interface IUpsertPowerBiSubscription {
    customerKey: string,
    ReportId: string,
    SubscribeName: string,
    RecipientMailId: string,
    ReportTypeFormat: string,
    SubscriptionStartDate: Date,
    Frequency: string,
    Status: boolean
    Timezone: string,
    ReportUrl:string,
    WeekOfFrequency: string | null,
    MonthOfFrequency: string | null,
}

export type IUpsertSubscription = Omit<IUpsertPowerBiSubscription, "customerKey">

type TUsePowerBiSubscription = [
    (payload: IUpsertSubscription) => Promise<AxiosResponse<any> | undefined>,
    {
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]

export const upsertSubscribeUrl = `${buildServerApiUrl("PowerBi.PowerBiSubscription").toString()}`
export const usePowerBiSubscription = (
    options?: IUseAxiosOptions<IUpsertPowerBiSubscription>
): TUsePowerBiSubscription => {

    const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertPowerBiSubscription>(
        upsertSubscribeUrl, {}, options
    )

    const PowerBiSubscription = async (payload: IUpsertSubscription) => {
        const res = await upsert({ ...payload, customerKey: getCustomerKey() })
        return res
    }

    return [PowerBiSubscription, { posting, status, errors }]
}
