import React, { FC } from 'react'
import { NoDataError } from '../Shared/Error'
import { ResetDashboardListItem } from './ResetDashboardListItem'
import { ICheckBoxState } from './ResetDashboardTabView'
import { ICollection } from './ResetDashboardV2Api'

interface IProps {
    collections: ICollection[],
    productName: string,
    checkBoxDashState: ICheckBoxState<number[]>,
    setCheckBoxDashState: (checkBoxState: ICheckBoxState<number[]>) => void,
    dashboardsPolling: ICheckBoxState<boolean>,
    setDashboardsPolling: (checkBoxState: ICheckBoxState<boolean>) => void
}

export const ResetDashboardTableView: FC<IProps> = (props) => {
    const { dashboardsPolling, setDashboardsPolling } = props
    const dashboardResetInfoList = props.collections.map(coll => {
        return (
            <ResetDashboardListItem collectionName={coll.collectionName} dashboards={coll.dashboards} productName={props.productName}
                key={coll.collectionName} checkBoxDashState={props.checkBoxDashState} setCheckBoxDashState={props.setCheckBoxDashState}
                {...{ dashboardsPolling, setDashboardsPolling }} />
        )
    })

    return (
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" style={{ width: "19%" }}>Dashboard Name</th>
                    <th scope="col" style={{ width: "15%" }}>Collection Name</th>
                    <th scope="col" style={{ width: "12%" }}>Reset Status</th>
                    <th scope="col" style={{ width: "17%" }}>Last Reset By</th>
                    <th scope="col" style={{ width: "11%" }}>Date</th>
                    <th scope="col" style={{ width: "50%" }}>Reason</th>
                </tr>
            </thead>
            <tbody>
                {
                    dashboardResetInfoList.length > 0
                        ?
                        dashboardResetInfoList
                        :
                        <tr>
                            <td colSpan={7}><NoDataError /></td>
                        </tr>
                }
            </tbody>
        </table>
    )
} 