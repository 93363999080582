import React, { FC, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'navex-react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { number, object, string } from 'yup'
import { Toasts, ToastId } from 'navex-react'
import { ButtonRow, FormikOption, FormikSelect } from 'navex-react'
import useButtonLoader from './CustomFormulaLoader'
import { IGetCustomColumn, IGetTables, useUpsertCustomColumns } from './CustomColumnsApi'

interface IProps {
  setIsCalculatedColumnsEdit: (value: boolean) => void,
  reFetchData: () => void,
  customColumn?: IGetCustomColumn,
  customColumnInfo: IGetCustomColumn[],
  tableData: IGetTables[]
}

const summarizeBy = [
  { name: "Default", value: "1" },
  { name: "None", value: "2" },
  { name: "Sum", value: "3" },
  { name: "Min", value: "4" },
  { name: "Max", value: "5" },
  { name: "Count", value: "6" },
  { name: "Average", value: "7" },
  { name: "DistinctCount", value: "8" }
]

const columnDataType = [
  // { name: "Automatic", value: "1" },
  { name: "String", value: "2" },
  { name: "Int64", value: "6" },
  { name: "Double", value: "8" },
  { name: "DateTime", value: "9" },
  { name: "Decimal", value: "10" },
  { name: "Boolean", value: "11" },
  { name: "Binary", value: "17" },
  // { name: "Unknown", value: "19" },
  // { name: "Variant", value: "20" }
]

export const CalculatedColumnDetailView: FC<IProps> = (props) => {
  
  const [isAdding, setIsAdding] = useButtonLoader("Add", "Adding...")
  const [isUpdating, setIsUpdating] = useButtonLoader("Update", "Updating...")

  const [dataType, setDataType] = useState(props.customColumn === undefined ? "" : columnDataType.filter(d => d.value === props.customColumn?.dataType.toString())[0].name)

  const columnName = props.customColumn?.columnName
  let toastId: ToastId = 0
  const [upsert] = useUpsertCustomColumns({
    onSuccess: () => {
      toastId = Toasts.success("Custom Column Added sucessfully")
      props.reFetchData()
      handleColumnClose()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
      props.reFetchData()
      handleColumnClose()
    }
  })

  const isCustomColumnNameExist = (proposedFieldName: string | undefined) => {
    if (!proposedFieldName)
      return false
    let existingFieldNames: string[] = props.customColumnInfo.map(s => s.columnName.toUpperCase())
    return !existingFieldNames.includes(proposedFieldName.toUpperCase())
  }

  const ValidateEditName = (editName: string) => {
    if (editName === columnName) {
      return true
    }
    return (isCustomColumnNameExist(editName))
  }

  const handleColumnClose = () => {
    props.setIsCalculatedColumnsEdit(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          ColumnName: props.customColumn === undefined ? "" : props.customColumn.columnName,
          Expression: props.customColumn === undefined ? "" : props.customColumn.expression,
          TableName: props.customColumn === undefined ? "" : props.customColumn.tableName,
          DataType: props.customColumn === undefined ? "" : columnDataType.filter(d => d.value === props.customColumn?.dataType.toString())[0].name,
          DecimalPlace: props.customColumn === undefined ? 0 : props.customColumn.decimalPlaces,
          SummarizeBy: props.customColumn === undefined ? "" : summarizeBy.filter(d => d.value === props.customColumn?.summarizeBy.toString())[0].name,
          ErrorMsg: props.customColumn === undefined ? "" : props.customColumn.errorMsg
        }}

        onSubmit={async (values, actions) => {
          setIsAdding(true)
          setIsUpdating(true)
          await upsert({
            columnName: values.ColumnName,
            dataType: values.DataType,
            decimalPlace: values.DecimalPlace,
            expression: values.Expression,
            summarizeBy: values.SummarizeBy,
            TableName: values.TableName,
            errorMsg:values.ErrorMsg
          })

          actions.setSubmitting(false)
          setIsAdding(false)
          setIsUpdating(false)
          actions.resetForm()
        }}

        validationSchema={object().shape({
          TableName: string()
            .required("Select Table Name"),
          ColumnName: string()
            .required("Enter Custom Column Name")
            .min(1)
            .max(100)
            .test("isUnique", "Name already exists", ValidateEditName),
          Expression: string()
            .required("Enter Expression"),
          DataType: string()
            .required("Select Data Type"),
          DecimalPlace: number()
            .min(0),
          SummarizeBy: string()
            .required("Select Summarize By value")
        })}
        validateOnBlur
      >
        {({ values,
          errors,
          touched,
          setFieldValue,
          isSubmitting, }) => (
          <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div className="row">
              <div className="col-12 col-sm-6">

                <div className="form-group">
                  <label className="control-label" htmlFor="TableName">Table Name
                    <span className="validation-required">{" * "}</span>
                    {errors.TableName && touched.TableName ? (<span className="validation-label" aria-hidden="false">{errors.TableName}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="TableName" component={FormikSelect} name="TableName" disabled={props.customColumn !== undefined}
                      aria-invalid={errors.TableName && touched.TableName} aria-required="true"
                      required maxMenuHeight="200px"
                      onChange={(value: any) => { setFieldValue('TableName', value) }} >
                      {
                        props.tableData.map(d => {
                          return <FormikOption value={d.tableAlias} key={d.tableAlias}>{d.tableAlias}</FormikOption>
                        })
                      }</Field>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label" htmlFor="ColumnName">Column Name
                    <span className="validation-required">{" * "}</span>
                    {errors.ColumnName && touched.ColumnName ? (<span className="validation-label" aria-hidden="false">{errors.ColumnName}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="ColumnName" name="ColumnName" className="form-control" aria-describedby="name-help-block"
                      aria-invalid={errors.ColumnName && touched.ColumnName} aria-required="true" disabled={props.customColumn !== undefined}
                      required />
                    <span className="add-clear-x form-control-feedback" style={{
                      color: "rgb(51, 51, 51)",
                      cursor: "pointer",
                      textDecoration: "none",
                      overflow: "hidden",
                      position: "absolute",
                      pointerEvents: "auto",
                      right: 0,
                      top: 0,
                      zIndex: 100,
                      display: values.ColumnName.length > 0 ? undefined : "none",
                    }}
                      onClick={() => setFieldValue("ColumnName", "")}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label" htmlFor="Expression">Expression
                    <span className="validation-required">{" * "}</span>
                    {errors.Expression && touched.Expression ? (<span className="validation-label" aria-hidden="false">{errors.Expression}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="Expression" component="textarea" name="Expression" className="form-control no-ie-clear" rows="8" aria-describedby="name-help-block"
                      aria-invalid={errors.Expression && touched.Expression} aria-required="true" placeholder={`SWITCH(\nTRUE,\n'Case Details'[Days to Close] >= 60, "60+ days",\n'Case Details'[Days to Close] >= 30, "30-59 days",\n'Case Details'[Days to Close] >= 11, "11-29 days",\n'Case Details'[Days to Close] < 11, "< 11 days",\n"None")`}
                      required />
                    <span className="add-clear-x form-control-feedback" style={{
                      color: "rgb(51, 51, 51)",
                      cursor: "pointer",
                      textDecoration: "none",
                      overflow: "hidden",
                      position: "absolute",
                      pointerEvents: "auto",
                      right: 0,
                      top: 0,
                      zIndex: 100,
                      display: values.Expression.length > 0 ? undefined : "none",
                      height: "200px"
                    }}
                      onClick={() => setFieldValue("Expression", "")}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label" htmlFor="DataType">Data Type
                    <span className="validation-required">{" * "}</span>
                    {errors.DataType && touched.DataType ? (<span className="validation-label" aria-hidden="false">{errors.DataType}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="DataType" component={FormikSelect} name="DataType"
                      aria-invalid={errors.DataType && touched.DataType} aria-required="true"
                      required
                      onChange={(value: any) => { setDataType(value); setFieldValue('DataType', value) }} >
                      {
                        columnDataType.map(d => {
                          return <FormikOption value={d.name} key={d.name}>{d.name}</FormikOption>
                        })
                      }
                    </Field>
                  </div>
                </div>

                {dataType === "Decimal" ? <div className="form-group">
                  <label className="control-label" htmlFor="DecimalPlace">Decimal Place
                    <span className="validation-required">{" * "}</span>
                    {errors.DecimalPlace && touched.DecimalPlace ? (<span className="validation-label" aria-hidden="false">{errors.DecimalPlace}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="DecimalPlace" name="DecimalPlace" className="form-control no-ie-clear" aria-describedby="name-help-block"
                      aria-invalid={errors.DecimalPlace && touched.DecimalPlace} aria-required="true"
                      type="number" />
                    <span className="add-clear-x form-control-feedback" style={{
                      color: "rgb(51, 51, 51)",
                      cursor: "pointer",
                      textDecoration: "none",
                      overflow: "hidden",
                      position: "absolute",
                      pointerEvents: "auto",
                      right: 0,
                      top: 0,
                      zIndex: 100,
                    }}
                      onClick={() => setFieldValue("DecimalPlace", "")}
                    >
                    </span>
                  </div>
                </div> : null}

                <div className="form-group">
                  <label className="control-label" htmlFor="SummarizeBy">Summarize By
                    <span className="validation-required">{" * "}</span>
                    {errors.SummarizeBy && touched.SummarizeBy ? (<span className="validation-label" aria-hidden="false">{errors.SummarizeBy}</span>) : null}
                  </label>
                  <div className="form-control-feedback-group">
                    <Field id="SummarizeBy" component={FormikSelect} name="SummarizeBy"
                      aria-invalid={errors.SummarizeBy && touched.SummarizeBy} aria-required="true"
                      required
                      onChange={(value: any) => { setFieldValue('SummarizeBy', value) }} >
                      {
                        summarizeBy.map(d => {
                          return <FormikOption value={d.name} key={d.name}>{d.name}</FormikOption>
                        })
                      }</Field>
                  </div>
                </div>

                <div className="form-group d-flex justify-content-end">
                  <ButtonRow>
                    <Button id="btnCancelColumn" disabled={isSubmitting} onClick={handleColumnClose}>Cancel</Button>
                    {props.customColumn === undefined
                      ? <Button id="btnAddColumn" purpose="primary" type="submit" disabled={values.TableName.length === 0 || !!errors.TableName || isSubmitting} innerRef={isAdding}>Add</Button>
                      : <Button id="btnUpdateColumn" purpose="primary" type="submit" disabled={values.ColumnName.length === 0 || !!errors.ColumnName || isSubmitting} innerRef={isUpdating}>Update</Button>
                    }
                  </ButtonRow>
                </div>
              </div>
            </div>
          </Form>
        )
        }
      </Formik >
    </>
  )
}

