
export const addDays = (date: string, days: number): Date => {
    const formattedDate = new Date(new Date(new Date(date)
        .setDate(new Date(date).getDate() + days)))
    return formattedDate
}

export const getDate = (date: Date): number => {
    return date.getDate()
}

export const getMonth = (date: Date): number => {
    return date.getMonth()
}

export const createDateFromMonthIndexAndDay = (date: Date, day: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), day)
}

export const getEndDateOfAMonth = (date: Date): number => {
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    return endDate
}

export const months : string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]