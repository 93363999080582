//in your authentication promise handler or callback
export function activatePendo(userId, customerKey, tenantId) {

    window.pendo.initialize({
        visitor: {
            id: userId   // Required if user is logged in

        },

        account: {
            id: tenantId === undefined ? customerKey : tenantId,  // Highly recommended
            clientKey: customerKey

        }
    });
}
