import React, { FC } from 'react'
import { NoDataError } from '../Shared/Error'
import { CustomFormulaListItem } from './CustomFormulaListItem'
import { IGetCustomFormula } from './CustomFormulasApi'

interface IProps {
  customFormulaInfo: IGetCustomFormula[],
  setIsCustomFormulaEdit: (isEdit: boolean) => void,
  reFetchData: () => void,
  setCustomFormulaId: (customId: number | undefined) => void,
  customId: number | undefined,
}

export const CustomFormulasListView: FC<IProps> = (props) => {

  return (<>
    <table id="formulaTable" className="table table-striped table-bordered">
      <thead>
        <tr style={{ backgroundColor: "#edecec" }}>
          <th style={{ width: "40%" }}>Custom Formula</th>
          <th style={{ width: "30%" }}>Name</th>
          <th style={{ width: "15%" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          props.customFormulaInfo.length > 0
            ?
            (props.customFormulaInfo.map(cf => {

              return <CustomFormulaListItem key={props.customId} customId={props.customId} formula={cf.formula} name={cf.name} id={cf.id}
              setIsCustomFormulaEdit={props.setIsCustomFormulaEdit} reFetchData={props.reFetchData} setCustomFormulaId={props.setCustomFormulaId}
              />
            }))
            : (<tr>
              <td colSpan={3}><NoDataError /></td>
            </tr>)
        }
      </tbody>
    </table>
  </>
  )
}