import { buildServerApiUrl, buildUrlForKeycloakAccessToken } from "./urls"
import rawAxios, { AxiosResponse, AxiosError } from "axios"

const DEFAULT_API_TIMEOUT = 120000

export const axiosInstance = rawAxios.create({
    withCredentials: true,
    headers: { crossDomain: true },
    timeout: DEFAULT_API_TIMEOUT,
})

export interface Ok {
    ok: true
    response: AxiosResponse<unknown>
}

export interface Err {
    ok: false
    error: AxiosError
}

export type ApiResult = Ok | Err

export const Ok = (response: AxiosResponse<unknown>): Ok => {
    return {
        ok: true,
        response
    }
}

export const Err = (error: AxiosError): Err => {
    return {
        ok: false,
        error
    }
}

export const getBirstTokenUrlFromServer = async (spaceId: string, customerKey: string) => {

    interface IPostBirstTokenPayload {
        spaceId: string,
        customerKey: string,
    }

    try {
        const url = `${buildServerApiUrl("BirstToken.GetBirstUrl", "v2").toString()}`

        const payload: IPostBirstTokenPayload = {
            spaceId,
            customerKey,
        }

        const response = await axiosInstance.post(url, payload)

        return Ok(response)

    } catch (error) {
        console.log(`An error occurred posting the birst token : `, error)
        return Err(error as any)
    }
}

export const getKeycloakAccessToken = async () => {

    let token = undefined
    const url = buildUrlForKeycloakAccessToken().toString()

    try {
        const response = await axiosInstance.get(url)
        token = response.data.data
        return token

    } catch (error) {
        console.log(`An error occurred trying to get the keycloak token : `, error)
        return undefined
    }

}
