import React, { FC, useState } from 'react'
import { Modal } from 'navex-react'
import { ActionIcon } from 'navex-react'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { Toasts, ToastId } from 'navex-react'
import { ButtonRow, IButtonProps } from 'navex-react'
import { useDeleteCustomColumn } from './CustomColumnsApi'
import { SmallSpinner } from '../Shared/Spinner'

interface IProps {
  columnId: number | undefined,
  id: number,
  columnName: string,
  dataType: string,
  decimalPlace: number,
  expression: string,
  status: string,
  summarizeBy: string,
  tableName: string,
  errorMsg: string,
  setIsCalculatedColumnsEdit: (value: boolean) => void,
  reFetchData: () => void,
  setCustomColumnId: (columnId: number | undefined) => void,
  disabled: boolean
}

const summarizeBy = [
  { name: "Default", value: "1" },
  { name: "None", value: "2" },
  { name: "Sum", value: "3" },
  { name: "Min", value: "4" },
  { name: "Max", value: "5" },
  { name: "Count", value: "6" },
  { name: "Average", value: "7" },
  { name: "DistinctCount", value: "8" }
]

const columnDataType = [
  { name: "Automatic", value: "1" },
  { name: "String", value: "2" },
  { name: "Int64", value: "6" },
  { name: "Double", value: "8" },
  { name: "DateTime", value: "9" },
  { name: "Decimal", value: "10" },
  { name: "Boolean", value: "11" },
  { name: "Binary", value: "17" },
  { name: "Unknown", value: "19" },
  { name: "Variant", value: "20" }
]

export const CustomColumnListItem: FC<IProps> = (props) => {
  const [showModalDeleteCustomColumn, setShowModalDeleteCustomColumn] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);
  const [okButtonProps, setOkButtonProps] = useState<IButtonProps>({ disabled: false })

  let toastId: ToastId = 0

  const { id } = props

  const [deleteCustomColumn] = useDeleteCustomColumn({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Custom Column deleted sucessfully")
      }
      props.reFetchData()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const handleEditCustomColumnBtnLinkClick = () => {
    props.setIsCalculatedColumnsEdit(true)
    props.setCustomColumnId(id)
  }

  const handleDeleteCustomColumnAlertBtnLinkClick = () => {
    setShowModalDeleteCustomColumn(true)
  }

  const handleDeleteModalXClose = () => {
    setShowModalDeleteCustomColumn(false)
  }

  const handleCustomColumnDelete = async () => {
    if (id !== undefined) {
      setIsDeleting(true)
      okButtonProps.disabled = true
      setOkButtonProps(okButtonProps)
      await deleteCustomColumn({id: id, tableName: props.tableName, columnName: props.columnName })
      setIsDeleting(false)
    }
  }

  const disabled = props.status === "Processing"
  return (
    <tr key={id}>
      <td>{props.tableName}</td>
      <td>{props.columnName}</td>
      <td>{props.expression}</td>
      <td>{columnDataType.filter(d => d.value === props.dataType.toString())[0].name}</td>
      <td>{props.decimalPlace}</td>
      <td>{summarizeBy.filter(d => d.value === props.summarizeBy.toString())[0].name}</td>
      <td>{props.status}{disabled && <SmallSpinner/>}</td>
      <td>{props.errorMsg}</td>
      <td style={{overflow:"hidden", whiteSpace:"nowrap"}}>
        <ButtonRow>
        <ActionIcon id={props.id + "_btnEditColumn"} icon={faPencilAlt} toolTip="Edit" onClick={handleEditCustomColumnBtnLinkClick} disabled={props.disabled}/>
        <ActionIcon id={props.id + "_btnDeleteColumn"} icon={faTrashAlt} toolTip="Delete" onClick={handleDeleteCustomColumnAlertBtnLinkClick} disabled={props.disabled}/>
        </ButtonRow>
        <Modal id={props.id + "_modalDeleteColumn"} modalType={("BASIC")}
          headerText={props.columnName}
          show={showModalDeleteCustomColumn}
          okButtonText={!isDeleting ? "Delete" : "Deleting..."}
          okButtonProps={okButtonProps}
          message={
            `Delete ${props.columnName} from custom column table.`
          }
          onClickedOk={handleCustomColumnDelete}
          onClickedCancel={handleDeleteModalXClose}
        />
      </td>     
    </tr>
  )
}