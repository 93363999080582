import { buildUrlForBirstLogout, buildUrlForFinishLogoutV3 } from "../Api/urls"

export type StringPredicate = (text: string) => boolean;

export function pascalize(text: string) {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter) {
      return letter.toUpperCase()
    })
    .replace(/\s+/g, "")
}

export function hiddenIframeToClearBirstCookiesOnLogout() {
  const iframeBirstLogout = document.createElement("iframe")
  iframeBirstLogout.setAttribute("src", buildUrlForBirstLogout().toString())
  iframeBirstLogout.setAttribute(
    "style",
    "visibility: hidden; position: absolute; left: 0; top: 0; height:0; width:0;border: none;"
  );
  document.body.appendChild(iframeBirstLogout)
}

export function handleStorageEvent(event: any) {
  if (event!.storageArea === localStorage) {
    CheckLocalStorageForTokenBeforeRedirectToLogin()
  }
}

export async function CheckLocalStorageForTokenBeforeRedirectToLogin() {
  let token = localStorage.getItem("insightsActive")

  if (!token) {
    hiddenIframeToClearBirstCookiesOnLogout()

    if (!window.location.pathname.includes("DashboardManagement_EditDashboard")) {
      window.location.href = buildUrlForFinishLogoutV3().toString()
    }
  }
}
