import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'
import { IInsightsState } from '../Models/GlobalState'


class InsightsReducerDef extends ImmerReducer<IInsightsState>{
    setCurrentPage(value: string) {
        this.draftState.currentPage = value
      }
}

export const InsightsActionCreators = createActionCreators(InsightsReducerDef)
export const insights = createReducerFunction(InsightsReducerDef, getEmptyInsightsState())

function getEmptyInsightsState(): IInsightsState {
  return {
    currentPage: "",
  }
}