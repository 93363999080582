import {combineReducers} from 'redux'
import {authentication} from './AuthenticationReducer'
import { birstIntegrations } from './BirstIntegrationsReducer'
import { insights } from './InsightsReducer'

const rootReducer = combineReducers({
    authentication,
    birstIntegrations,  
    insights, 
})

export default rootReducer