import { faChartPie, faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ActionIcon } from 'navex-react'
import React, { FC, useState } from 'react'
import { Toasts, ToastId, Button } from 'navex-react'
import { useEnableReport, useDeleteReport } from './ManagePowerBIApi'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getCustomerKey } from '../../Api/urls'
import { pascalize } from '../../Utils/utils'
import { SmallSpinner } from "../Shared/Spinner"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

interface IProps {
  id: string,
  name: string,
  description: string,
  collection: string,
  embedUrl: string,
  isDraft: boolean,
  workSpaceId: string,
  setReportMode: (mode: "Create" | "View" | "Edit" | "") => void
  setReportId: (reportId: string) => void
  setEmbedUrl: (embedUrl: string) => void,
  status: string,
  reFetch: () => void,
  favorite: boolean
}

const ManagePowerBIRowDraft: FC<IProps> = (props) => {

  const { reFetch } = props
  let toastId: ToastId = 0

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEnableReport({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Enabling the report")
      }
      reFetch()
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Enable report failed, please try again later.")
      }
    }
  })

  const [deleteReport] = useDeleteReport({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Deleting the report")
      }
      setIsSubmitting(false)
      setShowDeleteModal(false)
      reFetch()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Deleting report failed, please try again later.")
      }
      setIsSubmitting(false)
      setShowDeleteModal(false)
    }
  })

  const [enable] = useEnableReport({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Enabling the report")
      }
      reFetch()
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Enable report failed, please try again later.")
      }
    }
  })

  const handleOnViewClick = () => {
    props.setReportId(props.id)
    props.setEmbedUrl(props.embedUrl)
    props.setReportMode("View")
  }

  const handleOnEditClick = () => {
    props.setReportId(props.id)
    props.setEmbedUrl(props.embedUrl)
    props.setReportMode('Edit')
  }

  const enableReport = () => {
    enable({
      customerKey: getCustomerKey(),
      id: props.id,
      workSpaceId: props.workSpaceId,
      name: props.name,
      embedUrl: props.embedUrl
    })
  }

  const deletePowerBiReport = () => {
    deleteReport({
      customerKey: getCustomerKey(),
      id: props.id,
      workSpaceId: props.workSpaceId
    })
    setIsSubmitting(true)
  }

  const handleDeleteReportModalXClose = () => {
    setShowDeleteModal(false)
  }

  const closeModalXButton = (
    <button
      id="closeXButton"
      tabIndex={0}
      className="close modal-header-close"
      onClick={handleDeleteReportModalXClose}
      aria-label={"close"}
      type="button"
      disabled={isSubmitting}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  )


  return (
    <tr>
      <td>{props.name}</td>
      <td></td>
      <td></td>
      <td></td>
      <td style={{ display: "flex" }} >
        {
          <><ActionIcon
            id={`${props.id}_ViewIcon`}
            toolTip={"View Report"}
            onClick={handleOnViewClick}
            icon={faChartPie} />

            <ActionIcon
              id={`${props.id}_editIcon`}
              toolTip={"Edit Report"}
              onClick={handleOnEditClick}
              icon={faPencilAlt} />

            <ActionIcon
              id={`${pascalize(props.id)}_${pascalize("EthicsPoint")}_delete`}
              toolTip={"Delete Report"}
              icon={faTrashAlt}
              onClick={() => setShowDeleteModal(true)}  >
            </ActionIcon>
            <Modal isOpen={showDeleteModal}>
              <ModalHeader close={closeModalXButton}>
                Delete Report
              </ModalHeader>
              <ModalBody>
                <>
                  Are you sure you want to delete the <b>{props.name}</b> report?
                </>
              </ModalBody>
              <ModalFooter>
                {
                  isSubmitting ?
                    <SmallSpinner />
                    :
                    <Button purpose="primary" id="btnDelete"
                      style={{ float: "right" }}
                      onClick={deletePowerBiReport}>Delete</Button>
                }
              </ModalFooter>
            </Modal>
            <Button
              id={`${pascalize(props.id)}_enable`}
              onClick={enableReport}>
              Enable
            </Button>
          </>
        }
      </td>
    </tr>
  )
}

export default ManagePowerBIRowDraft