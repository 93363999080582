import  React, { useEffect, useState } from 'react'
import { Modal } from 'navex-react'


const messageContent = 'You are no longer signed in. If you would like to continue working, please sign in again. Otherwise you can close your window.'

const handleReload = () => {
    document.location.reload()

}

const isLoggedOut = () => {
    let token = localStorage.getItem('insightsActive')

    return token === null

}


export const LogoutHandler = () => {

    const [showModal, setShowModal] = useState(false)

    useEffect(function manageEvents(){

        const handleStorageEvent = (event:any) => {
            if (event!.storageArea === localStorage) {
            
                if (isLoggedOut() && window.location.pathname.includes('DashboardManagement_EditDashboard')) {

                    setShowModal(true)
                  }
            }
        }

        window.addEventListener('storage', handleStorageEvent)

        return function cleanUp(){
            window.removeEventListener('storage', handleStorageEvent)

        }

    }, [])

    return (<Modal id="logoutModal" modalType="BASIC"
        show={showModal}
        message={messageContent}
        okButtonText={"Sign in"}
        onClickedOk={handleReload}
        hideCloseButton
    />)
}