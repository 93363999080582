type TBirstFilterValue = string | null

export interface IBirstFilter {
  key: string,
  operator: string, // So far I've only seen =
  value: TBirstFilterValue[] // So far I've only seen [null]
}
export interface IDrillAcrossEventData {
  dashboard: string,
  page: string
}
export interface ISetFiltersEventData {
  filters: IBirstFilter[]
}

export const getDashboardsFromPath = (pathname: string) => {
  const matches = pathname.match(/Dashboards\/(?<collectionName>.*)_(?<dashboardName>.*)/)

  return {
    dashboardName: (matches && (matches.groups!.dashboardName || matches[1])) || "",
    collectionName: (matches && (matches.groups!.collectionName || matches[2])) || "",
  }
}

export const getReportsFromPath = (pathname: string) => {
  const matches = pathname.match(/Reports\/(?<pbiCollectionName>.*)_(?<reportName>.*)/)

  return {
    reportName: (matches && (matches.groups!.reportName || matches[1])) || "",
    pbiCollectionName: (matches && (matches.groups!.pbiCollectionName || matches[2])) || "",
  }
}

export const getDashboardsFromEditPath = (pathname: string) => {
  const matches = pathname.match(/DashboardManagement_EditDashboard\/(?<productName>.*)_(?<collectionName>.*)_(?<dashboardName>.*)/)

  return {
    productName: (matches && (matches.groups!.productName || matches[1])) || "",
    collectionName: (matches && (matches.groups!.collectionName || matches[2])) || "",
    dashboardName: (matches && (matches.groups!.dashboardName || matches[3])) || "",

  }
}

export const getFiltersFromQueryParams = (query: string) => {
  let filtersString = undefined
  const filtersRegex = new RegExp(/(?:filters=)(?<filters>.*&?)/)
  const filtersMatch = filtersRegex.exec(query)
  if (filtersMatch && filtersMatch.groups && filtersMatch.groups.filters) {
    filtersString = filtersMatch.groups.filters
  } else if (filtersMatch) {
    filtersString = filtersMatch[1]
  }
  return filtersString
}

export const buildQueryFromBirstFiltersArray = (filters: IBirstFilter[]) => {
  // https://birst.in.navex-pe.com/Help/Full/Data/integration/sso_parameters.htm
  if (!filters.length) return undefined
  let result = ""
  let index = 0
  for (let filter of filters) {
    if (index > 0) result += ";"
    result += `${encodeURIComponent(filter.key)}:`
    let valuesIndex = 0
    for (let value of filter.value) {
      if (valuesIndex > 0) result += ","
      result += encodeURIComponent(String(value).replace(/%2C/g, ","))
      valuesIndex++
    }
    index++
  }
  return result
}
