import React, { FC } from 'react'
import styles from "./FiscalCalendar.module.scss"
import { IQuarters } from './FiscalCalendarApi'
import { DAYS_OF_THE_WEEK } from './FiscalQuarters'

interface IProps {
    quarters: IQuarters[],
    fiscalYear: number,
    fiscalFormat: string | undefined,
}

export const PreviewRetailCalendar: FC<IProps> = (props) => {
    const { quarters, fiscalYear, fiscalFormat } = props

    let week = 1

    const addDays = (date: Date, days: number) => {
        let result = date
        result.setDate(result.getDate() + days)
        return result
    }

    const calDaysForRetail = (startDate: string, endDate: string) => {
        let start = new Date(new Date(startDate))
        let end = new Date(endDate)
        let days = []

        while (start <= end) {
            if (days.length === 0) {
                days.push({ isWeekNo: true, value: week })
            }
            else if (days.length === 8) {
                week += 1
                days.push({ isWeekNo: true, value: week })
            }
            else if (days.length === 16) {
                week += 1
                days.push({ isWeekNo: true, value: week })
            } else if (days.length === 24) {
                week += 1
                days.push({ isWeekNo: true, value: week })
            } else if (days.length === 32) {
                week += 1
                days.push({ isWeekNo: true, value: week })
            }
            else if (days.length === 40) {
                week += 1
                days.push({ isWeekNo: true, value: week })
            }
            days.push({ isWeekNo: false, value: start.getDate() })
            start = addDays(start, 1)
        }

        const cal = days.map((d, index) => {
            const style = d.isWeekNo === true ? styles.weekNo : styles.weekDay
            return (
                <li key={`${index}+${d}+${new Date().getMilliseconds}`}
                    className={style}
                >
                    {d.value}
                </li>
            )
        })

        week += 1

        return cal
    }

    const DisplayDays: FC<{ startDate: string, endDate: string, fiscalMonth: string }> = (props) => {
        const { startDate, endDate, fiscalMonth } = props
        return (
            <div className={styles.calendar} >
                <p>{fiscalMonth}</p>
                <hr />
                <ul>
                    <li className={styles.week}>Wk</li>
                    {DAYS_OF_THE_WEEK.map((d) => {
                        return (
                            <li className={styles.week} key={d}>{d}</li>
                        )
                    })}
                    {calDaysForRetail(startDate, endDate)}
                </ul>
            </div >
        )
    }

    return (
        <>
            <div className={styles.block}>
                <div className={styles.title} id="fiscalYear">
                    {`FY${fiscalYear} - Fiscal Year Calendar`}
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.fiscalFormat} id="fiscalFormat">
                    {fiscalFormat?.includes("Annual Calendar") ? "Annual Calendar" : `${fiscalFormat} Calendar`}
                </div>
            </div>
            <div className={styles.quarters}>
                <div className={styles.row}>
                    <div className={styles.col} id="quarterOne">
                        <div className={styles.quarterName}>
                            <p>Q1</p>
                        </div>
                        {quarters.map(q => {
                            if (q.quarter === 'One') {
                                return q.previewFiscalQuarterDataModel.map(qdm => {
                                    return <DisplayDays startDate={qdm.startDate} endDate={qdm.endDate} fiscalMonth={qdm.fiscalMonth}
                                        key={qdm.startDate + qdm.endDate} />
                                })
                            }
                            return null
                        })}

                    </div>
                    <span className={styles.verticalLineRetail} />
                    <div className={styles.col} id="quarterTwo">
                        <div className={styles.quarterName}>
                            <p>Q2</p>
                        </div>
                        {quarters.map(q => {
                            if (q.quarter === 'Two') {
                                return q.previewFiscalQuarterDataModel.map(qdm => {
                                    return <DisplayDays startDate={qdm.startDate} endDate={qdm.endDate} fiscalMonth={qdm.fiscalMonth}
                                        key={qdm.startDate + qdm.endDate} />
                                })
                            }
                            return null
                        })}
                    </div>
                    <span className={styles.verticalLineRetail} />
                    <div className={styles.col} id="quarterThree">
                        <div className={styles.quarterName}>
                            <p>Q3</p>
                        </div>
                        {quarters.map(q => {
                            if (q.quarter === 'Three') {
                                return q.previewFiscalQuarterDataModel.map(qdm => {
                                    return <DisplayDays startDate={qdm.startDate} endDate={qdm.endDate} fiscalMonth={qdm.fiscalMonth}
                                        key={qdm.startDate + qdm.endDate} />
                                })
                            }
                            return null
                        })}
                    </div>
                    <span className={styles.verticalLineRetail} />
                    <div className={styles.col} id="quarterFour">
                        <div className={styles.quarterName}>
                            <p>Q4</p>
                        </div>
                        {quarters.map(q => {
                            if (q.quarter === 'Four') {
                                return q.previewFiscalQuarterDataModel.map(qdm => {
                                    return <DisplayDays startDate={qdm.startDate} endDate={qdm.endDate} fiscalMonth={qdm.fiscalMonth}
                                        key={qdm.startDate + qdm.endDate} />
                                })
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}




