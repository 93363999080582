import React, { FC, useState } from 'react'
import { ActionIcon, Modal, ToastId, Toasts } from 'navex-react'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useDeleteDateDiff } from './DateDiffApi'

interface IProps {
    DateDiffId: number | undefined,
    FromDateTable: string,
    FromDateColumn: string,
    ToDateTable: string,
    ToDateColumn: string,
    ColumnDisplayName: string,
    Id: number,
    Duration: string,
    setAddDateDiff: (value: boolean) => void,
    reFetchData: () => void,
    setDateDiffId: (customId: number | undefined) => void,
}

export const DateDiffListItem: FC<IProps> = (props) => {
    const [showModalDeleteDateDiff, setShowModalDeleteDateDiff] = useState(false)
    let toastId: ToastId = 0
    const [deleteDateDiff] = useDeleteDateDiff({
        onSuccess: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.success(`Deleted Successfully`)
            }
            props.reFetchData()
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })
    const handleEditDateDiffBtnLinkClick = () => {
        props.setAddDateDiff(true)
        props.setDateDiffId(props.Id)
    }

    const handleDateDiffDelete = () => {
        if (props.Id !== undefined) {
            deleteDateDiff({ Id: props.Id })
        }
    }

    return (
        <tr key={props.DateDiffId}>
            <td>
                {props.FromDateTable}
            </td>
            <td>
                {props.FromDateColumn}
            </td>
            <td>
                {props.ToDateTable}
            </td>
            <td>
                {props.ToDateColumn}
            </td>
            <td>
                {props.ColumnDisplayName}
            </td>
            <td>
                {props.Duration}
            </td>
            <td>
                <ActionIcon id={props.Id + "_btnEditDateDiff"} icon={faPencilAlt} toolTip="Edit" onClick={handleEditDateDiffBtnLinkClick} />
                <ActionIcon id={props.Id + "_btnDeleteDateDiff"} icon={faTrashAlt} toolTip="Delete" onClick={() => setShowModalDeleteDateDiff(true)} />
                <Modal id={props.Id + "_modalDeleteDateDiff"} modalType={("BASIC")}
                    headerText={props.ColumnDisplayName}
                    show={showModalDeleteDateDiff}
                    okButtonText={"Delete"}
                    message={
                        `Delete ${props.ColumnDisplayName} from Custom DateDiff table.`
                    }
                    onClickedOk={handleDateDiffDelete}
                    onClickedCancel={() => setShowModalDeleteDateDiff(false)}
                />
            </td>
        </tr>
    )
}