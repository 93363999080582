import React, { useEffect } from 'react'
import { buildUrlForClientPlatformTopNav, getCustomerKey, buildUrlForClientSessionStatusUi, buildUrlForLogoutV3 } from '../../Api/urls'
import { IPlatformTopNavOptions } from '../../Interfaces/IPlatformTopNavOptions'
import { hiddenIframeToClearBirstCookiesOnLogout } from '../../Utils/utils'
import { ISessionStatusUiOptions } from '../../Interfaces/ISessionStatusUiOptions'
import { getKeycloakAccessToken } from '../../Api/api'

declare const Navex: any

interface IProps {
  puid: string | undefined,
  accessToken: string | undefined,
  useAuth0AsmCutover: boolean | undefined,
  tenantId: string | undefined
}

export const HeaderContents = (props: IProps) => {

  let platformTopNavOptions: IPlatformTopNavOptions | undefined = undefined
  let sessionStatusUiOptions: ISessionStatusUiOptions | undefined = undefined

  if (!!props.puid && !!props.accessToken) {

    const authorizationToken = props.accessToken.replace('Bearer ', '')

    platformTopNavOptions = {

      divId: 'platform-top-nav',
      clientKey: getCustomerKey(),
      lang: "en-US",
      puid: props.puid,

      onGetAuthTokenFromHostApp: () => {
        return authorizationToken
      },

      onClickedSignout: (e) => {
        e.preventDefault()

        hiddenIframeToClearBirstCookiesOnLogout()

        setTimeout(() => {
          localStorage.removeItem("insightsActive")
          window.location.href = buildUrlForLogoutV3(props.tenantId).toString()
        }, 500)

      },

      // If this is set to true, but the user doesn't have access to PolicyTech,
      // then the Tasks button will be hidden
      showTasks: false,

      // Set this to true if developers want to see fake user initials and fake waffle menu items
      // TODO: Remove this line or keep it set to false for production deployments
      devMode: false,
    }

    sessionStatusUiOptions = {
      divId: 'session-status-ui',
      lang: 'en-US',

      logoutCallback: () => {
        hiddenIframeToClearBirstCookiesOnLogout()

        setTimeout(() => {
          localStorage.removeItem("insightsActive")
          window.location.href = buildUrlForLogoutV3(props.tenantId).toString()
        }, 500)
      },
      devMode: false,
    }
  }

  useEffect(() => {
    const onMount = async () => {

      await loadPlatformTopNavScript()
      Navex.PlatformTopNav.inject(platformTopNavOptions)

      if (props.useAuth0AsmCutover) {
        await loadSessionStatusUiScript()
        Navex.SessionStatusUI.inject(sessionStatusUiOptions)

      }
    }

    onMount()

    return function unMount() { }

  }, []) // eslint-disable-line

  const loadPlatformTopNavScript = () => {
    return new Promise<void>(resolve => {
      const scriptUrl = buildUrlForClientPlatformTopNav().toString()
      const scriptTag = document.createElement('script')
      scriptTag.id = 'platform-top-nav-script'
      scriptTag.src = scriptUrl
      scriptTag.async = true
      scriptTag.onload = () => { resolve() }
      scriptTag.onerror = () => { console.error(`Could not load ${scriptUrl}`) }
      document.head.appendChild(scriptTag)
    })
  }

  const loadSessionStatusUiScript = () => {
    return new Promise<void>(resolve => {
      const scriptUrl = buildUrlForClientSessionStatusUi().toString()
      const scriptTag = document.createElement('script')
      scriptTag.id = 'session-status-ui-script'
      scriptTag.src = scriptUrl
      scriptTag.async = true
      scriptTag.onload = () => { resolve() }
      scriptTag.onerror = () => { console.error(`Could not load ${scriptUrl}`) }
      document.head.appendChild(scriptTag)
    })
  }

  if (!platformTopNavOptions && !sessionStatusUiOptions) {
    return null
  }
  else if (platformTopNavOptions && !sessionStatusUiOptions) {
    return (
      <div id={platformTopNavOptions!.divId}>
      </div>
    )
  }
  else if (sessionStatusUiOptions && !platformTopNavOptions) {
    return (
      <div id={sessionStatusUiOptions!.divId}>
      </div>
    )
  }

  return (
    <div>
      <div id={platformTopNavOptions!.divId}>
      </div>
      <div id={sessionStatusUiOptions!.divId}>
      </div>
    </div>
  )
}