/* eslint-disable */
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Spinner } from '../Shared/Spinner'
import { computeBirstDashboardRoutePath } from '../InsightsApp/dashboardLeftNavNodes'
import { BirstIntegrationsActionCreators, ILeftNavClickEvent } from '../../Reducers/BirstIntegrationsReducer'
import {
    getFiltersFromQueryParams, buildQueryFromBirstFiltersArray,
    IDrillAcrossEventData, ISetFiltersEventData, IBirstFilter, getDashboardsFromPath
} from './birstUtils'
import { createDashboard, ICreateDashboardProps } from './createDashboard'
import { useGetBirstUrl } from './BirstEmbeddedDashboardApi'
import { IDashboardCollection, IGlobalState } from '../../Models/GlobalState'
import { InsightsActionCreators } from '../../Reducers/InsightsReducer'
import { BirstConfig } from '../../Assets/js/birst_embed_7_3_3'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'
import { pathPrefix } from '../../Api/urls'

interface IStateDependentProps {
    leftNavEventHandled: boolean,
    hasSpaceIdchanged: boolean,
    leftNavEventData: ILeftNavClickEvent,
    dashboardCollections?: IDashboardCollection[],
    toggles: TOGGLENAME[],
}

interface IPropsThatDispatch {
    setCurrentPage: (value: string) => void
    handledLeftNavClickEvent: () => void
}

type props = IStateDependentProps & IPropsThatDispatch

const handleDrillAcrossEventData = (drillAcross: IDrillAcrossEventData) => {
    document.title =  "NAVEX One -" 
    document.title = document.title +" "+ drillAcross.page
    window.history.pushState("", drillAcross.page, `${pathPrefix}${computeBirstDashboardRoutePath(drillAcross.dashboard, drillAcross.page)}`)
}

const handleSetFiltersEventData = ({ filters }: ISetFiltersEventData) => {
    const filtersQuery = buildQueryFromBirstFiltersArray(filters)
    const titlePrefix = "NAVEX One -"
    
    const pageName = document.title.replace(titlePrefix, "")
    window.history.pushState("", pageName, `${window.location.pathname}?filters=${filtersQuery}`)
}

export const BirstEmbeddedDashboardUnwrappedForTesting: FC<props> = (props) => {
    const [iFrameLoading, setIframeLoading] = useState(false)

    const [getBirstUrl, { birstUrlLoading, birstUrl, errors }] = useGetBirstUrl()

    const hasError = errors.length
    
    const { hasSpaceIdchanged, leftNavEventHandled, leftNavEventData, setCurrentPage, handledLeftNavClickEvent } = props

    const BirstMessageHandler = ({ operation, drillAcross, filters }: {
        operation?: string, drillAcross?: IDrillAcrossEventData, filters?: IBirstFilter[]
    }) => {
        switch (operation) {
            case "initialized":
                setIframeLoading(false)
                break
            case "drillToDashboard":
                if (drillAcross) {
                    handleDrillAcrossEventData(drillAcross)
                    setCurrentPage(drillAcross.page)
                }
                break
            case "setFilters":
                if (filters) {
                    handleSetFiltersEventData({ filters })
                }
                break
        }
    }
    const init = () => {
        let spaceId = props.leftNavEventData.spaceId || lookUpSpaceId()

        if (spaceId) {
            setIframeLoading(false)
            getBirstUrl({ spaceId, showEditToolbar: false })
        }

    }

    useEffect(() => {
        if (!birstUrl && !birstUrlLoading && !hasError) {
            init()
        }
    }, [birstUrl, birstUrlLoading, hasError])

    useEffect(() => {
        if (!leftNavEventHandled) {
            if (hasSpaceIdchanged) {
                init()
            } else if (!iFrameLoading && !birstUrlLoading && !hasError) {
                BirstConfig.postData({
                    operation: 'drillToDashboard',
                    dashboard: leftNavEventData.collectionName,
                    page: leftNavEventData.page
                })

            }
            handledLeftNavClickEvent()
        }
    }, [leftNavEventHandled, hasSpaceIdchanged])

    const lookUpSpaceId = () => {
        const { collectionName } = getDashboardsFromPath(window.location.pathname)

        if (props.dashboardCollections) {
            for (const collection of props.dashboardCollections) {
                if (collection.name === collectionName) {
                    return collection.spaceId
                }
            }
        }
    }

    useEffect(() => {
        if (birstUrl && !iFrameLoading) {
            const filters = getFiltersFromQueryParams(window.location.search)
            const { dashboardName, collectionName } = getDashboardsFromPath(window.location.pathname)

            const dashboardUrl = new URL(birstUrl)
            const options: ICreateDashboardProps = {
                dashboardName: decodeURIComponent(dashboardName),
                collectionName: decodeURIComponent(collectionName),
                filters,
                dashboardUrl
            }

            setIframeLoading(true)
            createDashboard(options)

            const BirstMessageListener = (event: MessageEvent) => {
                if (dashboardUrl.origin === event.origin) {
                    BirstMessageHandler(event.data)
                }
            }

            window.addEventListener("message", BirstMessageListener, false)

            return function cleanup() {
                window.removeEventListener("message", BirstMessageListener)
            }
        }

    }, [birstUrl])

    const showSpinner = (!hasError && (iFrameLoading || birstUrlLoading))
    const styleDiv = {
        width: '100%',
        height: '100%',
        display: (showSpinner || hasError) ? "none" : undefined
    }

    let displayMessage = ""

    if (hasError) displayMessage = "Dashboards are currently unavailable. Please refresh and try again."

    return (
        <>
            <div id="BirstDash" style={styleDiv}></div>
            {showSpinner && <Spinner />}
            <div id="displayMessage" style={{ padding: 20, display: hasError ? undefined : "none" }}><span>{displayMessage}</span></div>
        </>
    )
}

export function mapStateToProps(state: IGlobalState): IStateDependentProps {
    return {
        leftNavEventHandled: state.birstIntegrations.leftNavClickEvent.handled,
        hasSpaceIdchanged: state.birstIntegrations.leftNavClickEvent.hasSpaceIdchanged,
        leftNavEventData: {
            collectionName: state.birstIntegrations.leftNavClickEvent.collectionName,
            spaceId: state.birstIntegrations.leftNavClickEvent.spaceId,
            page: state.birstIntegrations.leftNavClickEvent.page,
            hasSpaceIdchanged: state.birstIntegrations.leftNavClickEvent.hasSpaceIdchanged,
        },
        toggles: state.authentication.toggles || [],
        dashboardCollections: state.authentication.dashboardCollections,        
    }
}

export const mapDispatchToProps = (dispatch: any): IPropsThatDispatch => {
    return {
        setCurrentPage: (value: string) => dispatch(InsightsActionCreators.setCurrentPage(value)),
        handledLeftNavClickEvent: () => dispatch(BirstIntegrationsActionCreators.handledLeftNavClickEvent())
    }
}

export const BirstEmbeddedDashboard = connect(mapStateToProps, mapDispatchToProps)(BirstEmbeddedDashboardUnwrappedForTesting)