import { IAppNavigationUIRoute } from "navex-react"
import { IFeatureConfigurations } from "../../Models/GlobalState"
import { pascalize } from "../../Utils/utils"
import { AdminScreenDefinitions } from "./adminScreenDefinitions"
import { IAdminScreenDefinition } from "./adminScreenTypes"

export interface IAdminLeftNavTree {
    permissions: string[],
    unrecognizedPermissions: string[],
    accessibleScreens: {
        screenName: string,
        becauseOfPermission: string
    }[],
    inaccessibleScreens: string[],
    adminNodes: IAppNavigationUIRoute[],
    dashboardManagementNodes: IAppNavigationUIRoute[]
}

const makeStartingAccumulator = (permissions: string[]): IAdminLeftNavTree => {
    return {
        permissions,
        unrecognizedPermissions: [],
        accessibleScreens: [],
        inaccessibleScreens: [],
        adminNodes: [],
        dashboardManagementNodes: []
    }
}

interface IStringToAnyHash {
    [key: string]: any
}

export const computeAdminNavNodes = (permissions: string[], toggles: string[], featureConfigurations: IFeatureConfigurations[]): IAdminLeftNavTree => {
    const unrecognizedPermissionsHash: IStringToAnyHash = permissions.reduce((acc: IStringToAnyHash, p) => {
        acc[p] = 42
        return acc
    },
        {})

    const features = Array.isArray(featureConfigurations) ? featureConfigurations.map(f => f.featureConfigurationName) : []
    const adminScreenDefinitions = AdminScreenDefinitions(toggles,features)
    const result = Object.keys(adminScreenDefinitions).reduce(
        (acc: IAdminLeftNavTree, screenName: string) => {
            const screenDefinition = adminScreenDefinitions[screenName]
            const nameOfGatingPermission = screenDefinition.gatingPermission || `${screenName}Get`
            const nameOfToggle = screenDefinition.toggleName
            const isToggleEnabled = !nameOfToggle ? true : toggles.includes(nameOfToggle)
            const nameOfFeature = screenDefinition.featureName
            const isFeatureEnabled = !nameOfFeature ? true : features.includes(nameOfFeature)

            recognizePermission(nameOfGatingPermission)
            if (isFeatureEnabled && permissions.includes(nameOfGatingPermission)) {
                acc.accessibleScreens.push({
                    screenName, becauseOfPermission: nameOfGatingPermission
                })
                acc.adminNodes.push(makeAdminNode(screenDefinition))
            }

            else if (isToggleEnabled && permissions.includes(nameOfGatingPermission)) {
                acc.accessibleScreens.push({
                    screenName, becauseOfPermission: nameOfGatingPermission
                })
                acc.adminNodes.push(makeAdminNode(screenDefinition))
            }

            else {
                acc.inaccessibleScreens.push(screenName)
            }

            return acc
        },
        makeStartingAccumulator(permissions)
    )

    result.unrecognizedPermissions = Object.keys(unrecognizedPermissionsHash) || []

    return result

    function recognizePermission(permissionName: string) {
        if (unrecognizedPermissionsHash.hasOwnProperty(permissionName)) {
            delete unrecognizedPermissionsHash[permissionName]
        }
    }
}

const makeAdminNode = (screenDefinition: IAdminScreenDefinition): IAppNavigationUIRoute => {
    return {
        id: computeRoute(screenDefinition),
        title: screenDefinition.title,
        path: computePath(screenDefinition),
        component: screenDefinition.component,
        clickedAction: screenDefinition.clickedAction,
    }
}

const computeRoute = (screenDefinition: IAdminScreenDefinition): string => {
    return `route_${pascalize(screenDefinition.title)}`
}

const computePath = (screenDefinition: IAdminScreenDefinition): string => {
    return `/Admin_${pascalize(screenDefinition.title)}`
}
