import React, { FC, useEffect } from 'react'
import { NoDataError } from '../Shared/Error'
import { IGetCustomColumn } from './CustomColumnsApi'
import { CustomColumnListItem } from './CustomColumnListItem'

interface IProps {
  customColumnInfo: IGetCustomColumn[],
  setIsCalculatedColumnsEdit: (isEdit: boolean) => void,
  reFetchData: () => void,
  setCustomColumnId: (customId: number | undefined) => void,
  columnId: number | undefined,
}

export const CustomColumnsListView: FC<IProps> = (props) => {

  const disabled = props.customColumnInfo.filter(d => d.status === "Processing").length > 0 ? true : false
  useEffect(() => {
    if (props.customColumnInfo.find(cc => cc.status === "Processing"))
      setTimeout(() => {
        props.reFetchData()
      }, window.customFieldPollTimeout)
  }, [])

  return (<>
    <table className="table table-striped table-bordered" id="columnTable">
      <thead>
        <tr style={{ backgroundColor: "#edecec" }}>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Table Name</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Column Name</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Expression</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Data Type</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Decimal Places</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Summarize By</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Status</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>ErrorMsg</th>
          <th style={{ overflow: "hidden", whiteSpace: "nowrap" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          props.customColumnInfo.length > 0
            ?
            (props.customColumnInfo.map(cc => {
              return <CustomColumnListItem key={cc.id} columnId={props.columnId} id={cc.id}
                columnName={cc.columnName} dataType={cc.dataType} decimalPlace={cc.decimalPlaces} expression={cc.expression}
                status={cc.status} summarizeBy={cc.summarizeBy}
                tableName={cc.tableName} errorMsg={cc.errorMsg} disabled={disabled}
                setIsCalculatedColumnsEdit={props.setIsCalculatedColumnsEdit} reFetchData={props.reFetchData} setCustomColumnId={props.setCustomColumnId}
              />
            }))
            :
            (<tr>
              <td colSpan={7}><NoDataError /></td>
            </tr>)
        }
      </tbody>
    </table>
  </>
  )
}