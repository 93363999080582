import React from 'react'
import { DefaultPage } from 'navex-react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { ResetDashboardTabView } from './ResetDashboardTabView'
import { UseTemplateData } from './ResetDashboardV2Api'

export const ResetDashboardV2Route = () => {
    const [{ templateData, loading, status, errors }] = UseTemplateData()

    if (errors.length) return <UnexpectedError />
    if (loading || !status) return <Spinner />

    return (
        <DefaultPage>
            <ResetDashboardTabView {...{ templateData }} />
        </DefaultPage>
    )
} 