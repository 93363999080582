import { useAxiosGet } from '../../Api/useAxios'
import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"

export interface IFavoriteReport {
  reportId: string,
  workspaceId: string,
  name: string,
  embedUrl: string,
  favorite: boolean,
  description: string,
  collection: string,
}

type TUseGetFavoriteReportData = [
  {
      reportInfo: IFavoriteReport[],
      loading: boolean,
      status?: string | number,
      errors: any[],
  },
  () => Promise<AxiosResponse<any> | undefined>
]


export const useGetFavoriteReportsInfo = (): TUseGetFavoriteReportData => {  
  const getFavoritesReportsUrlPath = `${buildServerApiUrl("PowerBi.GetFavoriteReports").toString()}/${getCustomerKey()}`

  const [{ data, loading, errors, status }, reFetchData] = useAxiosGet(
    getFavoritesReportsUrlPath
  )
  let reportInfo
  if (data) {
      reportInfo = data
  }
  return [{ reportInfo, loading, status, errors }, reFetchData]
}
