import React, { FC } from 'react'
import { ActionIcon } from 'navex-react'
import { IFiscalCalendarData, useRetryFiscalCalendar } from './FiscalCalendarApi'
import { FISCALCALENDARSTATUS } from '../../ts/enums/fiscalCalendarStatus'
import { SmallSpinner } from '../Shared/Spinner'
import { faExclamationCircle, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'
import { PopOver } from 'navex-react'
import styles from "./FiscalCalendar.module.scss"
import { Button, ToastId, Toasts } from 'navex-react'

interface IProps {
    fiscalCalendarItem: IFiscalCalendarData
    setIsEdit: (isEdit: boolean) => void,
    reFetch: (silentFetch: boolean) => void,
    setOldFiscalYear: (fiscalYear: any) => void,
    setFiscalFormat: (format: string) => void,
    setStartDate: (date: string | undefined) => void,
    setEndDate: (date: string | undefined) => void,
    setFiscalMonth: (month: any) => void,
    setNewFiscalYear: (month: any) => void,
    setShowModal: (status: boolean) => void,
    setDeleteFiscalYear: (fiscalYear: number) => void,
    startPoll: boolean,
    setStartPoll: (status: boolean) => void,
    checkEligibleForPolling: () => boolean,
    toggle: boolean
}

export const FiscalCalendarListItem: FC<IProps> = (props) => {
    const { fiscalYear, startDate, endDate, fiscalFormat, startMonth, status
        , correlationId, history } = props.fiscalCalendarItem

    const { setIsEdit, setOldFiscalYear, setFiscalFormat, setStartDate, setFiscalMonth,
        setEndDate, setNewFiscalYear, setShowModal, setDeleteFiscalYear, startPoll, checkEligibleForPolling, reFetch, setStartPoll } = props

    const eligibleMonths = [{ date: new Date(startDate), index: new Date(startDate).getMonth() }]

    for (let i = 1; i <= 2; i++) {
        var newDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + i));
        eligibleMonths.push({ date: newDate, index: newDate.getMonth() })
    }

    const handleEditBtnClick = () => {
        setIsEdit(true)
        setOldFiscalYear(fiscalYear)
        setNewFiscalYear(fiscalYear)
        setFiscalFormat(fiscalFormat)
        setStartDate(startDate)
        setEndDate(endDate)
        setFiscalMonth(startMonth)
    }

    const handleDeleteBtnClick = (fiscalYear: number) => {
        setShowModal(true)
        setDeleteFiscalYear(fiscalYear)
    }

    let toastId: ToastId = 0

    const [Retry] = useRetryFiscalCalendar(props.toggle,{
        onSuccess: () => {
            setStartPoll(true)
            reFetch(false)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
            setStartPoll(true)
            reFetch(false)
        }
    })

    const handleRetryBtnClick = (retyrFiscalYear: number) => {
        setNewFiscalYear(retyrFiscalYear);
        Retry({
            fiscalYear: retyrFiscalYear
        })
    }


    return (
        <tr id={fiscalYear + "_year"}>
            <td>{fiscalYear}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
            <td>
                {status === FISCALCALENDARSTATUS.INPROCESS && <>Adding..<SmallSpinner /></>}
                {status === FISCALCALENDARSTATUS.EDITING && <>Editing..<SmallSpinner /></>}
                {status === FISCALCALENDARSTATUS.EDITINGSUCCESS && <>Edited</>}
                {status === FISCALCALENDARSTATUS.SUCCESS && <>Added</>}
                {status === FISCALCALENDARSTATUS.FAILED &&
                    <>
                        {"Adding Failed"}
                        {/* Using reactstrap since ActionIcon cuts off the tooltip text (max-width limited to 200px) */}
                        <span style={{ color: "red", paddingLeft: "3px" }} id={"toolTip_" + fiscalYear}>
                            <FontAwesomeIcon icon={faExclamationCircle} size='lg' style={{ paddingTop: "4px" }} />
                        </span>
                        <UncontrolledTooltip placement="bottom" target={"toolTip_" + fiscalYear}>
                            {`Some error occured, Error Id : ${correlationId}. Please retry.`}
                        </UncontrolledTooltip>
                    </>
                }
                {status === FISCALCALENDARSTATUS.EDITINGFAILED &&
                    <>
                        {"Editing Failed"}
                        {/* Using reactstrap since ActionIcon cuts off the tooltip text (max-width limited to 200px) */}
                        <span style={{ color: "red", paddingLeft: "3px" }} id={"toolTip_" + fiscalYear}>
                            <FontAwesomeIcon icon={faExclamationCircle} size='lg' style={{ paddingTop: "4px" }} />
                        </span>
                        <UncontrolledTooltip placement="bottom" target={"toolTip_" + fiscalYear}>
                            {`Some error occured, Error Id : ${correlationId}. Please retry.`}
                        </UncontrolledTooltip>
                    </>
                }
                {status === FISCALCALENDARSTATUS.DELETING && <>Deleting..<SmallSpinner /></>}
                {status === FISCALCALENDARSTATUS.DELETINGFAILED &&
                    <>
                        {"Deleting Failed"}
                        {/* Using reactstrap since ActionIcon cuts off the tooltip text (max-width limited to 200px) */}
                        <span style={{ color: "red", paddingLeft: "3px" }} id={"toolTip_" + fiscalYear}>
                            <FontAwesomeIcon icon={faExclamationCircle} size='lg' style={{ paddingTop: "4px" }} />
                        </span>
                        <UncontrolledTooltip placement="bottom" target={"toolTip_" + fiscalYear}>
                            {`Some error occured, Error Id : ${correlationId}. Please retry.`}
                        </UncontrolledTooltip>
                    </>
                }
            </td>
            <td>{
                history.length > 0 ?
                    <div>{history[0]}
                        {
                            history.length > 1 ?
                                <PopOver id={`${fiscalYear}_popOver`} size="large" className={styles.popOver}>
                                    <label role='alert' aria-labelledby={`${fiscalYear}_popOver`}>
                                        {history.slice(1).map((x, index) => <span key={index}>{x}{<br />}</span>)}
                                    </label>
                                </PopOver> : null}
                    </div> : null
            }</td>
            <td>
                        <div>
                            {
                                (status === FISCALCALENDARSTATUS.INPROCESS) &&
                                (startPoll && checkEligibleForPolling() &&
                                    <>
                                            <span style={{ paddingRight: "0px" }}>
                                                <Button purpose="primary"
                                                    id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                    disabled={true}
                                                >Retry</Button>
                                            </span>

                                        <span style={{ paddingLeft: "20px" }}>
                                            <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                                disabled={true} />
                                        </span>
                                        {
                                            <span style={{ paddingLeft: "30px" }} >
                                                <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                                    disabled={true} />
                                            </span>
                                        }

                                    </>)
                            }
                            {
                                (status === FISCALCALENDARSTATUS.SUCCESS || status === FISCALCALENDARSTATUS.EDITINGSUCCESS) &&
                                (startPoll && checkEligibleForPolling() ?
                                    <>
                                        <span style={{ paddingLeft: "75px" }}>
                                            <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                                disabled={true} />
                                        </span>
                                        {
                                            <span style={{ paddingLeft: "30px" }}>
                                                <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                                    disabled={true} />
                                            </span>}
                                    </> :
                                    <>
                                        <span style={{ paddingLeft: "75px" }}>
                                            <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                                onClick={handleEditBtnClick} />
                                        </span>
                                        {
                                            <span style={{ paddingLeft: "30px" }}>
                                                <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                                    onClick={() => handleDeleteBtnClick(fiscalYear)} />
                                            </span>}
                                    </>)
                            }
                            {
                                (status === FISCALCALENDARSTATUS.FAILED) &&
                                (startPoll && checkEligibleForPolling() ?
                                        <span style={{ paddingRight: "0px" }}>
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                disabled={true}
                                            >Retry</Button>
                                        </span> :
                                        <span style={{ paddingRight: "0px" }}>
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                onClick={() => handleRetryBtnClick(fiscalYear)}
                                            >Retry</Button>
                                        </span>)
                            }

                            {status === FISCALCALENDARSTATUS.FAILED &&
                                <span style={{ paddingLeft: "20px" }}>
                                    <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                        disabled={true} />
                                </span>
                            }

                            {status === FISCALCALENDARSTATUS.FAILED &&
                                (startPoll && checkEligibleForPolling() ?
                                    <span style={{ paddingLeft: "30px" }}>
                                        <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                            disabled={true} />
                                    </span> :

                                    <span style={{ paddingLeft: "30px" }}>
                                        <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                            onClick={() => handleDeleteBtnClick(fiscalYear)} />
                                    </span>)
                            }

                            {
                                (status === FISCALCALENDARSTATUS.EDITING) &&
                                (startPoll && checkEligibleForPolling() &&
                                    <>
                                            <span style={{ paddingLeft: "60px" }} >
                                                <Button purpose="primary"
                                                    id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                    disabled={true}
                                                >Retry</Button>
                                            </span>
                                            <span style={{ paddingLeft: "19px" }}>
                                                <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                                    disabled={true} />
                                            </span>
                                    </>)
                            }


                            {
                                status === FISCALCALENDARSTATUS.EDITINGFAILED &&
                                (startPoll && checkEligibleForPolling() ?
                                        <span style={{ paddingLeft: "60px" }} >
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                disabled={true}
                                            >Retry</Button>
                                        </span> :
                                        <span style={{ paddingLeft: "60px" }} >
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                onClick={() => handleRetryBtnClick(fiscalYear)}
                                            >Retry</Button>
                                        </span>
                                )
                            }

                            {status === FISCALCALENDARSTATUS.EDITINGFAILED &&
                                (startPoll && checkEligibleForPolling() ?

                                    <span style={{ paddingLeft: "18px" }}>
                                        <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                            disabled={true} />
                                    </span> :
                                    <span style={{ paddingLeft: "18px" }}>
                                        <ActionIcon id={fiscalYear + "_btnDeleteFiscalCalendar"} icon={faTrashAlt} toolTip="Delete"
                                            onClick={() => handleDeleteBtnClick(fiscalYear)} />
                                    </span>)
                            }

                            {
                                (status === FISCALCALENDARSTATUS.DELETING) &&
                                (startPoll && checkEligibleForPolling() &&
                                    <>

                                        <span style={{ paddingLeft: "74px" }} >
                                            <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                                disabled={true} />
                                        </span>
                                            <span style={{ paddingLeft: "18px" }}>
                                                <Button purpose="primary"
                                                    id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                    disabled={true}
                                                >Retry</Button>
                                            </span>
                                    </>)
                            }

                            {
                                status === FISCALCALENDARSTATUS.DELETINGFAILED &&
                                <span style={{ paddingLeft: "74px" }} >
                                    <ActionIcon id={fiscalYear + "_btnEditFiscalCalendar"} icon={faPencilAlt} toolTip="Edit"
                                        disabled={true} />
                                </span>
                            }

                            {status === FISCALCALENDARSTATUS.DELETINGFAILED &&
                                (startPoll && checkEligibleForPolling() ?
                                        <span style={{ paddingLeft: "18px" }}>
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                disabled={true}
                                            >Retry</Button>
                                        </span> :
                                        <span style={{ paddingLeft: "18px" }}>
                                            <Button purpose="primary"
                                                id={fiscalYear + "_btnRetryFiscalCalendar"}
                                                onClick={() => handleRetryBtnClick(fiscalYear)}
                                            >Retry</Button>
                                        </span>)
                            }
                        </div>
            </td>
            <td>

            </td>
        </tr>
    )
}