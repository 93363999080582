import React from "react"

export const Spinner = () => (<div className="loading-panel">
    <div className="loader" title="Loading..."></div>
    <span className="sr-only">loading</span>
</div>
)

export const SmallSpinner = () => (<div id="smallSpinner"
    className="spinner-border spinner-border-sm text-primary" style={{ verticalAlign: "middle" }}>
</div>)

export const ButtonSpinner = () => (<div id="buttonSpinner"
    className="spinner-border spinner-border-sm text-primary" style={{ verticalAlign: "middle", height: "18px", width: "18px" }}>
</div>)

