import { IUseAxiosOptions, useAxiosPost } from '../../Api/useAxios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { AxiosResponse } from 'axios'
import { useGet } from '../../Api/useFetch'

interface IUpsertFiscalCalendar {
    customerKey: string,
    beginDate: string,
    endDate: string,
    firstMonth: number,
    fiscalFormat: string,
    oldFiscalYear: number,
    fiscalYear: number
}

type TUpsertFiscalCalendar = Omit<IUpsertFiscalCalendar, "customerKey">

type TUseUpsertFiscalCalendar = [
    (payload: TUpsertFiscalCalendar) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]



export const useUpsertFiscalCalendar = (toggle:boolean,options?: IUseAxiosOptions<IUpsertFiscalCalendar>): TUseUpsertFiscalCalendar => {

    const UpsertFiscalCalendarUrl =  toggle?`${buildServerApiUrl("FiscalCalendar.UpsertFiscalCalendar","v2").toString()}`
                                           :`${buildServerApiUrl("FiscalCalendar.UpsertFiscalCalendar").toString()}`;

    const [execute, { posting, status, data, errors }] = useAxiosPost<IUpsertFiscalCalendar>(
        UpsertFiscalCalendarUrl, {}, options
    )

    const Upsert = async (payload: TUpsertFiscalCalendar) => {
        const res = await execute({ customerKey:getCustomerKey(), ...payload })
        return res
    }

    return [Upsert, { posting, status, data, errors }]
}

interface IRetryFiscalCalendar {
    customerKey: string,
    fiscalYear: number
}

type TRetryFiscalCalendar = Omit<IRetryFiscalCalendar, "customerKey">

type TUseRetryFiscalCalendar = [
    (payload: TRetryFiscalCalendar) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]



export const useRetryFiscalCalendar = (toggle:boolean,options?: IUseAxiosOptions<IRetryFiscalCalendar>): TUseRetryFiscalCalendar => {
     const RetryFiscalCalendarUrl = toggle?`${buildServerApiUrl("FiscalCalendar.RetryFiscalCalendar","v2").toString()}`
                                          :`${buildServerApiUrl("FiscalCalendar.RetryFiscalCalendar").toString()}`;

    const [execute, { posting, status, data, errors }] = useAxiosPost<IRetryFiscalCalendar>(
        RetryFiscalCalendarUrl, {}, options
    )

    const Retry = async (payload: TRetryFiscalCalendar) => {
        const res = await execute({ customerKey:getCustomerKey(), ...payload })
        return res
    }

    return [Retry, { posting, status, data, errors }]
}

export interface IFiscalCalendarData {
    fiscalYear: number,
    startDate: string,
    endDate: string,
    fiscalFormat: string,
    modifiedDate: string,
    modifiedBy: string,
    startMonth: string,
    status: string,
    correlationId: string,
    history: Array<string>
}

type TUseGetFiscalCalendar = [
    {
        fiscalCalendarItems: IFiscalCalendarData[],
        loading: boolean,
        status?: string | number,
        errors: any[],
    },
    (silentFetch: boolean) => Promise<AxiosResponse<any> | undefined>
]


export const useGetFiscalCalendar = (toggle:boolean): TUseGetFiscalCalendar => {
const getFiscalCalendarUrl = toggle?`${buildServerApiUrl("FiscalCalendar.Get","v2").toString()}/${getCustomerKey()}`
                                   :`${buildServerApiUrl("FiscalCalendar.Get").toString()}/${getCustomerKey()}`;

    const [{ data, loading, errors, status }, reFetch] = useGet( // using useFetch custom hook to get data and polling
        getFiscalCalendarUrl,
    )

    let fiscalCalendarItems = []
    if (data) {
        fiscalCalendarItems = data.fiscalCalendarData
    }

    return [{ fiscalCalendarItems, loading, status, errors }, reFetch]
}

export interface IQuarterData {
    fiscalMonth: string,
    startDate: string,
    endDate: string
}

export interface IQuarters {
    quarter: string,
    quarterStartDate: string,
    quarterEndDate: string
    previewFiscalQuarterDataModel: IQuarterData[]
}

interface IDeleteFiscalCalendar {
    customerKey: string,
    fiscalYear: number
}

type TDeleteFiscalCalendar = Omit<IDeleteFiscalCalendar, "customerKey">

type TUseDeleteFiscalCalendar = [
    (payload: TDeleteFiscalCalendar) => Promise<AxiosResponse<any> | undefined>,
    {
        data: any,
        posting: boolean,
        status?: string | number,
        errors: any[],
    }
]



export const useDeleteFiscalCalendar = (toggle:boolean,options?: IUseAxiosOptions<IDeleteFiscalCalendar>): TUseDeleteFiscalCalendar => {
    
    const DeleteFiscalCalendarUrl =  toggle?`${buildServerApiUrl("FiscalCalendar.DeleteCalendar","v2").toString()}` 
                                           :`${buildServerApiUrl("FiscalCalendar.DeleteCalendar").toString()}`;

     const [execute, { posting, status, data, errors }] = useAxiosPost<IDeleteFiscalCalendar>(
        DeleteFiscalCalendarUrl, {}, options
    )

    const Delete = async (payload: TDeleteFiscalCalendar) => {
        const res = await execute({ customerKey:getCustomerKey(), ...payload })
        return res
    }

    return [Delete, { posting, status, data, errors }]
}
