import React, { FC, useEffect, useState } from "react"
import { Button, ActionIcon, ModalBasicButton, Checkbox, Toasts, ToastId, Accordion, Card, Modal } from "navex-react"
import { ICollection, useEnableDashboard, useDisableDashboard, useDeleteDashboard, useAssignDashboard } from "./ViewDashboardApi"
import { faCaretDown, faCaretUp, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { pascalize } from "../../Utils/utils"
import { NoDataError } from "../Shared/Error"
import styles from "./ViewDashboard.module.scss"
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import CreateDashboard from "./CreateDashboard"
import { getCustomerKey, pathPrefix } from "../../Api/urls"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { SmallSpinner } from "../Shared/Spinner"
import EnableDashboard from "./EnableDashboard"
import { insightAppHistory } from '../InsightsApp/InsightsApp'

interface IProps {
  productName: string,
  collections: ICollection[],
  spaceId: string,
  startPoll: () => void,
  toggles: string[],
  newlyCreatedDashboard: string,
  setNewlyCreatedDashboard: (arg: string) => void,
}

interface newProps {
  id: number,
  name: string,
  status: string,
  isVisible: boolean | null,
  dashboardStatus: string | null,
  fromCollectionName: string
  failedCorrelationId: string | null,
  collectionName: string
}

interface ICheckboxState {
  [dashId: number]: boolean
}

let toastId: ToastId = 0



export const computeEditDashboardRoutePath = (productName: string, collectionName: string, page: string, filters?: string) => {
  const query = !filters ? "" : `?filters=${filters}`
  return `/DashboardManagement_EditDashboard/${encodeURIComponent(productName)}_${encodeURIComponent(collectionName)}_${encodeURIComponent(page)}${query}`
}

const computeScreenStateForIsVisibileItems = (coll: ICollection[]): ICheckboxState => {
  let allItems: ICheckboxState = {}
  coll.forEach(collection => collection.dashboards.forEach(dash => {
    if (dash.status === "Live" && (dash.dashboardStatus === "Enabling" || dash.dashboardStatus === "Enabled" || dash.dashboardStatus === "Failed")) {
      allItems[dash.id] = dash.isVisible!
    }
  }))
  return allItems
}

const sortingForDraft = (collections: ICollection[], sortOrder: string) => {
  let allDashboards: newProps[] = []
  collections.map(coll => coll.dashboards.map(dash =>
    allDashboards.push({
      id: dash.id,
      name: dash.name,
      status: dash.status,
      isVisible: dash.isVisible,
      dashboardStatus: dash.dashboardStatus,
      fromCollectionName: dash.fromCollectionName,
      failedCorrelationId: dash.failedCorrelationId,
      collectionName: coll.collectionName
    })
  ))
  if (sortOrder === 'asc')
    allDashboards.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
  if (sortOrder === 'desc')
    allDashboards.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? -1 : 1)
  return (allDashboards.filter(dash => dash.status === "In Development" || dash.dashboardStatus === null))
}

const sortingForEnabled = (collections: ICollection[], sortOrder: string, sortOrderForStatus: string) => {
  let allDashboards: newProps[] = []
  collections.map(coll => coll.dashboards.map(dash =>
    allDashboards.push({
      id: dash.id,
      name: dash.name,
      status: dash.status,
      isVisible: dash.isVisible,
      dashboardStatus: dash.dashboardStatus,
      fromCollectionName: dash.fromCollectionName,
      failedCorrelationId: dash.failedCorrelationId,
      collectionName: coll.collectionName
    })
  ))
  if (sortOrder === 'asc')
    allDashboards.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
  if (sortOrder === 'desc')
    allDashboards.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? -1 : 1)
  allDashboards.filter(dash => dash.status === "Live" && (dash.dashboardStatus !== null))
  if (sortOrderForStatus === 'asc')
    allDashboards.sort((a, b) => (a.dashboardStatus! > b.dashboardStatus!) ? 1 : -1)
  if (sortOrderForStatus === 'desc')
    allDashboards.sort((a, b) => (a.dashboardStatus! > b.dashboardStatus!) ? -1 : 1)
  return (allDashboards.filter(dash => dash.status === "Live" && (dash.dashboardStatus !== null)))

}

const DashboardViewRow: FC<IProps> = (props) => {
  const [isVisibleState, setIsVisibleState] = useState(computeScreenStateForIsVisibileItems(props.collections))
  const [modalMessage, setModalMessage] = useState("no message")
  const [modalHeaderMessage, setModalHeaderMessage] = useState("no message")
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)
  const [showCreateDashModal, setshowCreateDashModal] = useState(false)
  const [activeCardIndex, setActiveCardIndex] = useState(-1)
  const [sortOrderForDraft, setSortOrderForDraft] = useState('')
  const [sortOrderForEnabled, setSortOrderForEnabled] = useState('')
  const [sortOrderForStatus, setSortOrderForStatus] = useState('')

  const countPublishedDashboards = (coll: ICollection[]) => {
    let val: number = 0
    coll.forEach(collection => collection.dashboards.forEach(dash => {
      if (dash.status === "Live" && (dash.dashboardStatus === "Enabling" || dash.dashboardStatus === "Enabled" || dash.dashboardStatus === "Failed")) {
        val = val + 1
      }
    }
    ))
    return val
  }

  const countDraftDashboards = (coll: ICollection[]) => {
    let val: number = 0
    coll.forEach(collection => collection.dashboards.forEach(dash => {
      if (dash.status === "In Development" || dash.dashboardStatus === null) {
        val = val + 1
      }
    }
    ))
    return val
  }

  const [enable] = useEnableDashboard({
    onSuccess: (_correlationId) => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Enabling the dashboard")
      }
      setActiveCardIndex(1)
      props.startPoll()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Enable dashboard failed, please try again later.")
      }
    }
  })

  const [disable] = useDisableDashboard({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Disabling the dashboard")
      }
      setActiveCardIndex(0)
      props.startPoll()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Disable dashboard failed, please try again later!")
      }
    }
  })

  const [deleteDash] = useDeleteDashboard({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success("Deleting the dashboard")
      }
      props.startPoll()
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Delete dashboard failed, please try again later!")
      }
    }
  })

  const [assignDash] = useAssignDashboard({
    onError: (_, ctx) => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("Assigning the dashboard failed, please try again later!")
      }
      var visibleState = { ...isVisibleState }
      visibleState[ctx!.dashboardId] = !ctx!.isAssignable
      setIsVisibleState(visibleState)
    }
  })

  const onClickEditDashboard = (productName: string, collectionName: string, page: string) => {      
      insightAppHistory.push(`${pathPrefix}${computeEditDashboardRoutePath(productName, collectionName, page)}`)

  }

  const onClickEnableDashboardWithAssignmentForRetry = (collectionName: string, dashboardName: string, status: boolean) => {
    enable({
      customerKey: getCustomerKey(),
      spaceId: props.spaceId,
      collectionName: collectionName,
      dashboardName: dashboardName,
      isVisible: status
    })
  }

  const onClickDisableDashboard = (dashboardId: number) => {
    disable({
      customerKey: getCustomerKey(),
      spaceId: props.spaceId,
      dashboardId: dashboardId,
    })
  }

  const onClickDeleteDashboard = (collectionName: string, dashboardName: string, dashboardId: number) => {
    let dashId: number | null = null

    if (dashboardId !== 0) {
      dashId = dashboardId
    }

    deleteDash({
      customerKey: getCustomerKey(),
      spaceId: props.spaceId,
      collectionName: collectionName,
      name: dashboardName,
      dashboardId: dashId,
    })
  }

  const onClickAssignablePublishedDashboard = () => {
    assignDash({
      customerKey: getCustomerKey(),
      dashboardId: selectedId,
      isAssignable: isVisibleState[selectedId],
    })
    setShowModal(false)
  }

  const onClickAssignableCancel = () => {
    const visibleState = { ...isVisibleState }
    visibleState[selectedId] = !visibleState[selectedId]
    setIsVisibleState(visibleState)
    setShowModal(false)
  }

  const changeAssign = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dashId = parseInt(event.target.value, 10)
    const isChecked = event.target.checked
    setSelectedId(dashId)
    const visibleState = { ...isVisibleState }
    visibleState[dashId] = isChecked
    setIsVisibleState(visibleState)
    if (event.target.checked) {
      setModalMessage("This will make dashboard available for assignment from roles")
      setModalHeaderMessage(" Make Dashboard Assignable?")
    }
    else {
      setModalMessage("This will remove dashboard from any existing role assignments")
      setModalHeaderMessage(" Make Dashboard Not Assignable?")
    }
    setShowModal(true)
  }

  const retryModalMessage = (id: number) => (
    <>
      Do you want to assign this dashboard to Insights Roles?
      <Checkbox id="checkboxForEnable"
      label="Assignable to roles"
      onChange={changeAssignRetry}
      checked={isVisibleState[id]}
      className={styles.enablecheckbox}
      value={id.toString()} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}      />
    </>
  )

  const changeAssignRetry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dashId = parseInt(event.target.value, 10)
    const isChecked = event.target.checked
    const visibleState = { ...isVisibleState }
    visibleState[dashId] = isChecked
    setIsVisibleState(visibleState)
  }

  const changeActiveCardIndex = (newActiveCardIndex: number) => {
    setActiveCardIndex(newActiveCardIndex)
  }

  const setInAscendingOrderForDraftDash = () => {
    setSortOrderForDraft('asc')
  }

  const setInDescendingOrderForDraftDash = () => {
    setSortOrderForDraft('desc')
  }

  const setInAscendingOrderForEnabledDash = () => {
    setSortOrderForStatus('')
    setSortOrderForEnabled('asc')
  }

  const setInDescendingOrderForEnabledDash = () => {
    setSortOrderForStatus('')
    setSortOrderForEnabled('desc')
  }

  const setInAscendingOrderForStatus = () => {
    setSortOrderForStatus('asc')
    setSortOrderForEnabled('')
  }

  const setInDescendingOrderForStatus = () => {
    setSortOrderForStatus('desc')
    setSortOrderForEnabled('')
  }

  useEffect(() => {
    setIsVisibleState(computeScreenStateForIsVisibileItems(props.collections))
    sortingForDraft(props.collections, sortOrderForDraft)
    sortingForEnabled(props.collections, sortOrderForEnabled, sortOrderForStatus)
  }, [props.collections, sortOrderForDraft, sortOrderForEnabled, sortOrderForStatus])

  const draftDashboards = sortingForDraft(props.collections, sortOrderForDraft)
  const enabledDashboards = sortingForEnabled(props.collections, sortOrderForEnabled, sortOrderForStatus)

  return (<>
    <Accordion id="Accordion" activeCardIndex={activeCardIndex} onActiveCardChange={changeActiveCardIndex}>
      <Card id={`${props.productName}_devCollection`}
        actions={
          <>
            <ActionIcon id='CreateDashboard' toolTip={'Create Dashboard'} icon={faPlus} onClick={() => setshowCreateDashModal(true)} />
            <CreateDashboard {...{
              showCreateDashModal, setshowCreateDashModal, productName: props.productName,
              spaceId: props.spaceId, startPoll: props.startPoll, toggles: props.toggles, setNewlyCreatedDashboard: props.setNewlyCreatedDashboard
            }} />
          </>
        }
        title="Draft Dashboards" open={true} collapsible={true} badgeNumber={countDraftDashboards(props.collections)}>
        <table className="table table-striped table-bordered" id="draftTable">
          <thead>
            <tr>
              <th style={{ width: "25%" }} >Dashboard Name<>
                {
                  sortOrderForDraft === 'desc' ?
                    <ActionIcon
                      id="descendingOrderForDraftDash"
                      icon={faCaretDown}
                      toolTip=""
                      purpose={"widget"}
                      style={{ float: "right" }}
                      onClick={setInAscendingOrderForDraftDash} /> :
                    <ActionIcon
                      id="ascendingOrderForDraftDash"
                      icon={faCaretUp}
                      toolTip=""
                      purpose={"widget"}
                      style={{ float: "right" }}
                      onClick={setInDescendingOrderForDraftDash} />
                }
              </></th>
              <th style={{ width: "20%" }}>Collection Name</th>
              <th style={{ width: "20%" }}>Actions</th>
              <th colSpan={2}></th>
            </tr>
          </thead>
          <tbody>
            {
              draftDashboards.map(dash => {
                if (dash.status === "In Development" || dash.dashboardStatus === null) {
                  return (
                    <tr key={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_row`}>
                      <td>{dash.name}</td>
                      <td>{dash.collectionName}</td>
                      <td>
                        <Button style={{ marginLeft: "-15px", marginRight: "-15px" }}
                          id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_edit`}
                          purpose="link"
                          onClick={() => onClickEditDashboard(props.productName, dash.collectionName, dash.name)}>
                          <ActionIcon
                            id={`${pascalize(dash.name)}_editIcon`}
                            toolTip={"Edit Dashboard"}
                            icon={faPencilAlt} />
                        </Button>
                        {dash.name.toLowerCase() !== 'template'
                          ?
                          <ModalBasicButton
                            id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_delete`}
                            headerText="Delete Dashboard"
                            message="Are you sure you want to delete the selected dashboard?"
                            style={{ borderWidth: 0 }}
                            okButtonText="Delete"
                            cancelButtonText="Cancel"
                            onClickedOk={() => onClickDeleteDashboard(dash.collectionName, dash.name, dash.id)}>
                            <ActionIcon
                              id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_deleteIcon`}
                              toolTip={"Delete Dashboard"}
                              icon={faTrashAlt} />
                          </ModalBasicButton>
                          : null}
                      </td>
                      <td>
                        {dash.name.toLowerCase() !== 'template'
                          ?
                          <EnableDashboard
                            customerKey={getCustomerKey()}
                            spaceId={props.spaceId}
                            collectionName={dash.collectionName}
                            dashboardName={dash.name}
                            newlyCreatedDashboard={props.newlyCreatedDashboard}
                            setActiveCardIndex={setActiveCardIndex}
                            startPoll={props.startPoll} />                          
                          : null}
                      </td>
                    </tr>)
                }
                return null
              })
            }

            {
              draftDashboards.length === 0 ?
                <tr id="noDataDevCollection">
                  <td colSpan={4}>
                    <NoDataError />
                  </td>
                </tr> : null
            }

          </tbody>
        </table>
      </Card>
      <Card id={`${props.productName}_liveCollection`} title="Enabled Dashboards" open={true} collapsible={true} badgeNumber={countPublishedDashboards(props.collections)}>
        <table className="table table-striped table-bordered" id="publishedTable">
          <thead>
            <tr id="HeadersForPublishedDashboardTable">
              <th style={{ width: "25%" }}>Dashboard Name
                <>
                  {
                    sortOrderForEnabled === 'desc' ?
                      <ActionIcon
                        id='descendingOrderForEnabledDash'
                        icon={faCaretDown}
                        toolTip=""
                        purpose={"widget"}
                        style={{ float: "right" }}
                        onClick={setInAscendingOrderForEnabledDash} /> :
                      <ActionIcon
                        id='ascendingOrderForEnabledDash'
                        icon={faCaretUp}
                        toolTip=""
                        purpose={"widget"}
                        style={{ float: "right" }}
                        onClick={setInDescendingOrderForEnabledDash} />
                  }
                </>
              </th>
              <th style={{ width: "20%" }}>Collection Name</th>
              <th style={{ width: "15%" }}>Status
                <>
                  {
                    sortOrderForStatus === 'desc' ?
                      <ActionIcon
                        id='descendingOrderForStatusEnabledDash'
                        icon={faCaretDown}
                        toolTip=""
                        purpose={"widget"}
                        style={{ float: "right" }}
                        onClick={setInAscendingOrderForStatus} /> :
                      <ActionIcon
                        id='ascendingOrderForStatusEnabledDash'
                        icon={faCaretUp}
                        toolTip=""
                        purpose={"widget"}
                        style={{ float: "right" }}
                        onClick={setInDescendingOrderForStatus} />
                  }
                </>
              </th>
              <th style={{ width: "10%" }}>Actions</th>
              <th style={{ width: "10%" }}></th>
              <th style={{ width: "20%" }}>Assignable to Roles</th>
            </tr>
          </thead>
          <tbody>
            {enabledDashboards.map(dash => {
              if (dash.status === "Live" && (dash.dashboardStatus !== null)) {
                return (
                  <tr key={`${dash.name}_${pascalize(dash.collectionName)}_row`}>
                    <td>{dash.name}</td>
                    <td>{dash.collectionName}</td>
                    <td>
                      {dash.dashboardStatus === "Enabled"
                        ? dash.dashboardStatus
                        : null}
                      {dash.dashboardStatus === "Enabling" || dash.dashboardStatus === "Deleting"
                        ? <SmallSpinner />
                        : null}
                      {dash.dashboardStatus === "Failed" || dash.dashboardStatus === "DeletingFailed"
                        ? <>
                          {dash.dashboardStatus}
                          <ActionIcon id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_toolTip`}
                            color={'red'}
                            className={styles.toolTip}
                            toolTip={`Some error occured, Error Id : ${dash.failedCorrelationId}. Please retry.`}
                            icon={faExclamationCircle} />
                        </>
                        : null}
                    </td>
                    <td>
                      {
                        dash.dashboardStatus !== "Enabling" ?
                          <>
                            {
                              dash.dashboardStatus === "Deleting" ? (
                                <Button purpose="link">
                                  <ActionIcon
                                    id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_delete_icon`}
                                    toolTip={"Delete Dashboard"}
                                    icon={faTrashAlt}
                                    disabled={true}>
                                  </ActionIcon>
                                </Button>
                              ) :
                                <ModalBasicButton
                                  id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_delete`}
                                  headerText="Delete Dashboard"
                                  message="Are you sure you want to delete the selected dashboard?"
                                  style={{ borderWidth: 0 }}
                                  okButtonText="Delete"
                                  cancelButtonText="Cancel"
                                  onClickedOk={() => onClickDeleteDashboard(dash.collectionName, dash.name, dash.id)}>
                                  <ActionIcon
                                    id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_deleteIcon`}
                                    toolTip={"Delete Dashboard"}
                                    icon={faTrashAlt}>
                                  </ActionIcon>
                                </ModalBasicButton>
                            }
                          </>
                          : <>
                            <Button purpose="link">
                              <ActionIcon
                                id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_delete_icon`}
                                toolTip={"Delete Dashboard"}
                                icon={faTrashAlt}
                                disabled={true}>
                              </ActionIcon>
                            </Button>
                          </>
                      }
                    </td>
                    <td>
                      {
                        (dash.dashboardStatus === "Enabled" || dash.dashboardStatus === "DeletingFailed") && (
                          <ModalBasicButton
                            id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_disable`}
                            headerText="Disable Dashboard"
                            message="Disabled dashboards will not be available to any user. Are you sure you want to disable this dashboard?"
                            buttonText="Disable"
                            okButtonText="Disable"
                            cancelButtonText="Cancel"
                            onClickedOk={() => onClickDisableDashboard(dash.id)} />)
                      }
                      {
                        dash.dashboardStatus === "Enabling" && (<div>Enabling</div>)}
                      {dash.dashboardStatus === "Deleting" && (<div>Deleting</div>)

                      }
                      {
                        dash.dashboardStatus === "Failed" && (
                          <ModalBasicButton
                            id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_retry`}
                            headerText="Enable Dashboard"
                            message={retryModalMessage(dash.id)}
                            buttonText="Retry"
                            okButtonText="Continue"
                            cancelButtonText="Cancel"
                            onClickedOk={() => {
                              onClickEnableDashboardWithAssignmentForRetry(dash.fromCollectionName, dash.name, isVisibleState[dash.id])
                            }}
                            onClickedCancel={() => setIsVisibleState(computeScreenStateForIsVisibileItems(props.collections))}
                          />)
                      }
                    </td>
                    <td>
                      <div style={{ marginTop: "10px" }}>
                        <Checkbox id={`${pascalize(dash.name)}_${pascalize(dash.collectionName)}_isVisible`}
                        onChange={changeAssign}
                        checked={isVisibleState[dash.id] || false}
                        disabled={(dash.dashboardStatus === "Enabling" || dash.dashboardStatus === "Failed") ? true : false}
                        value={dash.id.toString()} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}                        />
                      </div>
                    </td>
                  </tr>)
              }
              return null
            })
            }

            {
              enabledDashboards.length === 0 ?
                <tr id="noDataLiveCollection">
                  <td colSpan={4}>
                    <NoDataError />
                  </td>
                </tr> : null
            }
          </tbody>
        </table>
      </Card>
    </Accordion>
    <Modal
      id="assignModal"
      headerText={modalHeaderMessage}
      message={modalMessage}
      okButtonText="Continue"
      cancelButtonText="Cancel"
      onClickedOk={onClickAssignablePublishedDashboard}
      onClickedCancel={onClickAssignableCancel}
      show={showModal}
      modalType="BASIC" />
  </>
  )
}

export default DashboardViewRow