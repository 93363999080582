import React, { FC, useState } from 'react'
import { DefaultPage } from 'navex-react'
import { RolesDetailsView } from './RolesDetailsView'
import { RolesListView } from './RolesListView'
import { useGetRoles, IRole } from './RolesApi'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
    toggles: string[]
}

export const RolesRoute:FC<IProps> = (props) => {
  const [isEdit, setIsEdit] = useState(false)
  const [roleId, setRoleId] = useState(undefined as number | undefined)

  const [{ roles, products, loading, status, errors }, reFetch] = useGetRoles(props.toggles.includes(TOGGLENAME.POWERBI_V2))

  const role: IRole | undefined = roles.find(f => f.id === roleId)
  const defaultRole: IRole | undefined = roles.find(f => f.isDefault === true)

  if (errors.length) return <UnexpectedError />
  if (loading || !status) return <Spinner />

  return (<DefaultPage>
    {isEdit
      ? <RolesDetailsView role={role} defaultRole={defaultRole} products={products} roleId={roleId} setIsEdit={setIsEdit} 
        reFetch={reFetch} toggles={props.toggles}
      />
      : <RolesListView roles={roles} setIsEdit={setIsEdit} setRoleId={setRoleId} reFetch={reFetch} toggles={props.toggles}
      />
    }
  </DefaultPage>)
}