import { IAppNavigationUIRoute } from "navex-react"
import { pascalize } from "../../Utils/utils"
import { computeDashboardManagementDefinitions } from "./dashboardManagementScreenDefinitions"
import { IDashboardManagementScreenDefinition } from "./dashboardManagementScreenTypes"

export interface IDashboardManagementLeftNavTree {
    permissions: string[],
    unrecognizedPermissions: string[],
    accessibleScreens: {
        screenName: string,
        becauseOfPermission: string
    }[],
    inaccessibleScreens: string[],
    dashboardManagementNodes: IAppNavigationUIRoute[]
}

const makeStartingAccumulator = (permissions: string[]): IDashboardManagementLeftNavTree => {
    return {
        permissions,
        unrecognizedPermissions: [],
        accessibleScreens: [],
        inaccessibleScreens: [],
        dashboardManagementNodes: []
    }
}

interface IStringToAnyHash {
    [key: string]: any
}

export const computeDashboardManagementLeftNavNodes = (permissions: string[], toggles: string[]): IDashboardManagementLeftNavTree => {
    const unrecognizedPermissionsHash: IStringToAnyHash = permissions.reduce((acc: IStringToAnyHash, p) => {
        acc[p] = 42
        return acc
    },
        {})

    const dashboardManagementScreenDefinitions = computeDashboardManagementDefinitions(toggles)

    const result = Object.keys(dashboardManagementScreenDefinitions).reduce(
        (acc: IDashboardManagementLeftNavTree, screenName: string) => {
            const screenDefinition = dashboardManagementScreenDefinitions[screenName]
            const nameOfGatingPermission = screenDefinition.gatingPermission || `${screenName}Get`
            const nameOfToggle = screenDefinition.toggleName
            const isToggleEnabled = !nameOfToggle ? true : toggles.includes(nameOfToggle)

            recognizePermission(nameOfGatingPermission)

            if (isToggleEnabled && permissions.includes(nameOfGatingPermission)) {
                acc.accessibleScreens.push({
                    screenName, becauseOfPermission: nameOfGatingPermission
                })
                acc.dashboardManagementNodes.push(makeDashboardManagementNode(screenDefinition))
            }
            else {
                acc.inaccessibleScreens.push(screenName)
            }

            return acc
        },
        makeStartingAccumulator(permissions)
    )

    result.unrecognizedPermissions = Object.keys(unrecognizedPermissionsHash) || []

    return result

    function recognizePermission(permissionName: string) {
        if (unrecognizedPermissionsHash.hasOwnProperty(permissionName)) {
            delete unrecognizedPermissionsHash[permissionName]
        }
    }
}

const makeDashboardManagementNode = (screenDefinition: IDashboardManagementScreenDefinition): IAppNavigationUIRoute => {
    return {
        id: computeRoute(screenDefinition),
        title: screenDefinition.title,
        path: computePath(screenDefinition),
        component: screenDefinition.component,
        clickedAction: screenDefinition.clickedAction,
        aliasPaths: screenDefinition.aliasPaths,
    }
}

const computeRoute = (screenDefinition: IDashboardManagementScreenDefinition): string => {
    return `route_${pascalize(screenDefinition.title)}`
}

const computePath = (screenDefinition: IDashboardManagementScreenDefinition): string => {
    return `/DashboardManagement_${pascalize(screenDefinition.title)}`
}
