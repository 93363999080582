import React, { FC } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Formik, Form, Field } from "formik"
import * as Yup from 'yup';
import { Toasts, ToastId } from "navex-react"
import { computeEditDashboardRoutePath } from "./DashboardViewRow"
import { FormikInput } from "navex-react"
import { ERRORKEY } from "../../ts/enums/errorKey"
import { Button } from "navex-react"
import { axiosInstance } from "../../Api/api"
import { buildServerApiUrl, getCustomerKey, pathPrefix } from "../../Api/urls"
import { SmallSpinner } from "../Shared/Spinner"
import { insightAppHistory } from '../InsightsApp/InsightsApp'

interface IProps {
    showCreateDashModal: boolean,
    setshowCreateDashModal: (value: boolean) => void,
    productName: string,
    spaceId: string,
    startPoll:  () => void,
    toggles: string[],
    setNewlyCreatedDashboard: (args:string) => void,
}

const CreateDashboard: FC<IProps> = (props) => {
    let toastId: ToastId = 0

    const handleCreateDashbordModalXClose = () => {
        props.setshowCreateDashModal(false)
    }

    const closeRolesModalXButton = (
        <button
            id="closeXButton"
            tabIndex={0}
            className="close modal-header-close"
            onClick={handleCreateDashbordModalXClose}
            aria-label={"close"}
            type="button"
        >
            <FontAwesomeIcon icon={faTimes} />
        </button>
    )

    return (
        <Modal isOpen={props.showCreateDashModal}>
            <Formik
                initialValues={{
                    dashboardName: ''
                }}
                onSubmit={(values, actions) => {
                    axiosInstance.post(`${buildServerApiUrl("Dashboard.CreateDashboard").toString()}`, {
                        dashboardName: values.dashboardName.trim(),
                        spaceId: props.spaceId,
                        customerKey: getCustomerKey()
                    }).then(response => {
                        if (response.status === 200) {
                            actions.setStatus(true)
                            actions.setSubmitting(true)
                            props.setNewlyCreatedDashboard(values.dashboardName.trim())
                            
                            insightAppHistory.push(`${pathPrefix}${computeEditDashboardRoutePath(props.productName, 'EthicsPoint-Draft', values.dashboardName.trim())}`)
                        }
                    }).catch(errors => {
                        if (errors.response) {
                            if (errors.response.status === 400 && errors.response.data.errors && errors.response.data.errors.length > 0) {
                                if (errors.response.data.errors[0].key === ERRORKEY.DashboardExists) {
                                    actions.setStatus(false)
                                    actions.setSubmitting(false)
                                    actions.setErrors({ dashboardName: 'Dashboard already exists!' })
                                }
                                else {
                                    // for status 400 and ERRORKEY is different than DashboardExists
                                    props.setshowCreateDashModal(false)
                                    toastId = Toasts.alert("An error occurred processing your request. Please try again.")
                                }
                            }
                            else {
                                // other than 200
                                if (!Toasts.isActive(toastId)) {
                                    actions.setStatus(false)
                                    actions.setSubmitting(false)
                                    props.setshowCreateDashModal(false)
                                    toastId = Toasts.alert("An error occurred processing your request. Please try again.")
                                }
                            }
                        }
                        else {
                            // if request is cancelled or net error
                            props.setshowCreateDashModal(false)
                            toastId = Toasts.alert("An error occurred processing your request. Please try again.")
                        }
                    }).finally(() => props.startPoll())
                }}

                validationSchema={Yup.object().shape({
                    dashboardName: Yup.string()
                        .min(2, 'Must be at least 2 characters')
                        .max(30, 'Must be 30 characters or less')
                        .matches(/^[a-zA-Z0-9 ]*$/, 'Special characters not allowed')
                        .required('Enter Dashboard name')
                })}
            >
                {({ values, errors, isSubmitting, status }) => (
                    <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <ModalHeader close={closeRolesModalXButton}>
                            Add New Dashboard
                        </ModalHeader>
                        <ModalBody>
                            <div id="create-dashboard" className="form-group">
                                <div className="form-control-feedback-group">
                                    <Field
                                        id="page-name"
                                        name="dashboardName"
                                        required
                                        label="Dashboard Name"
                                        component={FormikInput}
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {
                                !isSubmitting || status ?
                                    <Button purpose="primary" type="submit" id="btnCreate" disabled={values.dashboardName.length === 0 || !!errors.dashboardName}
                                        style={{ float: "right" }}>Create</Button>
                                    : <SmallSpinner />
                            }
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default CreateDashboard