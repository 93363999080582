import React, { useState } from 'react'
import { IFavoriteReport } from './FavoriteApi'
import { models } from 'powerbi-client'
import { PowerBIReport } from '../PowerBIManage/PowerBIReport'
import { NoDataError } from '../Shared/Error'
import { FavoriteRow } from './FavoriteRow'
import { DefaultPage } from 'navex-react'

interface IProps {
    reportInfo: IFavoriteReport[],
    refetch: () => void
}
export const FavoriteView = (props: IProps) => {

    const [reportMode, setReportMode] = useState<"View" | "">("")

    const [reportId, setReportId] = useState("")
    const [embedUrl, setEmbedUrl] = useState("")

    switch (reportMode) {
        case 'View':
            return <PowerBIReport reportId={reportId} embedUrl={embedUrl} reportMode={models.ViewMode.View} />
        default:
            return (<DefaultPage>
                <table className="table table-striped table-bordered" id="FavoriteReports">
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}> Report Name </th>
                            <th style={{ width: "30%" }}> Description</th>
                            <th style={{ width: "20%" }}> Collection</th>
                            <th style={{ width: "15%" }}> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.reportInfo.length > 0
                                ?
                                props.reportInfo.map(x =>
                                    <FavoriteRow key={x.reportId} reportId={x.reportId} workSpaceId={x.workspaceId} embedUrl={x.embedUrl}
                                        setReportId={setReportId} setEmbedUrl={setEmbedUrl} name={x.name} description={x.description} collection ={x.collection} setReportMode={setReportMode} reFetch={props.refetch} />
                                )
                                :
                                <tr>
                                    <td colSpan={4}>
                                        <NoDataError />
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
                </DefaultPage>
            )
    }
}