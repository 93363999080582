import React, { FC, useEffect, useRef } from 'react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { IDataset, useGetEmbedTokenForCreate } from './ManagePowerBIApi'
import { Report, models } from 'powerbi-client'
import './report.css'
import NavexThemeTemplate from './NavexThemeTemplate.json';
import { getCustomerKey } from '../../Api/urls'

interface Iprops {
    datasets: IDataset[],
    newReportName: string,
    setReportMode: (mode: "Create" | "View" | "Edit" | "") => void,
    reFetch: () => void
}

export const PowerBICreateReport: FC<Iprops> = (props) => {

    let dataset = props.datasets[0] /// ONLY GRABS the FIRST DATASET for now!!!
    //let dataset = props.datasets.find(e => typeof e !== 'undefined')
    //let dataset = props.datasets.find(element=>element!==undefined)

    const [getEmbedTokenInfo, { posting, status, embedTokenForCreateInfo, errors }] = useGetEmbedTokenForCreate({
        onSuccess: () => {

        },
        onError: () => {

        }
    })

    const embedContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getEmbedTokenInfo({
            customerKey: getCustomerKey(),
            datasetId: dataset.id,
        })
    }, [])

    useEffect(() => {
        if (embedTokenForCreateInfo && embedContainerRef.current) {
            let embedCreateConfiguration = {
                type: "report",
                accessToken: embedTokenForCreateInfo.token,
                datasetId: dataset.id,
                tokenType: models.TokenType.Embed,
                embedUrl: dataset.createReportEmbedUrl,
                theme: {
                    themeJson: NavexThemeTemplate
                }
            }

            window.report = window.powerbi.createReport(embedContainerRef.current, embedCreateConfiguration) as Report
            window.report.on('rendered', function () {
                window.report.off('rendered')
                window.report.saveAs({ name: props.newReportName })
                    .then(() => {
                        window.report.isSaved().then(
                            (val) => {
                                if (val === true)
                                {
                                    setTimeout(() => {
                                        props.reFetch()
                                        props.setReportMode("Edit")
                                    },5000)
                                }
                        }
                        )
                    })
            })
        }
    }, [embedTokenForCreateInfo, embedContainerRef])

    return (
        <>
            {
                !!errors.length && <UnexpectedError />
            }{
                (posting || !status) && <Spinner />
            }
            <div id="embedContainer" ref={embedContainerRef} className={'report'} ></div>
        </>
    )
}
