import { useAxiosGet, useAxiosPost, IUseAxiosOptions } from '../../Api/useAxios'
import { AxiosResponse } from 'axios'
import { buildServerApiUrl, getCustomerKey } from "../../Api/urls"
import { Omit } from '../../Utils/typeHelpers'

export interface IRole {
	id: number,
	name: string,
	usersInRole: string[],
	defaultDashboard: number | null,
	defaultReport: string | null,
	isDefault: boolean,
	roleProducts: IRoleProducts[],
	isModifiable: boolean
}

interface IRoleProducts {
	productKey: number,
	productName: string,
	rolePermissions: IRoleProductsPermissionDefinition[],
	roleDashboards: number[],
	roleReports: string[]
}

export interface IProduct {
	productKey: number,
	productName: string,
	permissions: IProductPermissionDefinition[],
	dashboardCollections: IProductDashboardCollection[],
	powerBiReports: IProductPowerBiReports[]
}


export interface IRoleProductCollection {
	[key: string]:IRoleProducts | undefined
}

export interface IProductDashboardCollection {
	id: string,
	name: string,
	displayName: string,
	spaceId: string,
	dashboards: IProductDashboardDefinition[],
}

export interface IProductDashboardDefinition {
	name: string,
	id: number,
}

export interface IProductPermissionDefinition {
	productPermissionId: number,
	name: string,
	possibleValues: string[],
}

export interface IProductPowerBiReports {
	displayName: string,
	embedUrl: string,
	workSpaceId: string
	reportId: string
}

export interface IRoleProductsPermissionDefinition {
	productPermissionId: number,
	name: string,
	selectedValue: string,
}

export interface IProductDashboardCollection {
	id: string,
	name: string,
	displayName: string,
	spaceId: string,
	dashboards: IProductDashboardDefinition[],
}

export interface IProductDashboardDefinition {
	id: number,
	name: string,
}



type TUseGetRoles = [
	{
		roles: IRole[],
		products: IProduct[],
		loading: boolean,
		status?: string | number,
		errors: any[],
	},
	() => Promise<AxiosResponse<any> | undefined>
]


export const useGetRoles = (powerBIToggle:boolean): TUseGetRoles => {
const getRolesUrl = powerBIToggle?`${buildServerApiUrl("Role.GetDeep", "v3").toString()}/${getCustomerKey()}`
                     :`${buildServerApiUrl("Role.GetDeep", "v2").toString()}/${getCustomerKey()}`

	const [{ data, loading, errors, status }, reFetch] = useAxiosGet(
		getRolesUrl,
	)

	let roles: IRole[] = [], products: IProduct[] = []
	
	if (data) {
		roles = data.roles
		products = data.products
	}

	return [{roles, products, loading, status, errors,}, reFetch]
}

export interface IUpsertRolePayload {
	customerKey: string,
	id?: number,
	name: string,
	isDefault: boolean,
	permissions: number[],
	defaultDashboard: number | null,
	dashboards: number[],
	reports: string[],
}

export type IUpsertRole = Omit<IUpsertRolePayload, "customerKey">

type TUseUpsertRole = [
	(payload: IUpsertRole) => Promise<AxiosResponse<any> | undefined>,
	{
		posting: boolean,
		status?: string | number,
		errors: any[],
	}
]

const upsertRoleUrl = `${buildServerApiUrl("Role.Upsert", "v2").toString()}`
export const useUpsertRole = (
	options?: IUseAxiosOptions<IUpsertRolePayload>
): TUseUpsertRole => {

	const [upsert, { posting, status, errors }] = useAxiosPost<IUpsertRolePayload>(
		upsertRoleUrl, {}, options)

	const update = async (payload: IUpsertRole) => {
		const res = await upsert({ ...payload, customerKey:getCustomerKey() })
		return res
	}

	return [update, { posting, status, errors }]
}

type IDeleteRolePayload = {
	roleId: number,
	customerKey: string,
}

export type IDeleteRole = Omit<IDeleteRolePayload, "customerKey">
type TUseDeleteRole = [
	(payload: IDeleteRole) => Promise<AxiosResponse<any> | undefined>,
	{
		posting: boolean,
		status?: string | number,
		errors: any[],
	}
]

export const deleteRoleUrl = `${buildServerApiUrl("Role.Delete", "v2").toString()}`
export const useDeleteRole = (
	options?: IUseAxiosOptions<IDeleteRolePayload>
): TUseDeleteRole => {

	const [execute, { posting, status, errors }] = useAxiosPost<IDeleteRolePayload>(
		deleteRoleUrl, {}, options
	)

	const clear = async (payload: IDeleteRole) => {
		const res = await execute({ ...payload, customerKey:getCustomerKey() })
		return res
	}

	return [clear, { posting, status, errors }]
}